<template>
  <div class="custom-radio-component">
    <div class="range-input-header">
      <slot></slot>
      <div class="clear-btn"
           v-if="showClearButton"
           @click="cleanFilterClicked">Clear
      </div>
    </div>

    <div class="words-radio-block">
      <div class="radio-item"
           :class="{active: currentRadio === 1}"
           @click="radioClicked(1)">1
      </div>
      <div class="radio-item"
           :class="{active: currentRadio === 2}"
           @click="radioClicked(2)">2
      </div>
      <div class="radio-item"
           :class="{active: currentRadio === 3}"
           @click="radioClicked(3)">3+
      </div>
    </div>

  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "index",
  emits: ['filter-changed', 'filter-cleaned'],
  props: {
    initialData: {
      type: Object,
      required: false,
    },
    initialValue: {
      type: Number,
    },
    resetDataCounter: {
      type: Number,
    },
  },
  data() {
    return {
      currentRadio: null,
      wordsRadioObject: {},
      showClearButton: false,
    }
  },
  mounted() {
    if (this.initialData.min !== undefined && this.initialData.max !== undefined) {
      this.setInitialRadio();
    }

    if (this.initialValue) {
      this.currentRadio = this.initialValue;
    }

    if (this.initialData?.isInitiallyActive) {
      this.showClearButton = true;
    }
  },
  methods: {
    radioClicked(num) {
      this.currentRadio = num;
      this.wordsRadioObject = {};

      if (num === 1) {
        this.wordsRadioObject.min = 1;
        this.wordsRadioObject.max = 1;
      } else if (num === 2) {
        this.wordsRadioObject.min = 2;
        this.wordsRadioObject.max = 2;
      } else {
        this.wordsRadioObject.min = 3;
        this.wordsRadioObject.max = 1000000;
      }

      this.showClearButton = true;
      this.$emit('filter-changed', this.wordsRadioObject);
    },
    cleanFilterClicked() {
      this.currentRadio = null;
      this.wordsRadioObject.min = 1;
      this.wordsRadioObject.max = 1000000;
      this.showClearButton = false;
      this.$emit('filter-cleaned', this.wordsRadioObject);
    },
    setInitialRadio() {
      if (this.initialData.min === 1 && this.initialData.max === 1) {
        this.currentRadio = 1;
      }

      if (this.initialData.min === 2 && this.initialData.max === 2) {
        this.currentRadio = 2;
      }

      if (this.initialData.min === 3 && this.initialData.max === 1000000) {
        this.currentRadio = 3;
      }

      if (this.initialData.min === 0 && this.initialData.max === 100) {
        this.currentRadio = null;
        this.showClearButton = false;
      }
    },
  },
  computed: {
    minBlockWidth() {
      const customMaxWidth = parseInt(this.customMaxWidth);
      const minWidth = parseInt(this.minWidth);
      if (customMaxWidth > 64) {
        const diff = customMaxWidth - 64;
        return minWidth + diff + 'px';
      }
      return this.minWidth;
    },
    showClearBtn() {
      return this.currentRadio;
    },
  },
  watch: {
    initialData(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setInitialRadio();
      }
    },
    resetDataCounter(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.currentRadio = null;
        this.wordsRadioObject.min = 1;
        this.wordsRadioObject.max = 1000000;
        this.showClearButton = false;
        this.$emit('filter-changed', this.wordsRadioObject);
      }
    },

  }
})
</script>

<style scoped lang="scss">
.custom-radio-component {
  .range-input-header {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral-900);
    padding: 0 16px 0;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    .clear-btn {
      margin-left: 12px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--primary-default);
      cursor: pointer;
    }
  }
}

.words-radio-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  & > div:not(:last-child) {
    margin-right: 10px;
  }

  .radio-item {
    width: 64px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid var(--neutral-500);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral-800);
    cursor: pointer;

    @media only screen and (max-width: 480px) {
      width: calc(33.33% - 8px);
    }
  }

  .radio-item.active {
    color: var(--primary-default);
    border-color: var(--primary-default);
  }
}
</style>