export class Developer {

    constructor(
        title
    ) {
        this._title = title;
    }

    get title() {
        return this._title;
    }
}