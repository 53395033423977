export function TooltipDirective(el, binding) {
    el.setAttribute('data-tooltip', binding.value.text);
    el.classList.add('with-tooltip');

    const position = binding.value.position;
    el.classList.remove('tooltip-top', 'tooltip-right', 'tooltip-bottom', 'tooltip-left');
    el.classList.add(`tooltip-${position}`);

    if (binding.value.classes) {
        el.classList.add(...binding.value.classes);
    }

    if (binding.value.onClickText) {
        el.setAttribute('data-tooltip-click', binding.value.onClickText);
        el.classList.add('with-tooltip-click');
        el.addEventListener('click', () => {
            el.classList.add('tooltip-clicked');
            setTimeout(() => {
                el.classList.remove('tooltip-clicked');
            }, 1000);
        });
    }
}