<template>
  <div class="search-input-block"
       :class="[...classes, {'opened': isFocused}]"
       v-click-outside="outside">

    <div class="input-wrap">
      <form>
        <label>
          <input type="text"
                 class="search-input"
                 :style="{height: height}"
                 @input="searchInputChanged"
                 @keydown.enter.prevent
                 :placeholder="placeholder ?? ''"
                 ref="searchInput"
                 :disabled="inputDisabled"
                 v-model="searchInput">
        </label>
      </form>
      <slot v-if="showCaret || showSearchIcon"
            name="search-icon"></slot>
      <slot name="preloader"></slot>
    </div>

    <div class="results-block-wrap"
         v-if="!hideResultsBlock"
         :class="[position, {'active': showResults || loading}, {'wide-block': withResultSlots}, {'full-width-content': fullWidthContent}]">

      <template v-if="searchInput.length >= minResultLength && loading">
        <custom-preloader height="20px"/>
      </template>

      <template v-else>
        <template v-if="searchResults?.length !== 0">
          <div class="results-block"
               :style="{'max-height': resultsMaxHeight}"
               v-for="result in searchResults">
            <slot name="results"
                  :result="result"></slot>
          </div>
        </template>
        <template v-else>
          <div class="no-results"
               style="padding: 0 16px;">{{ searchResults?.message ?? "We couldn't find what you are looking for" }}
          </div>
        </template>
      </template>
    </div>

    <slot name="alternative-results-block"></slot>
  </div>
</template>

<script>
import Preloader from '@/components/UI/Preloader/index';
import {debounce} from "@/utils/utils";

export default {
  name: "BasicSearchInput",
  props: {
    placeholder: {
      type: String
    },
    classes: {
      type: Array,
      required: false,
      default: () => []
    },
    searchResults: {
      type: Object
    },
    position: {
      type: String,
      default: 'position-absolute'
    },
    withResultSlots: {
      type: Boolean,
      default: false
    },
    focused: {
      type: Boolean,
      default: false
    },
    hideResultsBlock: {
      type: Boolean,
      default: false
    },
    clearInput: {
      type: String,
      required: false
    },
    inputDisabled: {
      type: Boolean,
      default: false
    },
    showCaret: {
      type: Boolean,
      default: true,
    },
    showSearchIcon: {
      type: Boolean,
      default: false,
    },
    minInputLength: {
      type: Number,
      default: 2
    },
    fullWidthContent: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '40px',
    },
    resultsMaxHeight: {
      type: String,
      default: '275px',
    },
    minResultLength: {
      type: Number,
      default: 2
    },
    isFocusedInitially: {
      type: Boolean,
      default: false
    },
    focusCounter: {
      type: Number,
    },
    resetInputValue: {
      type: Number,
    },
    inlinePreloader: {
      type: Boolean,
      default: false,
    },
    resultsCounter: {
      type: Number,
      default: 0,
    },
    initValue: {
      type: String,
      default: ''
    },
  },
  emits: ['search-input-changed', 'search-outside-clicked'],
  components: {
    'custom-preloader': Preloader
  },
  data() {
    return {
      searchInput: this.initValue,
      debouncedInput: '',
      searchIsActive: false,
      isFocused: false,
      loading: false,
      localFocusCounter: 0,
    }
  },
  methods: {
    outside() {
      this.searchIsActive = true;
      this.$emit('search-outside-clicked');
    },
    searchInputChanged() {
      this.searchIsActive = true;
      if (this.searchInput.length >= this.minResultLength) {
        this.loading = true;
      }
    },
    setFocus() {
      this.$refs.searchInput.focus();
    }
  },
  mounted() {
    this.loading = false;
    if (this.isFocusedInitially) {
      this.$refs.searchInput.focus();
    }
  },
  computed: {
    showResults() {
      if (this.minInputLength === 0) {
        return ((this.searchResults?.result || this.searchResults?.message) && this.searchIsActive);
      }
      return (this.searchResults?.result || this.searchResults?.message) && this.searchInput.length >= this.minResultLength && this.searchIsActive;
    }
  },
  watch: {
    searchResults: {
      immediate: true,
      handler(value) {
        if (value?.result || value?.message) {
          this.loading = false;
        }
      }
    },
    searchInput: debounce(function (newVal) {
      this.debouncedInput = newVal;
      this.$emit('search-input-changed', this.debouncedInput);
    }, 500),
    focused(val) {
      if (val) {
        this.$nextTick(() => {
          this.setFocus();
        });
      }
    },
    clearInput(val) {
      if (val === '') {
        this.searchInput = '';
      }
    },
    focusCounter(newVal, oldVal) {
      if (newVal !== oldVal) {
        setTimeout(() => {
          this.setFocus();
        }, 500);
      }
    },
    resetInputValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.searchInput = '';
      }
    }
  },
}
</script>

<style lang="scss" src="./styles.scss" scoped></style>
<style lang="scss">
.search-input-block {
  .search-icon {
    @media only screen and(max-width: 767px) {
      top: 16px;
    }
  }

  .close-icon {
    position: absolute;
    z-index: 10;
    right: 12px;
    top: 11px;
    font-size: 14px;
    color: #9199ab;
    cursor: pointer;
    transition: all .3s;

    @media only screen and(max-width: 767px) {
      top: 16px;
    }
  }

  .preloader-wrap {
    position: absolute;
    right: 12px;
    top: 9px;
    height: 16px;
    width: 16px;

    @media only screen and(max-width: 767px) {
      top: 14px;
    }
  }
}
.search-input-block {
  .search-input {
    height: 36px !important;
    border: 1px solid #fff;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(160, 190, 221, 0.30);
    transition: all .3s;
    font-size: 16px;
    line-height: 24px;

    @media only screen and(max-width: 767px) {
      height: 48px !important;
    }
  }

  .search-input::placeholder {
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral-700);
  }

  .search-input:focus {
    border: solid 1px #5d71fa;
  }
}


</style>