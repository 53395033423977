<template>
  <div class="current-app"
       v-click-outside="outside">

    <template v-if="!collapsed">

      <div class="app-details">
        <div class="app-logo">
          <img :src="cropImg(applicationData.logo, '24x24', applicationData?.store?.key)"
               width="24"
               height="24"
               alt="App logo">

          <template v-if="applicationData?.store?.key">
            <img v-if="applicationData?.store?.key === 'APP_STORE'"
                 src="@/assets/images/icons/app_store.png"
                 alt="Store icon"
                 class="store-icon"
                 width="12"
                 height="12">
            <img v-else
                 src="@/assets/images/icons/google_play.png"
                 alt="Store icon"
                 class="store-icon"
                 width="12"
                 height="12">
          </template>
        </div>
        <div class="app-name">{{ applicationData?.title ?? '' }}</div>
      </div>
      <div class="bottom-block">
        <div class="app-ratings">
            <current-app-aso-score v-if="applicationData?.id && currentKeywordCountry?.code"
                                   :app-id="applicationData?.id"
                                   :country-code="currentKeywordCountry?.code"></current-app-aso-score>
          <div class="app-id">
            <div class="css-icons star-icon"></div>
            {{ applicationData?.rating ? applicationData?.rating.toFixed(1) : '' }}
          </div>
        </div>
        <div class="search-trigger"
             @click="myAppsSearchOpened">Other apps
        </div>

        <transition name="fade">
          <div class="recent-apps-block"
               :class="{'long': recentApps?.length > 8 && windowWidth > 990}"
               v-if="showRecentAppsBlock">

            <div class="app-input-block">
              <div class="input-wrap">
                <form>
                  <label>
                    <input type="text"
                           class="search-input"
                           @input="searchInputChanged"
                           @keydown.enter.prevent
                           placeholder="Search for my apps"
                           ref="searchInput"
                           v-model="searchInput">
                  </label>
                </form>
                <svg-icon class="search-icon"
                          icon="search-solid"></svg-icon>
              </div>
            </div>

            <template v-if="searchInput?.length > 0 && searchResults?.length > 0">
              <div class="search-wrap">

                <div class="results-part search">
                  <div class="result-item"
                       v-for="(item, key) in searchResults"
                       :class="{'new': item?.isNew}">

                    <div class="product-type">
                      <svg-icon :icon="item?.store?.key === 'GOOGLE_PLAY' ? 'google-play' : 'app-store'"/>
                    </div>

                    <div class="product-logo">
                      <app-image
                          :width="22"
                          :height="22"
                          alt="App image"
                          :lazy-src="cropImg(item?.logo, '22x22', item?.store?.key)"
                      />
                    </div>
                    <div class="product-name"
                         @click="appClickHandler(item)">{{ item?.title ?? '' }}
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template v-else-if="searchInput?.length > 0 && searchResults?.length === 0">
              <div class="no-apps">No apps that match your criteria</div>
            </template>

            <template v-else>
              <div v-if="myRecentAppsLoaded"
                   class="recent-apps-heading">Recent Apps
              </div>
              <div class="results-part">
                <template v-if="!myRecentAppsLoaded">
                  <preloader-table-small :loader-size="'40px'"></preloader-table-small>
                </template>
                <template v-else>
                  <div class="result-item"
                       v-for="(item, key) in myRecentApps"
                       :class="{'new': item?.isNew}">

                    <div class="product-type">
                      <svg-icon :icon="item?.store?.key === 'GOOGLE_PLAY' ? 'google-play' : 'app-store'"/>
                    </div>
                    <div class="product-logo">
                      <app-image
                          :width="22"
                          :height="22"
                          alt="App image"
                          :lazy-src="cropImg(item?.logo, '22x22', item?.store?.key)"
                      />
                    </div>

                    <div class="product-name"
                         @click="appClickHandler(item)">{{ item?.title ?? '' }}
                    </div>
                  </div>

                  <div class="show-more"
                       v-if="windowWidth > 990 && recentApps?.length > 15 || windowWidth < 991 && recentApps?.length > 8">
                    <router-link :to="{name: 'MyApps'}"
                                 class="show-btn">
                      Show All
                      <svg-icon icon="arrow"></svg-icon>
                    </router-link>
                  </div>
                </template>
              </div>
            </template>

          </div>
        </transition>
      </div>

      <div v-if="showCurrentCountryDropdown"
           class="country-select sub-select">
        <custom-dropdown :data-updated="currentKeywordCountry"
                         @dropdown-clicked-outside="dropdownClosedOutside"
                         @dropdown-opened="dropdownOpened"
                         @dropdown-closed="dropdownClosed">
          <template v-slot:header>
            <div v-if="currentKeywordCountry?.code"
                 class="selected-country">
              <span class="flag">{{ flagEmoji(currentKeywordCountry.code) }}</span>
              <span class="text"
                    :title="currentKeywordCountry.name">{{ currentKeywordCountry.name }}</span>
            </div>
            <template v-else>Select country</template>
          </template>
          <template v-slot:content>
            <custom-tabs @country-selected="countryFilterSelected"
                         @country-input-changed="countryInputChanged"
                         :reset-tabs-to-default="countriesTabCounter"
                         :all-countries="searchCountryResults"></custom-tabs>
          </template>
        </custom-dropdown>
      </div>
    </template>

    <template v-else>
      <div class="collapsed-link">
        <div class="link-icon"
             @click="currentAppBlockClicked">
          <div class="app-logo">
            <img :src="cropImg(applicationData.logo, '42x42', applicationData?.store?.key)"
                 width="18"
                 height="18"
                 alt="App logo">

            <img v-if="applicationData?.store?.key === 'APP_STORE'"
                 src="@/assets/images/icons/app_store.png"
                 alt="Store icon"
                 class="store-icon"
                 width="10"
                 height="10">
            <img v-else
                 src="@/assets/images/icons/google_play.png"
                 alt="Store icon"
                 class="store-icon"
                 width="10"
                 height="10">
          </div>
        </div>

        <template v-if="isTablet">
          <div class="link-content"
               :style="{display: showCollapsedCurrAppBlock ? 'block' : 'none'}">
            <div class="app-details">
              <div class="app-name">{{ applicationData?.title ?? '' }}</div>
            </div>
            <div class="bottom-block">
              <div class="app-ratings">
                  <current-app-aso-score v-if="applicationData?.id && currentKeywordCountry?.code"
                                         :app-id="applicationData?.id"
                                         :country-code="currentKeywordCountry?.code"></current-app-aso-score>
                <div class="app-id">
                  <div class="css-icons star-icon"></div>
                  {{ applicationData?.rating ? applicationData?.rating.toFixed(1) : '' }}
                </div>
              </div>
              <div class="search-trigger"
                   @click="myAppsSearchOpened">Other apps
              </div>

              <transition name="fade">
                <div class="recent-apps-block"
                     :class="{'long': recentApps?.length > 8 && windowWidth > 990}"
                     v-if="showRecentAppsBlock">

                  <div class="app-input-block">
                    <div class="input-wrap">
                      <form>
                        <label>
                          <input type="text"
                                 class="search-input"
                                 @input="searchInputChanged"
                                 @keydown.enter.prevent
                                 placeholder="Search for my apps"
                                 ref="searchInput"
                                 v-model="searchInput">
                        </label>
                      </form>
                      <svg-icon class="search-icon"
                                icon="search-solid"></svg-icon>
                    </div>
                  </div>

                  <template v-if="searchInput?.length > 0 && searchResults?.length > 0">
                    <div class="search-wrap">

                      <div class="results-part search">
                        <div class="result-item"
                             v-for="(item, key) in searchResults"
                             :class="{'new': item?.isNew}">
                          <div class="product-type">
                            <svg-icon :icon="item?.store?.key === 'GOOGLE_PLAY' ? 'google-play' : 'app-store'"/>
                          </div>

                          <div class="product-logo">
                            <app-image
                                :width="22"
                                :height="22"
                                alt="App image"
                                :lazy-src="cropImg(item?.logo, '22x22', item?.store?.key)"
                            />
                          </div>

                          <div class="product-name"
                               @click="appClickHandler(item)">{{ item?.title ?? '' }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-else-if="searchInput?.length > 0 && searchResults?.length === 0">
                    <div class="no-apps">No apps that match your criteria</div>
                  </template>

                  <template v-else>
                    <div v-if="myRecentAppsLoaded"
                         class="recent-apps-heading">Recent Apps
                    </div>
                    <div class="results-part">
                      <template v-if="!myRecentAppsLoaded">
                        <preloader-table-small :loader-size="'40px'"></preloader-table-small>
                      </template>
                      <template v-else>
                        <div class="result-item"
                             v-for="(item, key) in myRecentApps"
                             :class="{'new': item?.isNew}">
                          <div class="product-type">
                            <svg-icon :icon="item?.store?.key === 'GOOGLE_PLAY' ? 'google-play' : 'app-store'"/>
                          </div>

                          <div class="product-logo">
                            <app-image
                                :width="22"
                                :height="22"
                                alt="App image"
                                :lazy-src="cropImg(item?.logo, '22x22', item?.store?.key)"
                            />
                          </div>

                          <div class="product-name"
                               @click="appClickHandler(item)">{{ item?.title ?? '' }}
                          </div>
                        </div>

                        <div class="show-more"
                             v-if="windowWidth > 990 && recentApps?.length > 15 || windowWidth < 991 && recentApps?.length > 8">
                          <router-link :to="{name: 'MyApps'}"
                                       class="show-btn">
                            Show All
                            <svg-icon icon="arrow"></svg-icon>
                          </router-link>
                        </div>
                      </template>
                    </div>
                  </template>

                </div>
              </transition>
            </div>

            <div v-if="showCurrentCountryDropdown"
                 class="country-select sub-select">
              <custom-dropdown :data-updated="currentKeywordCountry"
                               @dropdown-clicked-outside="dropdownClosedOutside"
                               @dropdown-opened="dropdownOpened"
                               @dropdown-closed="dropdownClosed">
                <template v-slot:header>
                  <div v-if="currentKeywordCountry?.code"
                       class="selected-country">
                    <span class="flag">{{ flagEmoji(currentKeywordCountry.code) }}</span>
                    <span class="text"
                          :title="currentKeywordCountry.name">{{ currentKeywordCountry.name }}</span>
                  </div>
                  <template v-else>Select country</template>
                </template>
                <template v-slot:content>
                  <custom-tabs @country-selected="countryFilterSelected"
                               @country-input-changed="countryInputChanged"
                               :reset-tabs-to-default="countriesTabCounter"
                               :all-countries="searchCountryResults"></custom-tabs>
                </template>
              </custom-dropdown>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="link-content">
            <div class="app-details">
              <div class="app-name">{{ applicationData?.title ?? '' }}</div>
            </div>
            <div class="bottom-block">
              <div class="app-ratings">
                  <current-app-aso-score v-if="applicationData?.id && currentKeywordCountry?.code"
                                         :app-id="applicationData?.id"
                                         :country-code="currentKeywordCountry?.code"></current-app-aso-score>
                <div class="app-id">
                  <div class="css-icons star-icon"></div>
                  {{ applicationData?.rating ? applicationData?.rating.toFixed(1) : '' }}
                </div>
              </div>
              <div class="search-trigger"
                   @click="myAppsSearchOpened">Other apps
              </div>

              <transition name="fade">
                <div class="recent-apps-block"
                     :class="{'long': recentApps?.length > 8 && windowWidth > 990}"
                     v-if="showRecentAppsBlock">

                  <div class="app-input-block">
                    <div class="input-wrap">
                      <form>
                        <label>
                          <input type="text"
                                 class="search-input"
                                 @input="searchInputChanged"
                                 @keydown.enter.prevent
                                 placeholder="Search for my apps"
                                 ref="searchInput"
                                 v-model="searchInput">
                        </label>
                      </form>
                      <svg-icon class="search-icon"
                                icon="search-solid"></svg-icon>
                    </div>
                  </div>

                  <template v-if="searchInput?.length > 0 && searchResults?.length > 0">
                    <div class="search-wrap">

                      <div class="results-part search">
                        <div class="result-item"
                             v-for="(item, key) in searchResults"
                             :class="{'new': item?.isNew}">
                          <div class="product-type">
                            <svg-icon :icon="item?.store?.key === 'GOOGLE_PLAY' ? 'google-play' : 'app-store'"/>
                          </div>

                          <div class="product-logo">
                            <app-image
                                :width="22"
                                :height="22"
                                alt="App image"
                                :lazy-src="cropImg(item?.logo, '22x22', item?.store?.key)"
                            />
                          </div>

                          <div class="product-name"
                               @click="appClickHandler(item)">{{ item?.title ?? '' }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-else-if="searchInput?.length > 0 && searchResults?.length === 0">
                    <div class="no-apps">No apps that match your criteria</div>
                  </template>

                  <template v-else>
                    <div v-if="myRecentAppsLoaded"
                         class="recent-apps-heading">Recent Apps
                    </div>
                    <div class="results-part">
                      <template v-if="!myRecentAppsLoaded">
                        <preloader-table-small :loader-size="'40px'"></preloader-table-small>
                      </template>
                      <template v-else>
                        <div class="result-item"
                             v-for="(item, key) in myRecentApps"
                             :class="{'new': item?.isNew}">
                          <div class="product-type">
                            <svg-icon :icon="item?.store?.key === 'GOOGLE_PLAY' ? 'google-play' : 'app-store'"/>
                          </div>

                          <div class="product-logo">
                            <app-image
                                :width="22"
                                :height="22"
                                alt="App image"
                                :lazy-src="cropImg(item?.logo, '22x22', item?.store?.key)"
                            />
                          </div>

                          <div class="product-name"
                               @click="appClickHandler(item)">{{ item?.title ?? '' }}
                          </div>
                        </div>

                        <div class="show-more"
                             v-if="windowWidth > 990 && recentApps?.length > 15 || windowWidth < 991 && recentApps?.length > 8">
                          <router-link :to="{name: 'MyApps'}"
                                       class="show-btn">
                            Show All
                            <svg-icon icon="arrow"></svg-icon>
                          </router-link>
                        </div>
                      </template>
                    </div>
                  </template>

                </div>
              </transition>
            </div>

            <div v-if="showCurrentCountryDropdown"
                 class="country-select sub-select">
              <custom-dropdown :data-updated="currentKeywordCountry"
                               @dropdown-clicked-outside="dropdownClosedOutside"
                               @dropdown-opened="dropdownOpened"
                               @dropdown-closed="dropdownClosed">
                <template v-slot:header>
                  <div v-if="currentKeywordCountry?.code"
                       class="selected-country">
                    <span class="flag">{{ flagEmoji(currentKeywordCountry.code) }}</span>
                    <span class="text"
                          :title="currentKeywordCountry.name">{{ currentKeywordCountry.name }}</span>
                  </div>
                  <template v-else>Select country</template>
                </template>
                <template v-slot:content>
                  <custom-tabs @country-selected="countryFilterSelected"
                               @country-input-changed="countryInputChanged"
                               :reset-tabs-to-default="countriesTabCounter"
                               :all-countries="searchCountryResults"></custom-tabs>
                </template>
              </custom-dropdown>
            </div>
          </div>
        </template>

      </div>
    </template>
  </div>
</template>

<script>
import Accordion from '@/components/UI/Accordion/index';
import SearchInput from '@/components/UI/SearchInput/index';
import CustomDropdown from "@/components/UI/CustomDropdown/index";
import PreloaderTableSmall from '@/components/UI/PreloaderTableSmall/index';
import {mapGetters} from "vuex";
import {httpRequest} from "@/api";
import SvgIcon from "@/components/UI/SvgIcon";
import CustomSelectWithSearch from "@/components/Forms/CustomSelectWithSearch";
import {getFlagEmoji, cropImage, setCookie, getCookie} from "@/utils/utils";
import CustomTabs from "@/views/SearchTerms/KeywordsTracking/components/CustomTabs";
import {UserAppsArrayCreator} from "@/utils/factories";
import getters from "../../../store/getters";
import CurrentAppAsoScore from "./AsoScore/index.vue";
import myApps from "@/views/MyApps/index.vue";

export default {
  name: "CurrentApp",
  components: {
    CurrentAppAsoScore,
    SvgIcon,
    'custom-dropdown': CustomDropdown,
    'custom-accordion': Accordion,
    'search-input': SearchInput,
    'preloader-table-small': PreloaderTableSmall,
    'custom-select-search': CustomSelectWithSearch,
    'custom-tabs': CustomTabs,
  },
  props: {
    appData: {
      type: Object
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    hasBackdrop: {
      type: Boolean,
      default: false
    },
    accordionInitialHeight: {
      type: String,
      default: '20px'
    }
  },
  data() {
    return {
      searchResults: [],
      accordionHeight: 0,
      applicationData: {},
      currentAppChanged: 0,
      myRecentAppsLoaded: false,
      showRecentAppsBlock: false,
      searchInput: '',
      countryInitial: {
        code: 'US',
        name: 'United States'
      },
      searchCountryResults: {},
      addedCountry: {},
      currentKeywordCountry: {},
      countriesTabCounter: 0,
      showCollapsedSidebarAppContent: false,
    }
  },
  methods: {
    outside() {
      this.showRecentAppsBlock = false;
      this.myRecentAppsLoaded = false;
      this.searchInput = '';

      this.showCollapsedSidebarAppContent = false;
    },
    dropdownClosedOutside() {
      this.countriesTabCounter++;
    },
    dropdownClosed() {
      this.countriesTabCounter++;
    },
    async dropdownOpened() {
      await this.setCountriesFilter();
      this.countriesTabCounter++;
    },
    searchInputChanged() {
      if (this.searchInput?.length >= 1) {
        const apps = this.recentApps ?? [];
        this.searchResults = apps.filter(app => app?.title?.toLowerCase().includes(this.searchInput.toLowerCase()));
      } else {
        this.searchResults = [];
      }
    },
    async myAppsSearchOpened() {
      if (this.showRecentAppsBlock !== true) {
        this.showRecentAppsBlock = true;
        this.myRecentAppsLoaded = false;

        setTimeout(() => {
          this.$refs.searchInput?.focus();
        }, 500);

        await this.fetchAppsData();
        this.myRecentAppsLoaded = true;
      } else {
        this.showRecentAppsBlock = false;
      }
    },
    async fetchAppsData() {
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS_NEW);
      this.$store.dispatch('SET_RECENT_APPS', UserAppsArrayCreator(result));
    },
    async appClickHandler(item) {
      this.showCollapsedSidebarAppContent = false;

      await this.$store.dispatch('SET_CURRENT_APP', item);

      setCookie('currentAppId', item.id, null, 7);
      this.$store.dispatch('SET_CURRENT_APP_RELOADED', true);
      this.$store.dispatch('SET_CURRENT_APP_ID', item.id);

      this.searchResults = {};
      this.currentAppChanged++;
      this.outside();

      this.$store.dispatch('SET_DATA_COUNTS_LOADER', false);
      await this.setCountriesFilter();
      await this.$store.dispatch('SET_DATA_COUNTS', {appId: item.id});
      this.$store.dispatch('SET_DATA_COUNTS_LOADER', true);

      if (this.$route.name === 'MyApps') {
        this.$router.push('/search-terms/keywords-tracking');
      }

    },
    async addAppSearchHandler(value) {
      if (value?.length > 2) {
        const url = process.env.VUE_APP_API_URL
            + this.$ApiUrls.myApps.SEARCH_APPLICATIONS
            + '?country_code=' + this.addedCountry?.code
            + '&phrase=' + value + '&device=iphone'
            + '&fields=id,store_key,logo,title,developer_name&page=1';
        const result = await httpRequest('GET', url);
        this.searchResults = {result: result?.results?.filter(item => item !== null)};
      } else {
        this.searchResults = {};
      }
    },
    flagEmoji(val) {
      return getFlagEmoji(val);
    },
    async setCountriesFilter() {
      if (getters.countryLoading(this.$store.state)) {
        return;
      }
      this.$store.dispatch('SET_COUNTRY_LOADING', true);
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.SEARCH_KEYWORDS_COUNTRIES + '/' + getCookie('currentAppId') + '/countries');
      this.$store.dispatch('SET_COUNTRIES_LIST', result?.countries);
      this.$store.dispatch('SET_COUNTRY_LOADING', false);
      this.searchCountryResults = {result: result?.countries};
    },
    async countryInputChanged(value) {
      const currentAppId = getCookie('currentAppId');
      if (value?.length >= 2) {
        const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.SEARCH_KEYWORDS_COUNTRIES + '/' + currentAppId + '/countries' + '?query=' + value);
        this.searchCountryResults = {result: result?.countries};
      }

      if (value?.length === 0) {
        const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.SEARCH_KEYWORDS_COUNTRIES + '/' + currentAppId + '/countries');
        this.searchCountryResults = {result: result?.countries};
      }
    },
    async countryFilterSelected(value) {
      this.showCollapsedSidebarAppContent = false;
      this.currentKeywordCountry = {...value};
      this.countriesTabCounter++;
      this.$store.dispatch('SET_CURRENT_COUNTRY_MANUAL', value);
      this.$store.dispatch('SET_DATA_COUNTS_LOADER', false);
      await this.setCurrentLatestCountry(value?.id);
      await this.setCountriesFilter();
      await myApps.checkCurrentApp(value?.code);
      await this.$store.dispatch('SET_DATA_COUNTS', {countryCode: value?.code});

      this.$store.dispatch('SET_DATA_COUNTS_LOADER', true);
    },
    async setCurrentLatestCountry(countryId) {
      const currentAppId = getCookie('currentAppId');
      const url = process.env.VUE_APP_API_URL + 'api/profiles/applications/' + currentAppId + '/' + countryId + '/used';
      await httpRequest('POST', url);
    },
    cropImg(imageUrl, size, store) {
      if (!imageUrl) {
        return;
      }

      return cropImage(imageUrl, size, store);
    },
    currentAppBlockClicked() {
      if (this.isTablet) {
        this.showCollapsedSidebarAppContent = !this.showCollapsedSidebarAppContent;
      }
    },
  },
  async mounted() {
    this.applicationData = {...this.appData};
    this.currentKeywordCountry = {
      code: this.currentCountry?.code,
      name: this.currentCountry?.name,
    };

    await this.setCountriesFilter(); //I've seen history of this line and continue tradition of commenting/uncommenting it :) okay :)
  },
  computed: {
    ...mapGetters([
      'recentApps',
      'currentAppId',
      'currentApp',
      'currentCountry',
      'isTablet',
    ]),
    ...mapGetters('keywordsTracking', [
      'trackingKeywordsCount',
    ]),
    myRecentApps() {
      let splicedApps = [...this.recentApps];
      return this.windowWidth > 990 ? splicedApps.splice(0, 15) : splicedApps.splice(0, 9);
    },
    windowWidth() {
      return window.innerWidth;
    },
    showCurrentCountryDropdown() {
      return !(this.$route.name === 'Profile' || this.$route.name === 'MyApps');
    },
    showCollapsedCurrAppBlock() {
      const width = window.innerWidth;

      if (!this.showCollapsedSidebarAppContent) {
        return false;
      }

      if (width > 768 && width < 992) {
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {
    appData(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.applicationData = {...newVal};
      }
    },
    async currentCountry(val) {
      this.currentKeywordCountry = {
        code: val?.code,
        name: val?.name,
        id: val?.id,
      };
    },
    async trackingKeywordsCount(newVal, oldVal) {
      if (newVal !== oldVal) {
        // await this.setCountriesFilter();
      }
    }
  }
}
</script>

<style src="./styles.scss" lang="scss"></style>