<template>
  <div class="custom-data-table-select"
       v-click-outside="outside"
       :class="{'active' : isOpened}, {'bg-transparent': bgIsTransparent}, 'direction-' + direction">
    <div class="select-styled"
         :class="[{error: errorMessage?.length > 0}, {'placeholder': selectedValue.length === 0}]"
         @click="isOpened = ! isOpened">
      <span id="selected-value">{{ selectedValue }}
        <template v-if="selectedValue.length === 0">
          Click to select
        </template>
      </span>
      <div class="icon">
        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-right" role="img"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"
             class="svg-inline--fa fa-angle-right fa-w-6 fa-3x">
          <path fill="currentColor"
                d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"
                class=""></path>
        </svg>
      </div>
    </div>
    <div class="select-options-wrap">
      <ul class="select-options">
        <li v-for="(option, idx) in optionsReversed"
            @click="selectClicked(option, idx)">{{ option }}
        </li>
      </ul>
    </div>

    <error-message v-if="errorMessage?.length > 0">{{ errorMessageComp }}</error-message>

  </div>
</template>

<script>
import ErrorMessageBlock from "@/components/Forms/ErrorMessageBlock/index";

export default {
  name: "CustomDataTableSelect",
  components: {
    'error-message': ErrorMessageBlock,
  },
  props: {
    options: {
      type: Object
    },
    errorMessage: {},
    initialData: {
      type: String
    },
    bgIsTransparent: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'down'
    }
  },
  data() {
    return {
      isOpened: false,
      selectedValue: ''
    }
  },
  methods: {
    selectClicked(option, idx) {
      this.$emit('selectClicked', {id: idx, value: option});
      this.selectedValue = option;
      this.isOpened = false;
    },
    outside() {
      this.isOpened = false;
    },
  },
  mounted() {
    if (this.initialData) {
      this.selectedValue = this.initialData;
    }
  },
  computed: {
    errorMessageComp() {
      if (typeof this.errorMessage === 'string') {
        return this.errorMessage;
      } else {
        let msgString = '';
        this.errorMessage.forEach(item => {
          msgString += item;
        });
        return msgString;
      }
    },
    optionsReversed() {
      return this.options.filter(item => item !== Number(this.selectedValue)).reverse();
    }
  },
  watch: {
    initialData(val) {
      if (val) {
        this.selectedValue = val;
      }
    }
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>