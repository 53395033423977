import {GenerateQueryUrl} from "@/utils/factories";

export const SearchFilterData = class {
    constructor(pagination, sorting, filters, searchInput) {
        this.sorting = sorting ?? new SortingData();
        this.pagination = pagination ?? new PaginationData();
        this.filters = filters ?? {};
        this.searchInput = searchInput ?? '';
    }

    getSearchInput() {
        return this.searchInput;
    }

    generateFilterString(likeParam = 'like') {
        let filterString = GenerateQueryUrl(this.filters);
        if (this.searchInput) {
            filterString += `&filter[${likeParam}][query]=` + this.searchInput;
        }

        return filterString;
    }

    paramsUrl() {
        let filterString = this.generateFilterString();
        filterString += this.sorting.generateFilterString();
        filterString += this.pagination.generateFilterString();
        return filterString;
    }
}

export const SortingData = class {
    constructor(sortBy, direction, sortExportParam) {
        this.sortBy = sortBy?? null;
        this.direction = direction ?? null;
        this.sortExportParam = sortExportParam ?? this.sortBy
    }

    generateFilterString() {
        return '&sort_by=' + this.sortExportParam + '&order=' + this.direction
    }
}

export const PaginationData = class {
    constructor(perPage, currentPage) {
        this.perPage = perPage ?? 50;
        this.currentPage = currentPage ?? 1;
        this.total = 0;
        this.calculate();
    }

    calculate() {
        this.to = this.perPage * this.currentPage;
        this.from = this.to - this.perPage + 1;
        this.skip = (this.currentPage - 1) * this.perPage;
    }

    setTotal(total) {
        this.total = total;
        if (this.to > this.total) {
            this.to = this.total;
        }
    }

    getTotal() {
        return this.total;
    }

    generateFilterString() {
        return '&size=' + this.perPage + '&skip=' + this.skip;
    }

    reset() {
        this.currentPage = 1;
        this.calculate();
    }
}
