<template>
  <template v-if="version === 2">
    <div class="progress"
         :style="[{width: width}, {height: height}, fillColor]" style="border-radius: 12px"></div>
  </template>
  <template v-else>
    <div class="progress-bar"
         :class="barType"
         :style="[{height: height}]">
      <div class="progress"
           :style="[{width: width}, fillColor]"></div>
    </div>
  </template>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    width: {
      type: String
    },
    height: {
      type: String,
      default: '10px',
    },
    barType: {
      type: String,
      required: false,
    },
    fillColor: {
      type: String,
    },
    version: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style scoped lang="scss" src="./styles.scss"></style>