<template>
  <img :src="useFlagPngIcon()"
       :class="classes"
       :width="width"
       :height="height"
       alt="Country icon"/>
</template>

<script>
export default {
  name: "DynamicImage",
  props: {
    width: {
      type: Number
    },
    height: {
      type: Number
    },
    countryCode: {
      type: String,
    },
    classes: {
      type: String
    },
    size: {
      type: Number,
      default: 16,
    }
  },
  methods: {
    useFlagPngIcon() {
      const code = this.countryCode?.toLowerCase();
      if (!code || code === '' || code === 'worldwide' || code === 'other') {
        return null;
      }

      if (this.size !== 16) {
        return require(`@/assets/images/flags/32x24/${code}.png`);
      } else {
        return require(`@/assets/images/flags/16x12/${code}.png`);
      }
    }
  },
}
</script>

<style scoped lang="scss">

</style>