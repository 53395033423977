<template>
  <div class="date-input-block"
       v-click-outside="outside">
    <input type="text"
           @focus="inputFocused"
           class="date-input"
           :class="classes"
           @input="inputHandler"
           :value="formattedInputDate">
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {formatDate, debounce} from "@/utils/utils";

export default defineComponent({
  name: "index",
  props: {
    date: {},
    classes: {
      type: String,
    }
  },
  data() {
    return {
      inputDate: null,
      debouncedInput: '',
    }
  },
  emits: ['open-date-picker', 'close-date-picker', 'input-date-changed', 'invalid-date-set'],
  methods: {
    inputFocused() {
      this.$emit('open-date-picker');
    },
    inputHandler(e) {
      this.inputDate = e.target.value;
    },
    outside(e) {
      if (!e.target.closest('.calendar-container')) {
        this.$emit('close-date-picker');
      }
    },
  },
  mounted() {
    this.debouncedInput = this.date;
  },
  computed: {
    formattedInputDate() {
      return formatDate(this.debouncedInput, 'month-day-year');
    }
  },
  watch: {
    date(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.debouncedInput = newVal;
      }
    },
    inputDate: debounce(function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const formattedDate = formatDate(newVal, 'month-day-year');

        if (!formattedDate?.includes('NaN') && new Date(newVal) !== 'Invalid Date') {
          this.debouncedInput = newVal;
          this.$emit('input-date-changed', newVal);
        } else {
          this.$emit('invalid-date-set');
        }

      }
    }, 2000),
  }
})
</script>

<style scoped lang="scss">
.date-input {
  border-radius: 4px;
  border: 1px solid #5D94FE;
  background: var(--neutral-0, #FFF);
  height: 32px;
  width: 100%;
  outline: none;
  padding: 0 12px;
  color: var(--neutral-800);
  font-size: 15px;
  font-weight: 400;
}
.date-input.yellow {
  border: 1px solid var(--secondary-yellow);
}
</style>