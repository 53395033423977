import {httpRequest} from "../index";

export default {
    ALL_KEYWORDS: 'api/profiles/rat/saved-keywords',
    TRACKING_KEYWORDS: 'api/profiles/saved-keywords-v2', //?application_id=1328002&country_code=US&sort=popularity&order=desc&size=100&skip=0
    REDEFINE_KEYWORDS_LANGUAGE: 'api/profiles/redefine-keywords-language',
    SUGGESTED_KEYWORDS: 'api/profiles/rat/saved-keywords/suggestions',
    RELATED_KEYWORDS: 'api/profiles/rat/saved-keywords/related',
    DELETE_KEYWORDS: 'api/profiles/rat/saved-keywords',
    BATCH_DELETE_KEYWORDS: 'api/profiles/rat/saved-keywords/batch-delete',
    SEARCH_TRACKED_KEYWORDS: 'api/profiles/rat/saved-keywords',
    SEARCH_KEYWORDS_COUNTRIES: 'api/profiles/applications',
    APPLICATIONS_LIVE_SEARCH: 'api/search/live',
    POPULARITY_DYNAMICS: 'api/profiles/rat/saved-keywords/popularity-dynamics',
    POPULARITY_DYNAMICS_COUNTRIES: 'api/profiles/rat/saved-keywords/popularity-dynamics/countries',
    CUSTOM_COMPETITORS: 'api/profiles/application/custom-competitors',

    deleteKeywords(appId, keywordIds, countryCode) {
        const data = {
            application_id: appId,
            keywords: keywordIds,
            country_code: countryCode
        };

        return httpRequest('DELETE', process.env.VUE_APP_API_URL + this.BATCH_DELETE_KEYWORDS, data);
    },

    addKeywords(appId, countryCode, keywords) {
        let keywordsChunks = [];
        let chunkSize = 250;
        for (let i = 0; i < keywords.length; i += chunkSize) {
            keywordsChunks.push(keywords.slice(i, i + chunkSize));
        }

        //each chunk is an array of keywords
        let promises = keywordsChunks.map(chunk => {
            let formData = new FormData();
            formData.append("country_code", countryCode);
            formData.append("application_id", appId);
            chunk.forEach((keyword, idx) => {
                formData.append(`keywords[${idx}]`, keyword);
            });

            return httpRequest('POST', process.env.VUE_APP_API_URL + this.ALL_KEYWORDS, formData);
        });

        return Promise.all(promises);
    }
};