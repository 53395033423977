export default {
    SET_TRACKED_KEYWORDS(state, payload) {
        state.trackedKeywords = payload;
    },
    SET_SUGGESTED_KEYWORDS(state, payload) {
        state.suggestedKeywords = payload;
    },
    SET_SORTING_META(state, payload) {
        state.sortingMeta = {...payload};
    },
    SET_URL_QUERY_STRING(state, payload) {
        state.urlQueryString = payload;
    },
    SET_POPULARITY_CHART_RESULTS(state, payload) {
        state.popularityChartResults = {...payload};
    },
    SET_TRACKING_KEYWORDS_COUNT(state) {
        state.trackingKeywordsCount++;
    },
    PARSE_URL(state, payload) {
        state.tempInitObject = {...payload}
    },
};