<template>
  <div class="header-search-block"
       v-click-outside="outside"
       :class="{'active': searchIsActive}">
    <div class="search-icon mobile-hidden tablet-hiddens"
         @click="searchInputTriggerClicked">
      <svg-icon icon="search-solid" class="zoom-icon"/>
      <svg-icon icon="close" class="close-icon"/>
    </div>

    <div class="search-icon-mobile mobile-visible"
         @click="searchInputTriggerClicked">
      <svg-icon icon="search-solid" class="zoom-icon"/>
      <svg-icon icon="close" class="close-icon"/>
    </div>

    <div class="search-input-block">
      <div class="input-wrap">
        <form>
          <label>
            <input type="text"
                   class="search-input"
                   @input="searchInputChanged"
                   v-model="searchInput">
          </label>
        </form>

        <svg-icon icon="search-solid"/>
      </div>

      <div v-if="false" class="results-block-wrap">
        <div class="results-block">
          <a href="#"
             class="result-item">
                  <span class="product-type">
                     <svg-icon icon="google-play"/>
                  </span>
            <span class="product-logo">
                    <img data-src="https://via.placeholder.com/22x22.it"
                         alt="app image"
                         width="22"
                         height="22"
                         class="lazy">
                  </span>
            <span class="product-name">Phone</span>
            <span class="product-aso-score">
                    <svg class="svg-icons"><svg-icon icon="aso"/></svg>58
                  </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderSearch",
  data() {
    return {
      searchInput: '',
      searchIsActive: false
    }
  },
  methods: {
    outside() {
      this.searchIsActive = false;
    },
    searchInputTriggerClicked() {
      this.searchIsActive = !this.searchIsActive;
    },
    searchInputChanged() {
      this.$emit('searchInputChanged', this.searchInput);
    }
  }
}
</script>

<style scoped lang="scss" src="./styles.scss"></style>