import mutations from "@/store/modules/live-search/mutations";
import actions from "@/store/modules/live-search/actions";
import getters from "@/store/modules/live-search/getters";

export default {
  namespaced: true,
  state() {
    return {
      suggestedKeywords: [],
      liveSearchResults: {},
      popularityChartResults: {},
      sortingMeta: {
        sort_by: 'keyword',
        sort_direction: 'desc'
      },
      urlQueryString: '',
      inputCurrentKeyword: '',
    };
  },
  mutations,
  actions,
  getters
};