export default class Store {
    constructor(id, key) {
        this._id = id;
        this._key = key;
    }

    get id() {
        return this._id;
    }

    get key() {
        return this._key;
    }
}