export default {
    SET_SORTING_META({commit, state, rootState}, payload) {
        const iap = payload?.iap ?? state.sortingMeta.iap;
        let iapValue;
        if (iap !== 'all') {
            iapValue = iap;
        } else {
            iapValue = null;
        }

        const metaObject = {
            order_by: payload?.order_by ?? state.sortingMeta.order_by,
            order_direction: payload?.order_direction ?? state.sortingMeta.order_direction,
            store_key: payload?.store_key ?? state.sortingMeta.store_key,
            cluster_key: payload?.cluster_key ?? state.sortingMeta.cluster_key,
            category_slug: payload?.category_slug ?? state.sortingMeta.category_slug,
            // iap: payload?.iap ?? state.sortingMeta.iap,
            // device: payload?.device ?? state.sortingMeta.device,
        };

        if (iapValue) {
            metaObject.iap = iapValue;
        }

        commit('SET_SORTING_META', metaObject);
    },
    CONSTRUCT_URL_QUERY_STRING({commit, state}, payload) {
        let queryString = '';
        if (payload && typeof payload === 'string') {
            commit('SET_URL_QUERY_STRING', payload);
            return;
        }

        if (Object.keys(state.sortingMeta).length > 0) {
            for (const key in state.sortingMeta) {
                if (state.sortingMeta[key]) {
                    queryString += '&' + key + '=' + state.sortingMeta[key];
                }
            }
        }
        commit('SET_URL_QUERY_STRING', queryString);
    },
};
