<template>
  <div class="page-content maintenance">
    <main>
      <transition name="fade">
        <div class="general-page-content new-container">
          <div class="countdown-layout">
            <div class="countdown-block">
              <div class="text">
                We are currently performing maintenance on the site. Soon we'll be back. Please come back in 🤗
              </div>

              <div v-if="false" class="countdown">
                <div class="countdown-item">
                  <div class="digit-wrap">
                    <div class="countdown-digit">{{counterData?.hours?.firstDigit}}</div>
                    <div class="countdown-digit">{{counterData?.hours?.secondDigit}}</div>
                  </div>
                  <div class="countdown-text">Hours</div>
                </div>
                <div class="countdown-separator">:</div>

                <div class="countdown-item">
                  <div class="digit-wrap">
                    <div class="countdown-digit">{{counterData?.minutes?.firstDigit}}</div>
                    <div class="countdown-digit">{{counterData?.minutes?.secondDigit}}</div>
                  </div>
                  <div class="countdown-text">Minutes</div>
                </div>
                <div class="countdown-separator">:</div>

                <div class="countdown-item">
                  <div class="digit-wrap">
                    <div class="countdown-digit">{{counterData?.seconds?.firstDigit}}</div>
                    <div class="countdown-digit">{{counterData?.seconds?.secondDigit}}</div>
                  </div>
                  <div class="countdown-text">Seconds</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </main>
  </div>
</template>

<script>
import {ref} from 'vue';
import {useStore} from "vuex";

export default {
  name: "CountdownLayout",
  setup() {
    const store = useStore();
    const countDownDate = new Date("Feb 17, 2023 14:30").getTime();
    let counterData = ref({});

    let counterInterval = setInterval(function () {
      let now = new Date().getTime();
      let distance = countDownDate - now;

      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (hours < 10) {
        hours = '0' + hours;
      } else {
        hours = hours.toString();
      }

      if (minutes < 10) {
        minutes = '0' + minutes;
      } else {
        minutes = minutes.toString();
      }

      if (seconds < 10) {
        seconds = '0' + seconds;
      } else {
        seconds = seconds.toString();
      }

      counterData.value = {
        hours: {
          firstDigit: hours[0],
          secondDigit: hours[1],
        },
        minutes: {
          firstDigit: minutes[0],
          secondDigit: minutes[1],
        },
        seconds: {
          firstDigit: seconds[0],
          secondDigit: seconds[1],
        },
      };

      if (distance < 0) {
        clearInterval(counterInterval);
      }
    }, 1000);


    return {
      counterData,
    }
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>