import {httpRequest} from "../index";
import {CustomCompetitor} from "../objects/CustomCompetitor";
import {Developer} from "../objects/Developer";
import customCompetitors from "./custom-competitors";

export default {
    KEYWORDS_COUNTS: 'api/live-search/app-keyword-counts',
    KEYWORDS_TOP: 'api/live-search/keyword-top',
    KEYWORDS_TOP_AVERAGE_METRICS: 'api/live-search/app-keyword-top-average-metrics',
    KEYWORDS_TOP_CATEGORY: 'api/live-search/app-keyword-top-category-distribution',
    LIVE_SEARCH: 'api/search/live',

    getKeywordTop(countryCode, device, appId, keyword, skip, size = 10, appsOnly = false, formatter) {
        let url = process.env.VUE_APP_API_URL
            + this.KEYWORDS_TOP
            + '?country_code=' + countryCode
            + '&device=' + device
            + '&size=' + size
            + '&skip=' + skip
            + '&app_id=' + appId
            + '&keyword=' + keyword.toLowerCase();

        if (appsOnly) {
            url += '&apps_only=true';
        }

        return new Promise((resolve, reject) => {
            return formatter(url, resolve, reject, {
                countryCode,
                appId,
            });
        });
    },

    formatAsCustomCompetitors(url, resolve, reject, args) {
        httpRequest('GET', url).then(response => {
            let competitors = [];
            response.list.forEach(item => {
                competitors.push(new CustomCompetitor(
                    args.appId,
                    null,
                    item.data.id,
                    null,

                    item.data.logo,
                    item.data.title,
                    item.data.categories[0] ?? null,
                    item.data.rating,
                    item.data.store_key,
                    item.data.url,
                    'new',
                    new Developer(
                        item.data.developer
                    ),
                    item.data.votes,
                    null
                ));
            });

            resolve(new customCompetitors.customCompetitorsViewObject([], competitors));
        }).catch(error => {
            console.error(error);
            resolve(new customCompetitors.customCompetitorsViewObject());
        });
    }
};