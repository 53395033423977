<template>
  <div class="translate-block">
    <div class="translate-trigger"
         :class="{active: active}"
         @click="translateClickHandler">
      <custom-tooltip :width="active ? '130px' : '170px'">
        <template v-slot:slotTrigger>
          <svg-icon icon="translate"/>
        </template>
        <template v-slot:slotContent>
          <template v-if="active">
            Hide translation
          </template>
          <template v-else>
            Translate all keywords into English
          </template>
        </template>
      </custom-tooltip>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: "TranslateTool",
  props: {
    active: {
      type: Boolean,
    },
  },
  emits: ['translate-clicked'],
  data() {
    return {}
  },
  methods: {
    translateClickHandler(val) {
      this.$emit('translate-clicked');
    }
  }
})
</script>

<style lang="scss" src="./styles.scss" scoped></style>