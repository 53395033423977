export default class RangeFilterValue {
    currentMinValue = 0;
    currentMaxValue = 0;
    initMinValue = 0;
    initMaxValue = 0;
    minValue = 0;
    maxValue = 0;

    isAlwaysChanged = false;

    constructor(minValue = null, maxValue = null, initMinValue = null, initMaxValue = null) {
        this.minValue = parseFloat(minValue ?? 0);
        this.maxValue = parseFloat(maxValue ?? 9999999);
        this.initMinValue = parseFloat(initMinValue ?? this.minValue);
        this.initMaxValue = parseFloat(initMaxValue ?? this.maxValue);
        this.currentMinValue = this.initMinValue;
        this.currentMaxValue = this.initMaxValue;
    }

    clone() {
        let newObj = new RangeFilterValue();
        newObj.currentMinValue = this.currentMinValue;
        newObj.currentMaxValue = this.currentMaxValue;
        newObj.initMinValue = this.initMinValue;
        newObj.initMaxValue = this.initMaxValue;
        newObj.minValue = this.minValue;
        newObj.maxValue = this.maxValue;
        newObj.isAlwaysChanged = this.isAlwaysChanged;
        return newObj;
    }

    collectedData(key) {
        let result = {};
        result['filter[' + key + '][from]'] = this.currentMinValue;
        result['filter[' + key + '][to]'] = this.currentMaxValue;
        return result;
    }

    reset() {
        this.currentMinValue = this.initMinValue;
        this.currentMaxValue = this.initMaxValue;
    }

    setAlwaysChanged(value = true) {
        this.isAlwaysChanged = value;
    }

    isChanged() {
        return this.currentMinValue !== this.initMinValue || this.currentMaxValue !== this.initMaxValue;
    }

    setMinValue(value) {
        if (value < this.minValue) {
            value = this.minValue;
        }
        if (value > this.maxValue) {
            value = this.maxValue;
        }
        this.currentMinValue = value;

        if (+this.currentMaxValue < +this.currentMinValue) {
            this.currentMaxValue = this.currentMinValue;
        }
    }

    setMaxValue(value) {
        if (value < this.minValue) {
            value = this.minValue;
        }
        if (value > this.maxValue) {
            value = this.maxValue;
        }
        this.currentMaxValue = value;

        if (+this.currentMinValue > +this.currentMaxValue) {
            this.currentMinValue = this.currentMaxValue;
        }
    }
}