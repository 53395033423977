function collectEntry(title_entry, short_description_entry, description_entry) {
    let entriesArray = [];

    for (const key in title_entry) {
        entriesArray.push({
            locale: key,
            title_entry: title_entry[key],
            short_description_entry: short_description_entry[key],
            description_entry: description_entry ? description_entry[key] : null,
        })
    }
    return entriesArray;
}

function groupMissingKeywords(array) {
    if (!array) {
        return null;
    }
    return array.join(', ');
}

function groupCollectedEntries(entriesArray) {
    const KEYS = {
        'title_entry': 'title_entry',
        'short_description_entry': 'short_description_entry',
        'description_entry': 'description_entry',
    }
    let concatedResultArray = [];
    let groupedEntriesArray = new Set();

    entriesArray.forEach(entry => {
        const filteredEqualSample = entriesArray.filter(item => {
            const titleEntryEquality = item?.[KEYS['title_entry']]?.level === entry?.[KEYS['title_entry']]?.level;
            const titleEntryMissingEquality = JSON.stringify(item?.[KEYS['title_entry']]?.missing) === JSON.stringify(entry?.[KEYS['title_entry']]?.missing);
            const shortDescriptionEntryEquality = item?.[KEYS['short_description_entry']]?.level === entry?.[KEYS['short_description_entry']]?.level;
            const shortDescriptionEntryMissingEquality = JSON.stringify(item?.[KEYS['short_description_entry']]?.missing) === JSON.stringify(entry?.[KEYS['short_description_entry']]?.missing);

            if (titleEntryEquality && shortDescriptionEntryEquality && titleEntryMissingEquality && shortDescriptionEntryMissingEquality) {
                return item;
            }
        });

        if (filteredEqualSample.length > 0) {
            const stringified = JSON.stringify(filteredEqualSample);

            if (!groupedEntriesArray.has(stringified)) {
                groupedEntriesArray.add(stringified);
            }
        }
    });

    groupedEntriesArray.forEach(entry => {
        let parsedEntry = JSON.parse(entry);
        let sampleEntry = parsedEntry[0] ?? null;
        let concatedLocales = [];

        parsedEntry.forEach(innerEntry => {
            concatedLocales.push(innerEntry.locale);
        });

        concatedResultArray.push({
            locale: JSON.stringify(concatedLocales),
            title_entry: {
                level: sampleEntry.title_entry?.level,
                missing: groupMissingKeywords(sampleEntry.title_entry?.missing)
            },
            short_description_entry: {
                level: sampleEntry.short_description_entry?.level,
                missing: groupMissingKeywords(sampleEntry.short_description_entry?.missing)
            },
            description_entry: {
                level: sampleEntry?.description_entry?.level,
                missing: groupMissingKeywords(sampleEntry?.description_entry?.missing)
            },
        })
    });

    return concatedResultArray;
}

function replaceCountriesTitles(array, countryList) {
    return array.map(item => {
        const localesArray = JSON.parse(item.locale);
        let tempItem = {...item};
        let localesFullNameArray = [];
        localesArray.forEach(locale => {
            const matchedCountry = countryList?.filter(country => country?.code?.toLowerCase() === locale.toLowerCase());
            if (matchedCountry[0]?.name) {
                localesFullNameArray.push(matchedCountry[0]?.name);
            } else {
                localesFullNameArray.push(locale);
            }
        });

        tempItem.locale = localesFullNameArray?.join(', ');
        return tempItem;
    });
}

export default {
    SET_TRACKED_KEYWORDS({commit, state, rootState}, payload) {
        if (!payload) {
            return;
        }

        let countriesList;
        let dataArray = [];

        if (rootState?.countryList) {
            countriesList = [...rootState.countryList];
        }

        if (payload?.isArray) {
            dataArray = [...payload];
        } else {
            if (Object?.values(payload)) {
                dataArray = Object.values(payload)
            }
        }

        commit('SET_TRACKED_KEYWORDS', dataArray.map(keyword => {
                let aso_title_entry = {...keyword?.title_entry};
                let aso_short_description_entry = {...keyword?.short_description_entry};
                let aso_description_entry = {...keyword?.description_entry};
                let collectedEntries;

                if (rootState?.currentApp?.store?.key === 'APP_STORE') {
                    collectedEntries = collectEntry(aso_title_entry, aso_short_description_entry);
                } else {
                    collectedEntries = collectEntry(aso_title_entry, aso_short_description_entry, aso_description_entry);
                }
                let inAsoUnformatted = groupCollectedEntries(collectedEntries);
                let inAso = replaceCountriesTitles(inAsoUnformatted, countriesList);

                return {
                    applications_count: keyword?.applications_count ? keyword?.applications_count.toLocaleString('ru-RU') : null,
                    country_id: keyword?.country_id ?? null,
                    difficulty: keyword?.difficulty ?? null,
                    impression: keyword?.impression ? keyword?.impression.toLocaleString('ru-RU') : null,
                    id: keyword?.id ?? null,
                    keyword: keyword?.keyword ?? "",
                    popularity: keyword?.popularity ?? null,
                    inAso: inAso,
                }
            })
        )
    },
    SET_SUGGESTED_KEYWORDS({commit}, payload) {
        if (!payload) {
            return;
        }
        commit('SET_SUGGESTED_KEYWORDS', payload.map(keyword => {
                return {
                    selected: keyword?.is_included ?? false,
                    sap: keyword?.SAP ?? null,
                    name: keyword?.keyword ?? "",
                }
            })
        )
    },
    SET_SORTING_META({commit, state, rootState}, payload) {
        const metaObject = {
            sort_by: payload?.sort_by ?? state.sortingMeta.sort_by,
            sort_direction: payload?.sort_direction ?? state.sortingMeta.sort_direction,
            page: payload?.page ?? rootState.pageMeta.current_page,
            per_page: payload?.per_page ?? rootState.pageMeta.per_page,
            query: payload?.query ?? state.sortingMeta.query,
            popularity_min: payload?.popularity_min === null ? undefined : (payload?.popularity_min ?? state.sortingMeta?.popularity_min),
            popularity_max: payload?.popularity_max === null ? undefined : (payload?.popularity_max ?? state.sortingMeta?.popularity_max),
            difficulty_min: payload?.difficulty_min === null ? undefined : (payload?.difficulty_min ?? state.sortingMeta?.difficulty_min),
            difficulty_max: payload?.difficulty_max === null ? undefined : (payload?.difficulty_max ?? state.sortingMeta?.difficulty_max),
            impression_min: payload?.impression_min === null ? undefined : (payload?.impression_min ?? state.sortingMeta?.impression_min),
            impression_max: payload?.impression_max === null ? undefined : (payload?.impression_max ?? state.sortingMeta?.impression_max),
            rank_min: payload?.rank_min === null ? undefined : (payload?.rank_min ?? state?.sortingMeta?.rank_min),
            rank_max: payload?.rank_max === null ? undefined : (payload?.rank_max ?? state?.sortingMeta?.rank_max),
            'filter[range_impression][from]': payload['filter[range_impression][from]'] ?? state.sortingMeta?.['filter[range_impression][from]'],
            'filter[range_impression][to]': payload['filter[range_impression][to]'] ?? state.sortingMeta?.['filter[range_impression][to]'],
            'filter[range_rank][from]': payload['filter[range_rank][from]'] ?? state.sortingMeta?.['filter[range_rank][from]'],
            'filter[range_rank][to]': payload['filter[range_rank][to]'] ?? state.sortingMeta?.['filter[range_rank][to]'],
            'filter[range_sap][from]': payload['filter[range_sap][from]'] ?? state.sortingMeta?.['filter[range_sap][from]'],
            'filter[range_sap][to]': payload['filter[range_sap][to]'] ?? state.sortingMeta?.['filter[range_sap][to]'],
            'filter[range_words_count][from]': payload['filter[range_words_count][from]'] ?? state.sortingMeta?.['filter[range_words_count][from]'],
            'filter[range_words_count][to]': payload['filter[range_words_count][to]'] ?? state.sortingMeta?.['filter[range_words_count][to]'],
            'filter[like_keyword][query]': payload['filter[like_keyword][query]'] ?? state.sortingMeta?.['filter[like_keyword][query]'],
        };

        commit('SET_SORTING_META', metaObject);
    },
    RESET_SORTING_META({commit, state, rootState}, payload) {
        let metaObject = {...state.sortingMeta};

        const keys = ['sort_by', 'sort_direction', 'page', 'per_page'];

        for (const key in metaObject) {
            if (!keys?.includes(key)) {
                metaObject[key] = undefined;
            } else {
                metaObject[key] = payload[key];
            }
        }

        commit('SET_SORTING_META', metaObject);
    },
    CONSTRUCT_URL_QUERY_STRING({commit, state}) {
        let queryString = '';
        if (Object.keys(state.sortingMeta).length > 0) {
            for (const key in state.sortingMeta) {
                if (state.sortingMeta[key] !== null && state.sortingMeta[key] !== undefined && state.sortingMeta[key] !== false) {
                    queryString += '&' + key + '=' + state.sortingMeta[key];
                }
            }
        }

        commit('SET_URL_QUERY_STRING', queryString);
    },
    SET_LIVE_SEARCH_RESULTS({commit, state, rootGetters}, payload) {
        let myAppsIds = [];
        if (rootGetters?.myApps) {
            for (const key in rootGetters.myApps) {
                const item = rootGetters.myApps[key];
                myAppsIds.push(item?.id);
            }
        }

        const TYPES = {
            'apps': 'apps',
            'editorial-items': 'STORY',
            'app-bundles': 'BUNDLE',
            'developers': 'DEVELOPER PAGE',
            'in-apps': 'In-Apps Purchase',
            'preorder': 'PRE-ORDER',
            'subscription': 'SUBSCRIPTION',
        };

        let i = 0;

        let searchResults = {
            hasNextPage: payload.hasNexPage || payload.hasNextPage,
            hasScreenshots: payload.hasScreenshots,
            querySugg: payload?.querySugg ?? payload?.query_sugg ?? null,
            results: payload?.results?.map(resultItem => {
                const rating_num = resultItem?.data?.rating_num ? parseFloat(resultItem?.data?.rating_num).toFixed(1) : null;

                let logo = null;
                if (resultItem?.data?.logo) {
                    logo = resultItem?.data?.logo;
                } else if (resultItem?.data?.icon) {
                    logo = resultItem?.data?.icon;
                } else if (resultItem?.data?.image) {
                    logo = resultItem?.data?.image;
                }

                let screenshots = [];
                let croppedScreenshots = [];

                if (resultItem?.data?.screenshots) {
                    screenshots = resultItem?.data?.screenshots.slice(0, 4);

                    croppedScreenshots = screenshots.map(screenshot => {
                        const screen = screenshot?.src || screenshot;
                        let imageDirectionCss = '';
                        let formatImageString;

                        if (resultItem?.data?.store_key === 'APP_STORE') {
                            let splittedScreenUrl = screen?.split('/');
                            let poppedItem = splittedScreenUrl.pop();
                            let croppedScreen = splittedScreenUrl.join('/');
                            let imageFormat = poppedItem.split('.')[1];
                            let imageSizes = poppedItem.split('.')[0];
                            let imageSizesSplitted = imageSizes.split('x');
                            let imageWidth = parseInt(imageSizesSplitted[0]);
                            let imageHeight = parseInt(imageSizesSplitted[1]);

                            if (imageWidth > imageHeight) {
                                imageDirectionCss = 'horizontal';
                                formatImageString = '535x224bb.' + imageFormat;
                            } else {
                                imageDirectionCss = 'vertical';
                                formatImageString = '124x224bb.' + imageFormat;
                            }

                            croppedScreen += '/' + formatImageString;

                            return {
                                src: croppedScreen,
                                type: imageDirectionCss
                            }
                        } else {
                            const splittedScreenUrl = screen?.split('=');
                            const splittedSize = splittedScreenUrl[1] ? splittedScreenUrl[1]?.split('-') : null;

                            if (splittedSize === null || splittedSize?.length < 2) {
                                return screen;
                            }

                            const width = splittedSize[0];
                            const height = splittedSize[1];

                            if (width > height) {
                                imageDirectionCss = 'horizontal';
                                formatImageString = 'w535-h224';
                            } else {
                                imageDirectionCss = 'vertical';
                                formatImageString = 'w124-h224';
                            }

                            return {
                                src: `${splittedScreenUrl[0]}=${formatImageString}`,
                                type: imageDirectionCss
                            }

                        }
                    });
                }

                return {
                    type: resultItem?.type ?? null,
                    is_paid: resultItem?.is_paid ?? null,
                    positionIndex: resultItem?.is_paid ? null : ++i,
                    typeFormatted: resultItem?.type ? TYPES[resultItem?.type] : null,
                    competitorType: resultItem?.competitorType ?? null,
                    data: {
                        id: resultItem?.data?.id ?? null,
                        store_key: resultItem?.data?.store_key ?? null,
                        logo,
                        link: resultItem?.data?.link ?? null,
                        icon: resultItem?.data?.icon ?? null,
                        title: resultItem?.data?.title ?? null,
                        sub_title: resultItem?.data?.sub_title ?? null,
                        developer_name: resultItem?.data?.developer_name ?? null,
                        categories: resultItem?.data?.categories ? [...resultItem?.data?.categories] : [],
                        rating_num,
                        votes_num: resultItem?.data?.votes_num,
                        price: resultItem?.data?.price ?? null,
                        genre: resultItem?.data?.genre ?? null,
                        screenshots: croppedScreenshots,
                        apps: resultItem?.data?.apps ?? [],
                        isAdded: myAppsIds.includes(resultItem?.data?.id),
                        competitorType: resultItem?.competitorType ?? null,
                        // competitorType: resultItem?.competitor ?? null,
                        isLoading: resultItem?.data?.isLoading ?? false,
                        installs: resultItem?.data?.installs ?? null,
                        revenue: resultItem?.data?.revenue ?? null,
                    }
                }
            })
        };
        commit('SET_LIVE_SEARCH_RESULTS', searchResults);
    },
    SET_LIVE_SEARCH_ACTIVE_APPS({commit, state, getters}, payload) {
        commit('SET_LIVE_SEARCH_RESULTS',
            {
                querySugg: getters.liveSearchResults.querySugg,
                hasNextPage: getters.liveSearchResults.hasNextPage,
                hasScreenshots: getters.liveSearchResults.hasScreenshots,
                results: getters.liveSearchResults?.results?.map(resultItem => {
                    let resultData = {...resultItem};
                    resultData.data.isAdded = payload.id === resultItem?.data?.id ? true : resultItem?.data?.isAdded;

                    return {
                        ...resultData
                    }
                })
            }
        );
    },
    SET_LIVE_SEARCH_COMPETITORS_RESULTS({commit, getters}) {
        const notEmptyConcatedArray = getters.liveSearchResults?.results?.map(item => {
            if (item !== null) {
                const competitor = getters.competitorsResults?.resultIsCompetitors?.includes(+item?.data?.id);
                const nonCompetitor = getters.competitorsResults?.resultNotCompetitors?.includes(+item?.data?.id);
                let tempItem = {...item};

                if (competitor || nonCompetitor) {
                    tempItem.competitorType = competitor ? 'competitor' : (nonCompetitor ? 'nonCompetitor' : null);
                } else {
                    tempItem.competitorType = null;
                }

                return tempItem;
            }
        });

        commit('SET_LIVE_SEARCH_RESULTS',
            {
                querySugg: getters?.liveSearchResults.querySugg,
                hasNextPage: getters?.liveSearchResults?.hasNextPage,
                hasScreenshots: getters?.liveSearchResults?.hasScreenshots,
                results: notEmptyConcatedArray?.filter(item => (item !== null && item !== undefined))
            }
        );
    },
    SET_COMPETITORS_RESULTS({commit, state}, payload) {
        commit('SET_COMPETITORS_RESULTS', payload);
    },
    SET_POPULARITY_CHART_RESULTS({commit, state}, payload) {
        commit('SET_POPULARITY_CHART_RESULTS', payload);
    },
    SET_TRACKING_KEYWORDS_COUNT({commit, state}, payload) {
        commit('SET_TRACKING_KEYWORDS_COUNT', payload);
    },
};
