<template>
  <div class="user-limits-exceeded">
    <div class="warning-block">
      <svg-icon icon="danger-icon"/>
    </div>
    <div class="text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/UI/SvgIcon/index.vue";

export default {
  name: "UserLimitsExceeded",
  components: {SvgIcon}
}
</script>

<style lang="scss" src="./styles.scss"></style>