export default {
    SET_TRACKED_KEYWORDS({commit}, payload) {
        if (!payload) {
            return;
        }
        commit('SET_TRACKED_KEYWORDS', payload.map(keyword => {
                return {
                    results: keyword?.results ? keyword?.results.toLocaleString('ru-RU') : null,
                    country_id: keyword?.country_id ?? null,
                    daily_impression: keyword?.daily_impression ? keyword?.daily_impression.toLocaleString('ru-RU') : null,
                    keyword: keyword?.keyword ? {...keyword.keyword} : '',
                    id: keyword.keyword?.tracked_id,
                    sap: keyword?.sap ?? null,
                }
            })
        )
    },
    SET_SUGGESTED_KEYWORDS({commit}, payload) {
        if (!payload) {
            return;
        }
        commit('SET_SUGGESTED_KEYWORDS', payload.map(keyword => {
                return {
                    selected: keyword?.is_included ?? false,
                    sap: keyword?.SAP ?? null,
                    name: keyword?.keyword ?? "",
                }
            })
        )
    },
    SET_POPULARITY_CHART_RESULTS({commit, state}, payload) {
        commit('SET_POPULARITY_CHART_RESULTS', payload);
    },
    SET_TRACKING_KEYWORDS_COUNT({commit, state}, payload) {
        commit('SET_TRACKING_KEYWORDS_COUNT', payload);
    },
    PARSE_URL({commit, state}, payload) {
        const splittedArray = payload.split('&');
        const tempInitObject = {
            filter: {},
            selectedApp: {
                id: null,
                title: null,
            },
            currentFilterTab: {
                title: null,
                value: null,
            },
            country_code: null,
            date: {
                start: new Date(),
                end: new Date(),
            },
            sap: {
                min: null,
                max: null,
                filter: null,
            },
            impressions: {
                min: null,
                max: null
            },
            rank: {
                min: null,
                max: null
            },
            keyword_tracked: {
                title: null,
                value: null
            },
            keyword_declined: {
                title: null,
                value: null
            },
            sources: {
                list: {
                    0: []
                }
            }
        };

        splittedArray.forEach(item => {
            if (item !== '') {
                const splittedPair = item.split('=');

                if (splittedPair[0].includes('filter[0]')) {
                    tempInitObject.currentFilterTab.value = splittedPair[1] !== 'all' ? splittedPair[1] : null;
                }

                if (splittedPair[0].includes('country')) {
                    tempInitObject.country_code = splittedPair[1];
                }

                if (splittedPair[0].includes('application_id')) {
                    tempInitObject.selectedApp.id = splittedPair[1];
                }

                if (splittedPair[0].includes('application_title')) {
                    tempInitObject.selectedApp.title = splittedPair[1];
                }

                if (splittedPair[0].includes('date_from')) {
                    const splittedDateArray = splittedPair[1].split('-');
                    const formattedDate = `${splittedDateArray[2]}-${splittedDateArray[1]}-${splittedDateArray[0]}`;
                    tempInitObject.date.start = new Date(formattedDate);
                }

                if (splittedPair[0].includes('date_to')) {
                    const splittedDateArray = splittedPair[1].split('-');
                    const formattedDate = `${splittedDateArray[2]}-${splittedDateArray[1]}-${splittedDateArray[0]}`;
                    tempInitObject.date.end = new Date(formattedDate);
                }

                if (splittedPair[0].includes('[range_sap][from]')) {
                    tempInitObject.sap.min = splittedPair[1];
                    tempInitObject['filter[range_sap][from]'] = splittedPair[1];
                }

                if (splittedPair[0].includes('[range_sap][to]')) {
                    tempInitObject.sap.max = splittedPair[1];
                    tempInitObject['filter[range_sap][to]'] = splittedPair[1];
                }

                if (splittedPair[0].includes('[range_impression][from]')) {
                    tempInitObject.impressions.min = splittedPair[1];
                    tempInitObject['filter[range_impression][from]'] = splittedPair[1];
                }

                if (splittedPair[0].includes('[range_impression][to]')) {
                    tempInitObject.impressions.max = splittedPair[1];
                    tempInitObject['filter[range_impression][to]'] = splittedPair[1];
                }

                if (splittedPair[0].includes('[range_rank][from]')) {
                    tempInitObject.rank.min = splittedPair[1];
                    tempInitObject['filter[range_rank][from]'] = splittedPair[1];
                }

                if (splittedPair[0].includes('[range_rank][to]')) {
                    tempInitObject.rank.max = splittedPair[1];
                    tempInitObject['filter[range_rank][to]'] = splittedPair[1];
                }

                if (splittedPair[0].includes('[keyword_tracked][isTracked]')) {
                    const title = splittedPair[1] === 'true' ? 'Added' : 'Not added'
                    tempInitObject.keyword_tracked.value = splittedPair[1];
                    tempInitObject.keyword_tracked.title = title;
                    tempInitObject['filter[keyword_tracked][isTracked]'] = splittedPair[1];
                }

                if (splittedPair[0].includes('[keyword_declined][isDeclined]')) {
                    const title = splittedPair[1] === 'true' ? 'Added' : 'Not added'
                    tempInitObject.keyword_declined.value = splittedPair[1];
                    tempInitObject.keyword_declined.title = title;
                    tempInitObject['filter[keyword_declined][isDeclined]'] = splittedPair[1];
                }

                if (splittedPair[0].includes('[sources][list][0]')) {
                    const title = splittedPair[1] === 'true' ? 'Added' : 'Not added'
                    tempInitObject.keyword_declined.value = splittedPair[1];
                    tempInitObject.keyword_declined.title = title;
                    tempInitObject['filter[sources][list][0]'] = splittedPair[1];
                }
            }
        });
        commit('PARSE_URL', tempInitObject);
    },

};
