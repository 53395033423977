export default class FilterItem {
    type = null;
    name = null;
    key = null;
    value = null;
    hidden = false;
    clone = () => {
        let newObj = new FilterItem();
        newObj.type = this.type;
        newObj.name = this.name;
        newObj.key = this.key;
        newObj.value = this.value.clone();
        newObj.hidden = this.hidden;
        return newObj;
    }

    collectedData = () => {
        return this.value.collectedData(this.key);
    }
}