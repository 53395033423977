export default class RefsStore {
    static refs = {};

    static addRef = (key, ref) => {
        this.refs[key] = ref;
    }

    static getRef = (key) => {
        if (this.refs[key]) {
            return this.refs[key];
        }

        console.error(`Ref ${key} not found in RefsStore`);

        return null;
    }
}