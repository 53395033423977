<script>
export default {
  name: "EmptyResult",
  props: {
    filtersReset: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    resetClick() {
      this.$emit('reset');
    }
  }
}
</script>

<template>
<div>
  <div>🥲</div>
  <div class="font-20 mt-12">Sorry, no results found by selected criteria</div>
  <template v-if="filtersReset">
    <div class="font-16 mt-16">Try to change the filters</div>
    <div class="font-16 mt-12">or</div>
    <div class="font-16 mt-12 color-primary cursor-pointer underline" @click="resetClick">Reset All Filters</div>
  </template>
</div>
</template>

<style scoped lang="scss">

</style>