<template>
  <div>
    <div class="order-radaso-button" @click="isOpened = true">
      <template v-if="value === 'full-aso-support'">
        <svg-icon icon="order-kw-research" />
        Order Full ASO Support
      </template>
      <template v-if="value === 'keywords-research'">
        <svg-icon icon="order-kw-research" />
        Order KW Research
      </template>
      <template v-if="value === 'aso-audit'">
        <svg-icon icon="order-kw-research" />
        Order ASO Audit
      </template>
      <template v-if="value === 'metadata-optimization'">
        <svg-icon icon="order-kw-research" />
        Order Metadata Optimization
      </template>
      <template v-if="value === 'visual-aso'">
        <svg-icon icon="order-kw-research" />
        Order Visuals Creation
      </template>
      <template v-if="value === 'launch-support'">
        <svg-icon icon="order-kw-research" />
        Order App Launch Support
      </template>
    </div>

    <custom-modal :opened="isOpened"
                  class-name="order-radaso-modal"
                  @closeModal="isOpened = false"
                  :max-width="1350">
      <order-radaso-modal :pre-selected="preSelected" @emailSent="emailSent" @closeModal="isOpened = false" />
    </custom-modal>

    <alert :text="alertText" @hide="alertText = ''" />
  </div>
</template>
<script>
import OrderRadasoModal from "@/components/OrderRadaso/Modal/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import Alert from "@/components/UI/Alert/index.vue";

export default {
  name: 'OrderRadasoButton',
  components: {
    Alert,
    SvgIcon,
    OrderRadasoModal,
  },
  props: {
    preSelected: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpened: false,
      alertText: '',
    }
  },
  methods: {
    emailSent() {
      this.alertText = 'Thank you for your request. We will contact you soon.';
    },
  },
  computed: {
    value() {
      return this.preSelected[Math.floor(Math.random() * this.preSelected.length)];
    },
  },
}
</script>
<style lang="scss">
.order-radaso-button {
  color: var(--primary-default);
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  line-height: 25px;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
}

.order-radaso-modal {
  section.main {
    overflow-y: auto;
    padding: 0;
  }

  header {
    display: none!important;
  }

  .modal {
    max-width: 1350px;
  }
}
</style>