import {SearchFilterData} from "@/api/modules/tables/search-filter-data";

export const SimpleDataProvider = class {
    constructor(items, filterConfigs, searchByConfigs, pagination, sorting, loading) {
        this.items = items ?? [];
        this.filterConfigs = filterConfigs ?? [];
        this.searchByConfigs = searchByConfigs ?? [];
        this.searchFilterData = new SearchFilterData(pagination, sorting);
        this.loading = loading ?? false;
    }

    getSearchFilterData() {
        return this.searchFilterData;
    }

    setSearchFilterData(data) {
        this.searchFilterData = data;
    }

    getPagination() {
        return this.searchFilterData.pagination;
    }

    getSorting() {
        return this.searchFilterData.sorting;
    }

    getItems() {
        return this.prepareData();
    }

    getAllItems() {
        return this.items;
    }

    setItems(items) {
        this.items = items;
        this.loading = false;
        this.searchFilterData.pagination.reset();
    }

    isLoading() {
        return this.loading;
    }

    setLoading(loading) {
        this.loading = loading;
    }

    filteredData() {
        let items = this.filterData([...this.items]);
        return this.filterBySearchInput(items);
    }

    prepareData() {
        let items = this.filteredData();
        items = this.sortData(items);

        this.searchFilterData.pagination.setTotal(items.length);

        return items.slice(this.searchFilterData.pagination.from - 1, this.searchFilterData.pagination.to);
    };

    filterData(items) {
        let preparedFilters = this.prepareFilters(this.searchFilterData.filters, this.filterConfigs);

        if (preparedFilters.length === 0) {
            return items;
        }

        return items.filter((item) => {
            return preparedFilters.every((filter) => {
                let value;
                if (typeof filter.filtering === 'function') {
                    value = filter.filtering(item);
                } else {
                    value = item[filter.filtering];
                }

                if (filter.type === 'range') {
                    value = parseFloat(value);

                    if (isNaN(value) && filter.from === 0 && filter.to === 0) {
                        return true;
                    }

                    return value >= filter.from && value <= filter.to;
                } else if (filter.type === 'dropdown-multi') {
                    if (Array.isArray(value)) {
                        return value.some((itemValue) => {
                            return filter.selected.indexOf(itemValue) > -1;
                        });
                    }

                    return filter.selected.indexOf(value) > -1;
                }
            });
        });
    };

    prepareFilters(appliedFilters, filtersConfigs) {
        let result = [];
        filtersConfigs.forEach((filterData) => {
            if (filterData.type === 'range') {
                let from = appliedFilters['filter[' + filterData.filter + '][from]'];
                let to = appliedFilters['filter[' + filterData.filter + '][to]'];
                if (from !== undefined && to !== undefined) {
                    result.push({
                        filtering: filterData.filtering,
                        type: 'range',
                        from: appliedFilters['filter[' + filterData.filter + '][from]'],
                        to: appliedFilters['filter[' + filterData.filter + '][to]'],
                    });
                }
            } else if (filterData.type === 'dropdown-multi') {
                let selected = [];
                let keyPrefix = 'filter[' + filterData.filter + '][list]';
                filterData.presetsData.forEach((item, index) => {
                    let key = keyPrefix + '[' + index + ']';
                    if (appliedFilters[key] !== undefined) {
                        selected.push(appliedFilters[key]);
                    }
                });
                if (selected.length > 0) {
                    result.push({
                        filtering: filterData.filtering,
                        type: 'dropdown-multi',
                        selected: selected,
                    });
                }
            }
        });

        return result;
    }

    filterBySearchInput(items) {
        if (this.searchFilterData.searchInput.length === 0) {
            return items;
        }

        let input = this.searchFilterData.searchInput.toLowerCase();

        return items.filter((item) => {
            return this.searchByConfigs.some((column) => {
                let value;
                if (typeof column === 'function') {
                    value = column(item);
                } else {
                    value = item[column];
                }

                if (value === undefined || value === null) {
                    return false;
                }

                return value.toString().toLowerCase().includes(input);
            })
        });
    };

    sortData(items) {
        let sorted = items;

        if (this.searchFilterData.sorting.sortBy !== null) {
            let aValue;
            let bValue;
            let sortResult;

            sorted.sort((a, b) => {
                if (typeof this.searchFilterData.sorting.sortBy === 'function') {
                    aValue = this.searchFilterData.sorting.sortBy(a);
                    bValue = this.searchFilterData.sorting.sortBy(b);
                } else {
                    aValue = a[this.searchFilterData.sorting.sortBy];
                    bValue = b[this.searchFilterData.sorting.sortBy];
                }

                if (aValue === bValue) {
                    return 0;
                }

                if (aValue === null) {
                    return 1;
                }
                if (bValue === null) {
                    return -1;
                }

                if (aValue < bValue) {
                    sortResult = -1;
                } else {
                    sortResult = 1;
                }

                if (this.searchFilterData.sorting.direction === 'desc') {
                    sortResult = sortResult * -1;
                }

                return sortResult;
            });
        }

        return sorted;
    };
}
