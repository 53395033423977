<template>
  <div :class="{'country-search': true, 'flags-only': mode === 'flags'}">
    <custom-inline-search placeholder=""
                          @search-input-changed="countryInputChanged"
                          @dropdown-opened="countriesDropdownOpened"
                          @dropdown-closed="countriesDropdownClosed"
                          :with-result-slots="true"
                          :show-search-input="mode !== 'flags'"
                          :min-input-length="0"
                          :show-caret="true"
                          :full-width-content="true"
                          results-max-height="256px"
                          :initial-data="'initial country name'"
                          :close-from-outside-counter="countriesCloseCounter"
                          :search-results="countries">

      <template v-slot:heading-info>
        <dynamic-image classes="country-flag"
                       :width="14"
                       :height="10"
                       :country-code="currentCountry?.code"/>
        <div v-if="mode !== 'flags'" class="country-name">
          {{ currentCountry?.name ?? '' }}
        </div>
      </template>

      <template v-slot:results>
        <template v-if="isLoading">
          <div class="preloader-wrap">
            <preloader-table-small :loader-size="'40px'"></preloader-table-small>
          </div>
        </template>
        <template v-else-if="filterCountryInSearch?.length === 0">
          <div class="results-group">
            <div class="text">No data found</div>
          </div>
        </template>
        <template v-else>
          <div v-for="country in filterCountryInSearch"
               @click="countryFilterSelected(country)"
               class="result-item">
            <dynamic-image classes="country-flag"
                           :width="14"
                           :height="10"
                           :country-code="country?.code"/>
            <div class="item-name">
              {{ country?.name ?? '' }}
              <span class="counts" v-if="country?.keywords_count !== 0">
                {{ country?.keywords_count ?? '' }}
              </span>
            </div>
            <div class="favorite-star active"
                 v-if="country?.keywords_count > 0">
              <img src="@/assets/svg/star-filled.svg"
                   width="15"
                   height="15"
                   class="filled-star star-icon"
                   alt="Icon"/>
            </div>
          </div>
        </template>
      </template>
    </custom-inline-search>
  </div>
</template>

<script>
import CustomInlineSearch from "@/components/Forms/CustomInlineSearch/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";

export default {
  name: 'SelectCountry',
  components: {
    PreloaderTableSmall,
    CustomInlineSearch,
  },
  props: {
    currentCountry: Object,
    countries: Array,
    isLoading: Boolean,
    mode: {
      type: String,
      default: 'full',
    },
  },
  emits: [
    'changed',
    'opened',
  ],
  data() {
    return {
      countriesCloseCounter: 0,
      countrySearchInput: '',
    };
  },
  methods: {
    async countryFilterSelected(value) {
      this.countriesCloseCounter++;
      this.countrySearchInput = '';
      this.$emit('changed', value);
    },
    countriesDropdownOpened() {
      this.$emit('opened');
    },
    countriesDropdownClosed() {
      this.countrySearchInput = '';
    },
    countryInputChanged(e) {
      this.countrySearchInput = e;
    },
  },
  computed: {
    filterCountryInSearch() {
      const inputLowered = this.countrySearchInput?.toLowerCase();
      return this.countries?.filter(item => item?.name?.toLowerCase()?.includes(inputLowered));
    },
  }
}
</script>

<style src="./styles.scss" lang="scss"></style>
