<template>
  <div class="no-data-block"
       :style="{minHeight: minHeight}">
    <div class="content">
      <span class="emoji">😢</span>
      <span class="text">
        <slot name="text">No keywords that match your criteria.</slot>
      </span>
      <slot name="btn-block"></slot>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "NoDataFoundBlock",
  props: {
    minHeight: {
      type: String,
      default: 'calc(100vh - 200px);'
    }
  }
})
</script>

<style scoped lang="scss">
.no-data-block {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  box-shadow: 0 8px 48px 0 rgba(184, 186, 229, 0.16);
  background-color: #fff;
  padding: 20px;
  //min-height: calc(100vh - 200px);

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  span {
    display: block;
    text-align: center;
  }

  .emoji {
    font-size: 24px;
    margin-bottom: 4px;
  }

  .text {
    font-size: 18px;
    line-height: 1.44;
    color: var(--neutral-800);
    margin-bottom: 16px;
  }

  .btn-block {
    display: flex;
    justify-content: center;
  }
}
</style>