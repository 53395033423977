<template>
  <div class="form-group switch-toggle">
    <div class="option-text"
         @click="setToggleModel(false)"
         :class="{active: !toggleModel}"
         id="opt-one">
      <slot name="optionOne">
        {{ optionOne }}
      </slot>
    </div>
    <input type="checkbox"
           id="check-toggle"
           class="chkbx-toggle"
           name="custom-checkbox"
           :value="toggleModel">
    <label for="check-toggle"
           :class="{checked: toggleModel}"
           @click="switchToggleChanged">
    </label>
    <div class="option-text"
         @click="setToggleModel(true)"
         :class="{active: toggleModel}"
         id="opt-two">
      <slot name="optionTwo">
        {{ optionTwo }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SwitchToggle",
  props: {
    optionOne: {
      type: String
    },
    optionTwo: {
      type: String
    },
    parentToggleModel: {
      type: Boolean,
      default: 0,
    }
  },
  data() {
    return {
      toggleModel: this.parentToggleModel,
    }
  },
  methods: {
    switchToggleChanged() {
      this.toggleModel = !this.toggleModel;
      this.$emit('toggle-changed', this.toggleModel);
    },
    setToggleModel(value) {
      this.toggleModel = value;
      this.$emit('toggle-changed', this.toggleModel);
    }
  }
}
</script>

<style scoped lang="scss" src="./styles.scss"></style>