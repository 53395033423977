<template>
  <div class="live-search-input">
    <search-app-input @search-input-changed="searchInputChanged"
                      height="36px"
                      :placeholder="placeholder"
                      :show-current="showCurrent"
                      :selected-app="searchApp"
                      :search-results="showPresets ? presets : liveSearchApps"
                      :show-results-anyway="showPresets"
                      :app-search-loaded="appSearchLoaded"
                      results-max-height="256px"
                      @outside="searchInputClose"
                      @focus-in="focusIn">
      <template v-slot:searchInputBlock>
        <select-country v-if="countries.length > 0 || isCountriesLoading === true"
                        :current-country="searchCountry"
                        :countries="sortedCountries"
                        mode="flags"
                        key="live-search-country"
                        class="live-search-country"
                        :is-loading="isCountriesLoading"
                        @changed="searchCountry = $event"
        />
      </template>

      <template v-slot:selectOther>
        Search Any App in Store
      </template>
      <template v-slot:aboveResults>
        <div class="results-title" v-if="showPresets">
          {{ presetsTitle }}
        </div>
      </template>
      <template v-slot:results="slotProps">
        <div class="result-item-row"
             @click="searchResultsAppClicked(slotProps.result)">
          <app-title :title="slotProps.result?.info?.title ?? ''"
                     :store="slotProps.result?.store?.key"
                     :logo-size="22"
                     :store-icon-size="15"
                     :logo="slotProps.result?.info?.logo" />
        </div>
      </template>
    </search-app-input>
    <div v-if="showSelectFromCompetitors && searchApp.id !== undefined"
         class="select-competitors"
         @click="openCompetitorsModal">
      Or select from your competitors
    </div>
  </div>

  <competitors_select_modal v-if="showSelectFromCompetitors && currentApp.id !== undefined"
                            :current-app="currentApp"
                            ref="competitors_select_modal"
                            :init-select-emit="false"
                            :one-select-mode="true"
                            :current-country="currentCountry"
                            :key="currentApp.id + '|' + searchCountry.id"
                            @save-and-continue="handleSelectCompetitors"
  />
</template>

<script>
import SearchAppInput from "@/components/UI/SearchAppInput/index.vue";
import AppImage from "@/components/UI/ImageLazyLoad/index.vue";
import {httpRequest} from "@/api";
import Competitors_select_modal from "@/views/CompetitorsFinderPage/Competitors/competitors_select_modal.vue";
import AppTitle from "@/components/Billets/AppTitle/index.vue";
import SelectCountry from "@/components/Dropdowns/SelectCountry/index.vue";
import {mapGetters} from "vuex";

export default {
  name: 'LiveSearchWithPresets',
  components: {
    SelectCountry,
    AppTitle,
    Competitors_select_modal,
    AppImage,
    SearchAppInput,
  },
  props: {
    currentCountry: Object,
    selectedApp: Object,
    store: String,
    presets: Array,
    presetsTitle: {
      type: String,
      default: 'My Apps',
    },
    showCurrent: {
      type: Boolean,
      default: false,
    },
    showSelectFromCompetitors: {
      type: Boolean,
      default: true,
    },
    countries: {
      type: Array,
      default: [],
    },
    isCountriesLoading: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
  },
  emits: [
    'changed',
  ],
  data() {
    return {
      appSearchLoaded: true,
      showPresets: false,
      liveSearchApps: [],
      storeKey: this.store,
      searchCountry: this.currentCountry,
      searchApp: {},
      lastSearch: null,
    };
  },
  mounted() {
    this.searchApp = this.selectedApp;
    if (this.storeKey === undefined) {
      this.storeKey = this.searchApp?.store?.key;
    }
  },
  methods: {
    openCompetitorsModal() {
      this.$refs.competitors_select_modal.openModal();
    },
    async searchInputChanged(string) {
      this.lastSearch = string;
      if (string?.length > 2) {
        this.showPresets = false;
        this.appSearchLoaded = false;
        this.liveSearchApps = [];

        const url = process.env.VUE_APP_API_URL
          + this.$ApiUrls.liveSearch.LIVE_SEARCH
          + '?country_code=' + this.searchCountry.code
          + '&device=iphone'
          + '&store_key=' + this.storeKey
          + '&phrase=' + string
          + '&apps_only=1';
        const result = await httpRequest('GET', url);

        let searchApps = result?.results;
        this.liveSearchApps = searchApps?.map(resultItem => {
          return {
            id: resultItem?.data?.id,
            info: {
              logo: resultItem?.data?.logo,
              title: resultItem?.data?.title,
              store_link: resultItem?.data?.url,
            },
            store: {
              key: resultItem?.data?.store_key,
            },
          }
        });

        this.appSearchLoaded = true;
      }
    },
    searchResultsAppClicked(item) {
      let app = {...item};
      app.title = item.info.title;
      app.logo = item.info.logo;
      this.searchApp = app;
      this.$emit('changed', this.searchApp, this.searchCountry);
      this.searchInputClose();
    },
    handleSelectCompetitors (app) {
      this.searchApp = {
        id: app.competitorId,
        info: {
          logo: app.logo,
          store_link: app.storeUrl,
          title: app.title,
        },
        store: {
          key: app.store,
        },
      };

      this.searchResultsAppClicked(this.searchApp);
    },
    searchInputClose() {
      this.appSearchLoaded = false;
      this.showPresets = false;
      this.liveSearchApps = [];
    },
    focusIn() {
      this.appSearchLoaded = true;
      this.showPresets = true;
      this.liveSearchApps = [];
    },
  },
  computed: {
    ...mapGetters([
      'currentApp',
    ]),
    sortedCountries() {
      return this.countries.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  watch: {
    searchCountry(oldVal, newVal) {
      if (oldVal.id !== newVal.id) {
        this.searchInputChanged(this.lastSearch);
      }
    },
  },
}
</script>

<style src="./styles.scss" lang="scss"></style>
