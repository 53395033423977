import {httpRequest} from "../index";

export default {
    RECOMMENDED_KEYWORDS: 'api/application/get-recommended-keywords', //GET {app_id: int, country_code: string}
    DECLINE_KEYWORDS: 'api/saved-keywords/decline', //POST {app_id: int, country_code: string, keywords: array}
    UNDECLINE_KEYWORDS: 'api/saved-keywords/unDecline', //POST {app_id: int, country_code: string, keywords: array}
    DATA_COUNTS: 'api/data-counts', //GET ?country_code=XX&app_id=XXXX

    unDeclineKeywords(appId, countryCode, keywords) {
        let formData = new FormData();
        formData.append("app_id", appId);
        formData.append("country_code", countryCode);
        keywords.forEach((keyword, idx) => {
            formData.append(`keywords[${idx}]`, keyword);
        });

        return httpRequest('POST', process.env.VUE_APP_API_URL + this.UNDECLINE_KEYWORDS, formData);
    },

    declineKeywords(appId, countryCode, keywords) {
        let formData = new FormData();
        formData.append("app_id", appId);
        formData.append("country_code", countryCode);
        keywords.forEach((keyword, idx) => {
            formData.append(`keywords[${idx}]`, keyword);
        });

        return httpRequest('POST', process.env.VUE_APP_API_URL + this.DECLINE_KEYWORDS, formData);
    }
};