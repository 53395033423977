<template>
  <button v-if="!link && to === '/'"
          class="btn"
          :disabled="disabled"
          :class="[mode, classes, {disabled: disabled}]"
          :style="{height, minWidth: width, fontSize, borderRadius}">
    <slot></slot>
  </button>
  <router-link v-else :to="to"
               class="btn"
               :target="isBlank ? '_blank' : '_self'"
               :disabled="disabled"
               :class="[mode, classes, {disabled: disabled}]"
               :style="{height, minWidth: width, fontSize, borderRadius}">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: 'default-btn'
    },
    link: {
      type: Boolean,
      required: false,
      default: false
    },
    to: {
      type: String,
      required: false,
      default: '/'
    },
    height: {
      type: String,
      required: false,
      default: '42px'
    },
    width: {
      type: String,
      required: false,
      default: '142px'
    },
    fontSize: {
      type: String,
      required: false,
      default: '16px'
    },
    borderRadius: {
      type: String,
      required: false,
      default: '4px'
    },
    classes: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isBlank: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  font-weight: normal;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .3s;
  position: relative;
  z-index: 100;
  outline: none;
  background-color: var(--primary-default);

  &:before {
    background-image: none;
    border-radius: inherit;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -100;
    transition: opacity 0.3s;
  }

  &:hover {
    background-color: var(--primary-hover);
    background-image: none;

    &:before {
      opacity: 1;
    }
  }
}

.btn.default-btn {
  color: #ffffff;
  border: none;
}

.btn.disabled {
  cursor: not-allowed;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background: var(--neutral-400);
  color: #A3B0C5;
}

.btn.disabled.gray {
  background-color: #e4eaf4;
  background-image: none;

  &:before {
    background-image: none;
  }
}

.default-new {
  border-radius: 8px;
  box-shadow: 0 8px 48px 0 rgba(184, 186, 229, 0.16);
  background-color: #13182b;
  color: #fff;
  padding: 0 16px;

  &:hover {
    background-image: none;
    background-color: #1c233d;

    &:before {
      display: none;
    }
  }
}

.flat {
  border: solid 1px transparent;
  background-color: #ffffff;
  color: var(--primary-default);
}

.outline {
  border: solid 1px #5d71fa;
  background-color: #ffffff;
  color: var(--primary-default);
}

.flat:hover,
.outline:hover, {
  border: solid 1px #5d71fa;
  background-color: #ffffff;
  color: var(--primary-default);

  &:hover {
    border: transparent;
    color: #ffffff;

    &:before {
      opacity: 1;
    }
  }
}

.outline.disabled {
  cursor: not-allowed;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  //background: var(--neutral-400)!important;
  border: 1px solid var(--neutral-600)!important;
  background: transparent!important;
  color: #A3B0C5!important;
  //border: none!important;
}

.btn-standard.outline {
  border: 1px solid var(--primary-default);
  background-color: #fff;
  color: var(--primary-default);
}

.btn-standard.outline:hover {
  background-color: var(--primary-hover);
  background-image: none !important;

  &:before, &:after {
    display: none !important;
  }
}

.btn.outline:hover {
  border: solid 1px #5d71fa;
  color: #fff;
  background-color: var(--primary-hover);
  background-image: none !important;
}

.just-text {
  border: none;
  color: var(--primary-default);
  background: #ffffff;

  &:hover {
    border: none;
    color: var(--primary-default);
    background: #ffffff;
    text-decoration: underline;
  }
}
</style>