<template>
  <custom-modal :opened="showModal"
                :max-width="520"
                @closeModal="closeThisModal"
                class-name="congrats-modal">
    <div class="modal-message">Congratulations! Your payment has been successfully processed 🤑</div>
    <div class="footer-text">
      <b>You now have access to all of our amazing tools and features 🤩.</b> If you have any questions or concerns, please
      don't hesitate to reach out to our friendly support team. <br />We're always happy to help 💛!
    </div>
    <template v-slot:footer-action>
      <div class="btn-block action-btns">
        <base-button height="40px"
                     width="200px"
                     border-radius="4px"
                     class="btn-standard"
                     @click="closeThisModal">Continue
        </base-button>
      </div>
    </template>
  </custom-modal>
</template>

<script>
export default {
  name: "CongratulationsModal",
  props: {
    planName: {
      type: String,
      default: 'Plan name'
    },
    planKey: {
      type: String,
    },
    openShowModalOutside: {
      type: Number
    },
  },
  data() {
    return {
      showModal: true
    }
  },
  methods: {
    closeThisModal() {
      this.closeModal('showModal');
      let urlString = '/';
      window.history.replaceState({}, '', urlString);
    }
  }
}
</script>

<style lang="scss">
.congrats-modal {
  header {
    padding: 16px 32px 6px !important;
  }

  .main {
    padding: 0 32px 32px !important;
  }

  .modal-message {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: var(--neutral-900);
    margin-bottom: 12px;
  }

  .footer-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--neutral-800);
    margin-bottom: 24px;
    b {
      font-weight: 600;
    }
  }

  .action-btns {
    .btn {
      width: 200px;
      min-width: 200px;
      max-width: 200px;
    }
  }
}
</style>