export default {
    suggestedKeywords: state => {
        return state.suggestedKeywords
    },
    sortingMeta: state => {
        return state.sortingMeta;
    },
    urlQueryString: state => {
        return state.urlQueryString;
    },
    liveSearchResults: state => {
        return state.liveSearchResults;
    },
    popularityChartResults: state => {
        return state.popularityChartResults;
    },
    inputCurrentKeyword: state => {
        return state.inputCurrentKeyword;
    },
};