<template>
  <custom-modal :opened="showModal"
                :max-width="520"
                @closeModal="closeThisModal"
                class-name="upgrade-successful-modal">
    <div class="modal-message">Update successful!</div>
    <div class="footer-text">
      🎉 We are thrilled to inform you that your payment plan has been updated! Your new plan is {{ planName }} 💳 With
      your new
      plan, you now have updated limits on the number of apps, keywords, and competitors you can track.
    </div>
    <template v-slot:footer-action>
      <div class="btn-block action-btns">
        <base-button height="40px"
                     width="200px"
                     border-radius="4px"
                     class="btn-standard"
                     @click="closeThisModal">Continue
        </base-button>
      </div>
    </template>
  </custom-modal>
</template>

<script>
export default {
  name: "UpgradeSuccessfulModal",
  props: {
    planName: {
      type: String,
      default: 'Plan name'
    },
    planKey: {
      type: String,
    },
    openShowModalOutside: {
      type: Number
    },
  },
  data() {
    return {
      showModal: true
    }
  },
  methods: {
    closeThisModal() {
      this.closeModal('showModal');
      let urlString = '/';
      window.history.replaceState({}, '', urlString);
    }
  }
}
</script>

<style lang="scss">
.upgrade-successful-modal {
  header {
    padding: 16px 32px 6px !important;
  }

  .main {
    padding: 0 32px 32px !important;
  }

  .modal-message {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: var(--neutral-900);
    margin-bottom: 12px;
  }

  .footer-text {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: var(--neutral-800);
    margin-bottom: 24px;
  }

  .action-btns {
    .btn {
      width: 200px;
      height: 40px;
      min-width: 200px;
      max-width: 200px;
      border-radius: 4px;
    }
  }
}
</style>