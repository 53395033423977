<template>
  <router-link :to="menuItem.link"
               class="sublist-item"
               :class="classes"
               exact>
    <span class="sublist-item-title"
          :class="{shortened: menuItem.labelContent}"
          :data-intercom="menuItem.title">{{ menuItem.title }}</span>
    <template v-if="menuItem.labelContent">
      <menu-label :label-content="menuItem.labelContent"
                  :label-type="labelType"></menu-label>
    </template>
  </router-link>
</template>

<script>
import Label from '@/components/UI/Label/index';

export default {
  name: "index",
  components: {
    'menu-label': Label
  },
  props: {
    menuItem: {
      type: Object
    },
    labelType: {
      type: String
    },
    classes: {
      type: String
    }
  },
}
</script>

<style scoped>

</style>