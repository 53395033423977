import {formatDate} from "@/utils/utils";

export default {
    RANKING_KEYWORDS: 'api/application/ranking-keywords',
    RANKING_KEYWORDS_TOTALS: 'api/applications/ranking-keywords-totals',

    rankingsLink(appData, countryCode, dateFrom = null, dateTo = null) {
        if (!appData) {
            return '/search-terms/ranking-keywords';
        }

        if (!dateTo) {
            dateTo = new Date();
            dateTo.setDate(dateTo.getDate() - 1);
        }
        if (!dateFrom) {
            dateFrom = new Date();
            dateFrom.setDate(dateFrom.getDate() - 2);
        }

        return '/search-terms/ranking-keywords?' +
            'date_from=' + formatDate(dateFrom, 'digits-dash') +
            '&date_to=' + formatDate(dateTo, 'digits-dash') +
            '&sort_by=popularity' +
            '&order=desc' +
            '&title=' + appData.title +
            '&app_id=' + appData.id +
            '&logo=' + appData.logo +
            '&country_code=' + countryCode;
    }
};