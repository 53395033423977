import {httpRequest} from "@/api";
import {CustomCompetitor} from "@/api/objects/CustomCompetitor";
import {Logo} from "@/api/objects/Logo";
import {Screenshot} from "@/api/objects/Screenshot";
import {Developer} from "@/api/objects/Developer";

export default {
    CUSTOM_COMPETITORS: 'api/profiles/application/custom-competitors',
    GET_LATEST_SCREENSHOTS: 'api/application/find-competitors/getLatestScreenshots',
    GET_KEYWORD_TOP_COMPETITORS_INFO: 'api/keywords-top-competitors',
    GET_LATEST_LOGOS: 'api/application/find-competitors/getLatestLogos',

    customCompetitorsViewObject: class {

        constructor(filterValueLimits, competitors) {
            this.filterValueLimits = filterValueLimits ? filterValueLimits : {};
            this.competitors = competitors ? competitors : [];
            this.initialData = this.competitors;
            this.total = this.initialData.length;
        }

        /**
         * @return {CustomCompetitor[]}
         */
        getCompetitors() {
            return this.competitors;
        }

        uncheckAll() {
            this.competitors.forEach((item) => item.checked = false);
        }

        getFilterValueLimits() {
            return this.filterValueLimits;
        }

        getCompetitorsCheckedOnly() {
            return this.competitors.filter((item) => item.checked);
        }

        getCompetitorsCheckedOnlyInitial() {
            return this.initialData.filter((item) => item.checked);
        }

        getCompetitorsFiltered(filtersObj) {
            this.latestFilterQ = filtersObj;

            let filtersAllowed = {
                'filter[range_votes_count][from]': (item, val) => item.votesCount >= val,
                'filter[range_votes_count][to]': (item, val) => item.votesCount <= val,
                'filter[range_rating][from]': (item, val) => item.rating >= val,
                'filter[range_rating][to]': (item, val) => item.rating <= val,
                'filter[like][query]': (item, val) => (item.title.toLowerCase().includes(val.toLowerCase()) || item.subTitle?.toLowerCase()?.includes(val.toLowerCase())),
            };

            let result = [];
            this.initialData.forEach((item) => {
                let isAllowed = true;

                let filteredByCategory = false;
                let isCategoryAllowed = false;
                for (let filter in filtersObj) {
                    if (filtersAllowed.hasOwnProperty(filter)) {
                        if (filtersObj[filter] && !filtersAllowed[filter](item, filtersObj[filter])) {
                            isAllowed = false;
                            break;
                        }
                    } else if (filter.includes('filter[categories_names][list]')) {
                        filteredByCategory = true;
                        if (item.category.toLowerCase() === filtersObj[filter]) {
                            isCategoryAllowed = true;
                        }
                    } else {
                        console.error('Unknown filter: ' + filter);
                    }
                }
                if (isAllowed && (!filteredByCategory || isCategoryAllowed)) {
                    result.push(item);
                }
            });

            return result;
        }

        isCompetitorsEmpty() {
            return this.competitors.length === 0;
        }

        getTotalCount() {
            return this.initialData ? this.initialData.length : this.competitors.length;
        }

        removed = [];

        remove(competitor) {
            this.removed.push(competitor);
            this.competitors = this.competitors.filter((item) => item !== competitor);

            this.total--;
        }

        restore(competitor) {
            this.removed = this.removed.filter((item) => item !== competitor);
            this.competitors.push(competitor);
            this.sort(this.latestSort);

            this.total++;
        }

        getById(id) {
            let res = this.competitors.find((item) => item.competitorId === id);
            if (!res) {
                res = this.removed.find((item) => item.competitorId === id);
            }

            return res;
        }

        latestSort = {
            sort: 'rating',
            order: 'desc'
        };

        sort(sort) {
            this.latestSort = sort;
            this.competitors = this.competitors.sort((a, b) => {
                if (a[sort.sort] === b[sort.sort]) {
                    return 0;
                }
                if (sort.order === 'asc') {
                    return a[sort.sort] > b[sort.sort] ? 1 : -1;
                } else {
                    return a[sort.sort] < b[sort.sort] ? 1 : -1;
                }
            });
        }

        getLatestFilterQ() {
            return this.latestFilterQ ? this.latestFilterQ : {};
        }

        /**
         *
         * @param obj{customCompetitorsViewObject}
         */
        mergeMetas(obj) {
            obj.getCompetitors().map((item) => {
                let competitor = this.competitors.find((comp) => comp.competitorId === item.competitorId);
                if (competitor) {
                    competitor.title = item.title;
                    competitor.logo = item.logo;
                    competitor.subTitle = item.subTitle;
                }
            });
        }
    },

    /**
     *
     * @param app_id
     * @param country_id
     * @param isCompetitors
     * @param locale_code
     * @returns {Promise<customCompetitorsViewObject>}
     */
    fetchCompetitors: function (app_id, country_id, isCompetitors = 1, locale_code = null) {
        let competitorsUrl = process.env.VUE_APP_API_URL
            + this.CUSTOM_COMPETITORS
            + '?app_id=' + app_id
        ;

        if (country_id) {
            competitorsUrl += '&country_id_rating=' + country_id;
        }

        competitorsUrl += '&is_competitor=' + isCompetitors

        if (locale_code) {
            competitorsUrl += '&locale_code=' + locale_code;
        }

        const state = isCompetitors === 1 ? 'competitor' : (isCompetitors === 2 ? 'indirect' : 'nonCompetitor');

        return new Promise((resolve, reject) => {
            httpRequest('GET', competitorsUrl).then(response => {
                let competitors = [];
                response.list.forEach(competitor => {
                    competitors.push(new CustomCompetitor(
                        app_id,
                        country_id,
                        competitor.id,
                        competitor.origin_id,

                        competitor.logo,
                        competitor.title,
                        competitor.category,
                        competitor.rating,
                        competitor.store,
                        competitor.store_link,
                        state,
                        new Developer(
                            competitor.developer.name
                        ),
                        competitor.votes_count,
                        competitor.sub_title,
                        competitor.installs,
                        competitor.revenue
                    ));
                });

                resolve(new this.customCompetitorsViewObject(response.filter_value_limits, competitors));
            }).catch(error => {
                console.error(error);
                resolve(new this.customCompetitorsViewObject());
            });
        });
    },

    /**
     * @param competitor {CustomCompetitor}
     * @param toState
     * @returns {Promise}
     */
    changeState(competitor, toState) {
        if (competitor.competitorState === toState) {
            return;
        }
        if (competitor.competitorState === 'new') {
            competitor.competitorState = toState;
            return this.deleteCompetitor(competitor.appId, competitor.competitorId, competitor.countryId);
        }

        let formData = new FormData();
        switch (toState) {
            case 'competitor':
                formData.append('is_competitor', 1);
                break;
            case 'non-competitor':
                formData.append('is_competitor', 0);
                break;
            case 'nonCompetitor':
                formData.append('is_competitor', 0);
                break;
            case 'indirect':
                formData.append('is_competitor', 2);
                break;
            case 'new':
                competitor.competitorState = toState;
                return;
            default:
                console.error('Unknown state: ' + toState);
                return;
        }

        competitor.competitorState = toState;

        formData.append('app_id', competitor.appId);
        formData.append('comp_app_id', competitor.competitorId);
        formData.append('country_id', competitor.countryId);

        return new Promise((resolve, reject) => {
            httpRequest('POST', process.env.VUE_APP_API_URL + this.CUSTOM_COMPETITORS, formData).then(response => {
                resolve(response);
            }).catch(error => {
                console.error(error);
                reject(error);
            });
        });
    },

    deleteCompetitor: function (app_id, competitor_id, country_id) {
        const competitorsUrl = process.env.VUE_APP_API_URL
            + this.CUSTOM_COMPETITORS
            + '?app_id=' + app_id
            + '&comp_app_id=' + competitor_id
            + '&country_id=' + country_id;

        return httpRequest('DELETE', competitorsUrl);
    },

    fetchLogo: function (appId, localeCode) {
        const url = `?locale_code=${localeCode}&app_id=${appId}`;

        return new Promise((resolve, reject) => {
            httpRequest('GET', process.env.VUE_APP_API_URL + this.GET_LATEST_LOGOS + url).then(response => {
                if (!response?.list[0]?.url) {
                    reject(null);
                }

                resolve(new Logo(response.list[0].url));
            }).catch(error => {
                console.error(error);
                reject(error);
            });
        });
    },

    /**
     * @param appId
     * @param localeCode
     * @param device
     * @returns {Promise<Screenshot[]>}
     */
    fetchScreenshots: function (appId, localeCode, device = null) {
        let url = `?locale_code=${localeCode}&app_id=${appId}`;
        if (device) {
            url += `&device=${device}`;
        }

        return new Promise((resolve, reject) => {
            httpRequest('GET', process.env.VUE_APP_API_URL + this.GET_LATEST_SCREENSHOTS + url).then(response => {
                let result = [];
                response.list.forEach(screenshot => {
                    result.push(new Screenshot(screenshot.url));
                });

                resolve(result);
            }).catch(error => {
                console.error(error);
                reject(error);
            });
        });
    },

    getKeywordTopCompetitorsInfo(appId, countryCode, keywords) {
        let url = `?country_code=${countryCode}&app_id=${appId}`;
        keywords.forEach((keyword, idx) => {
            url += `&keywords[${idx}]=${encodeURIComponent(keyword)}`;
        });

        return new Promise((resolve, reject) => {
            httpRequest('GET', process.env.VUE_APP_API_URL + this.GET_KEYWORD_TOP_COMPETITORS_INFO + url).then(response => {
                resolve(response);
            }).catch(error => {
                console.error(error);
                reject(error);
            });
        });
    }
};