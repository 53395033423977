<template>
  <div class="radio-item"
       @click="onClick"
       :class="[{active: active, disabled: disabled}, mode]">
    <div class="radio-marker"></div>
    <div class="radio-text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioButton",
  props: {
    active: {
      type: Boolean
    },
    mode: {
      type: String, // empty or 'inside'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: {},
    }
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit('change');
      }
    }
  }
}
</script>

<style scoped lang="scss" src="./styles.scss"></style>