export default {
    SET_SUGGESTED_KEYWORDS({commit}, payload) {
        if (!payload) {
            return;
        }
        commit('SET_SUGGESTED_KEYWORDS', payload.map(keyword => {
                return {
                    selected: keyword?.selected ?? false,
                    sap: keyword?.SAP ?? null,
                    name: keyword?.keyword ?? "",
                }
            })
        )
    },
    SET_SORTING_META({commit, state, rootState}, payload) {
        const metaObject = {
            sort_by: payload?.sort_by ?? state.sortingMeta.sort_by,
            sort_direction: payload?.sort_direction ?? state.sortingMeta.sort_direction,
            page: payload?.page ?? rootState.pageMeta.current_page,
            per_page: payload?.per_page ?? rootState.pageMeta.per_page,
            query: payload?.query ?? state.sortingMeta.query,
            popularity_min: payload?.popularity_min ?? state.sortingMeta.popularity_min,
            popularity_max: payload?.popularity_max ?? state.sortingMeta.popularity_max,
            difficulty_min: payload?.difficulty_min ?? state.sortingMeta.difficulty_min,
            difficulty_max: payload?.difficulty_max ?? state.sortingMeta.difficulty_max,
            impression_min: payload?.impression_min ?? state.sortingMeta.impression_min,
            impression_max: payload?.impression_max ?? state.sortingMeta.impression_max,
            rank_min: payload?.rank_min ?? state.sortingMeta.rank_min,
            rank_max: payload?.rank_max ?? state.sortingMeta.rank_max,
        };

        commit('SET_SORTING_META', metaObject);
    },
    CONSTRUCT_URL_QUERY_STRING({commit, state}) {
        let queryString = '';
        if (Object.keys(state.sortingMeta).length > 0) {
            for (const key in state.sortingMeta) {
                if (state.sortingMeta[key]) {
                    queryString += '&' + key + '=' + state.sortingMeta[key];
                }
            }
        }
        commit('SET_URL_QUERY_STRING', queryString);
    },
    SET_POPULARITY_CHART_RESULTS({commit, state, rootGetters}, payload) {
        commit('SET_POPULARITY_CHART_RESULTS', payload);
    },
    SET_CURRENT_KEYWORD({commit, state, rootGetters}, payload) {
        commit('SET_CURRENT_KEYWORD', payload);
    },
};
