export class Screenshot {
    constructor(
        url
    ) {
        this.url = url;
    }

    getUrl() {
        return 'https://' + this.url; //TODO
    }
}