<template>
  <div class="form-control"
       :class="[{active: isActive}, {'bg-transparent': bgIsTransparent}]">
    <label><textarea :name="name"
                     v-model="inputModel"
                     @input="inputChanged"
                     :class="[{error: errorMessage?.length > 0}]"
                     :disabled="disabled"
                     :placeholder="placeholder ?? ''"
                     :required="required"></textarea>
    </label>

    <error-message v-if="errorMessage?.length > 0">{{ errorMessageComp }}</error-message>
  </div>
</template>

<script>
import ErrorMessageBlock from "@/components/Forms/ErrorMessageBlock/index";

export default {
  name: "TextAreaBlock",
  components: {
    'error-message': ErrorMessageBlock,
  },
  props: {
    name: {
      type: String,
    },
    inputValue: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false
    },
    errorMessage: {},
    disabled: {
      type: Boolean,
      default: false
    },
    bgIsTransparent: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      inputModel: '',
      isActive: false
    }
  },
  mounted() {
    if (this.inputValue) {
      this.inputModel = this.inputValue;
    }
  },
  methods: {
    inputChanged(e) {
      this.$emit('inputChanged', e.target.value);
    }
  },
  computed: {
    errorMessageComp() {
      if (typeof this.errorMessage === 'string') {
        return this.errorMessage;
      } else {
        let msgString = '';
        this.errorMessage.forEach(item => {
          msgString += item;
        });
        return msgString;
      }
    }
  },
  watch: {
    inputValue(val) {
      this.inputModel = val;
    }
  }
}
</script>