<template>
  <div class="calendar-block"
       v-click-outside="outside"
       :class="{active: showDatepicker}">
    <div class="calendar-input"
         :class="{staticMode: !isActivePicker}"
         @click="toggleShow">
      <div class="input-value">
        {{ formatDate(dateFilter.start) }} - {{ formatDate(dateFilter.end) }}
      </div>
      <div class="input-caret" v-if="isActivePicker">
        <svg-icon icon="angle-down-solid"/>
      </div>
    </div>

    <div class="calendar-block-wrap" :class="{'right':toRight}">
      <div class="tablet-top-part">
        <div class="date-picker-range">
          <div class="range-title">Select date:</div>
          <div class="range-inputs-block">
            <date-input :date="tempDate.end"
                        classes="blue"
                        @open-date-picker="topDateClickerOpened"
                        @invalid-date-set="resetDatePicker('no-reload')"
                        @input-date-changed="inputDateChanged($event, 'end')"/>
          </div>
          <div class="range-items-list">
            <div class="range-title">Compare with:</div>
            <radio-button @click="changePresetDate(1)"
                          :active="currentDataRange === 1 && !showBottomDateInput">1 Day Ago
            </radio-button>
            <radio-button @click="changePresetDate(7)"
                          :active="currentDataRange === 7 && !showBottomDateInput">7 Day Ago
            </radio-button>
            <radio-button @click="changePresetDate(30)"
                          :active="currentDataRange === 30 && !showBottomDateInput">30 Day Ago
            </radio-button>
            <radio-button @click="customRangeClicked"
                          :active="showBottomDateInput === true">Custom
            </radio-button>
          </div>

          <div class="range-inputs-block mar-none"
               v-if="showBottomDateInput">
            <date-input :date="tempDate.start"
                        classes="yellow"
                        @open-date-picker="bottomDateClickerOpened"
                        @invalid-date-set="resetDatePicker('no-reload')"
                        @input-date-changed="inputDateChanged($event, 'start')"/>
          </div>
        </div>

        <div class="calendar-container"
             v-if="currentCalendar === 'to'">
          <v-date-picker v-model="tempDate.end"
                         :min-date="currentCalendar === 'from' ? tempDate.start : minDate"
                         :max-date="maxDate"
                         :locale="calendarLocale"
                         :is-required="true"
                         :mode="'single'"
                         @dayclick="dateClicked($event, 'end')"
                         :key="datePickerKey"
                         :columns="1"/>
        </div>
        <div class="calendar-container yellow-marker"
             v-if="currentCalendar === 'from'">
          <v-date-picker v-model="tempDate.start"
                         :min-date="minDate"
                         :max-date="maxDateBottomCalendar"
                         :locale="calendarLocale"
                         :is-required="true"
                         :mode="'single'"
                         @dayclick="dateClicked($event, 'start')"
                         :key="datePickerKey"
                         :columns="1"/>
        </div>

        <div class="bottom-part">
          <base-button mode="outline"
                       height="32px"
                       font-size="15px"
                       border-radius="4px"
                       :disabled="buttonsDisabled"
                       @click="resetDatePicker">Reset
          </base-button>
          <base-button height="32px"
                       font-size="15px"
                       border-radius="4px"
                       :disabled="buttonsDisabled"
                       @click="applyDatePicker">Apply
          </base-button>
        </div>
      </div>
      <div class="bottom-part mobile-visible">
        <base-button mode="outline"
                     height="32px"
                     font-size="15px"
                     border-radius="4px"
                     :disabled="buttonsDisabled"
                     @click="resetDatePicker">Reset
        </base-button>
        <base-button height="32px"
                     font-size="15px"
                     border-radius="4px"
                     :disabled="buttonsDisabled"
                     @click="applyDatePicker">Apply
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import DateInput from "@/components/UI/DatepickerWithRange/components/DateInput/index.vue";
import {Calendar, DatePicker} from 'v-calendar';
import 'v-calendar/dist/style.css';
import {formatDate} from "@/utils/utils";
import RadioButton from "@/components/UI/RadioButton/index.vue";

export default {
  name: "DatepickerWithRange",
  props: {
    toRight: {
      type: Boolean,
      default: false
    },
    isActivePicker: {
      type: Boolean,
      default: true
    },
    opened: {
      type: Boolean
    },
    minDate: {
      type: Date,
    },
    maxDate: {
      type: Date,
    },
    dateFilter: {
      type: Object,
    },
    lastDaysCount: {
      type: Number,
      default: 1
    }
  },
  components: {
    'v-calendar': Calendar,
    'v-date-picker': DatePicker,
    DateInput,
    RadioButton,
  },
  emits: ['closeModal', 'date-filter-changed', 'date-filter-closed', 'reset-button-clicked'],
  data() {
    return {
      showDatepicker: false,
      tempDate: {
        start: new Date(),
        end: new Date(),
      },
      currentDataRange: null,
      calendarLocale: {
        masks: {weekdays: 'WW'},
      },
      showBottomDateInput: false,
      buttonsDisabled: true,
      currentCalendar: 'to',
      datePickerKey: 0,
    }
  },
  methods: {
    toggleShow() {
      if (this.isActivePicker) {
        this.showDatepicker = !this.showDatepicker;
      }
    },
    applyDatePicker() {
      this.showDatepicker = false;
      let startDate = typeof this.tempDate?.start === 'number' ? new Date(this.tempDate.start) : this.tempDate.start;

      let tempDate = {
        start: startDate,
        end: typeof this.tempDate?.end === 'number' ? new Date(this.tempDate.end) : this.tempDate.end
      }

      if (startDate.getTime() < this.minDate.getTime() && startDate.getTime() < this.maxDate.getTime()) {
        tempDate.start = new Date(this.minDate);
      }

      this.$emit('date-filter-changed', tempDate);
      this.datePickerKey++;
    },
    resetDatePicker(e) {
      this.$emit('reset-button-clicked', e);
      this.customRangeClicked();

      if (e !== 'no-reload') {
        this.showDatepicker = false;
      }

      this.showBottomDateInput = false;
      this.currentDataRange = 1;
      this.currentCalendar = 'to';
    },
    formatDate(val) {
      return formatDate(val, 'month-day-year');
    },
    outside() {
      this.showDatepicker = false;
      this.tempDate = {...this.dateFilter};
    },
    changePresetDate(days) {
      this.buttonsDisabled = false;
      this.showBottomDateInput = false;
      this.currentCalendar = 'to';
      this.getLastDaysRange(days);
    },
    getLastDaysRange(days, mode = 'default') {
      let nowStart = new Date();
      let nowEnd = new Date();
      let tempDate = {
        start: nowStart.setDate(nowStart.getDate() - days),
        end: nowEnd?.setDate(nowEnd?.getDate()),
      }

      if (mode === 'default') {
        nowStart = new Date(this.tempDate?.end);
        nowEnd = new Date(this.tempDate?.end);

        tempDate = {
          start: nowStart?.setDate(nowEnd?.getDate() - days),
          end: nowEnd,
        }
      }

      tempDate = {
        start: typeof tempDate?.start === 'number' ? new Date(tempDate.start) : tempDate.start,
        end: typeof tempDate?.end === 'number' ? new Date(tempDate.end) : tempDate.end
      };

      let startDate = typeof tempDate?.start === 'number' ? new Date(tempDate.start) : tempDate.start;
      if (startDate.getTime() < this.minDate.getTime() && startDate.getTime() < this.maxDate.getTime()) {
        tempDate.start = new Date(this.minDate);
      }

      this.showBottomDateInput = false;
      this.tempDate = {...tempDate};
      this.currentDataRange = days;
    },
    setStartDate(days, dateEnd) {
      if (!dateEnd) {
        return;
      }

      let date = new Date(dateEnd);

      if (typeof date === 'number' || typeof date === 'string') {
        date = new Date(date);
      }

      return new Date(date.setDate(date.getDate() - days));
    },
    customRangeClicked() {
      this.showBottomDateInput = true;
      // this.currentCalendar = 'from';
    },
    dateClicked(e, type) {
      let date = {...this.tempDate};
      let clickedDateValue = e.date;

      if (type === 'end' && !this.showBottomDateInput) {
        date.start = this.setStartDate(this.currentDataRange, clickedDateValue);
      }

      if (type === 'end' && this.showBottomDateInput) {
        let startDate = new Date(date.start);

        if (e?.date.getTime() < startDate.getTime()) {
          date.start = new Date(startDate.setDate(e?.date.getDate() - 1));
        }
      }


      if (e?.isDisabled === false) {
        this.buttonsDisabled = false;
        this.tempDate = {...date};
      }

    },
    endTypeProcesses(date, e) {
      let tempDate = {...date};
      this.currentCalendar = 'to';

      if (new Date(e).getTime() <= this.maxDate.getTime() && new Date(e).getTime() > this.minDate.getTime()) {
        tempDate.end = e;
        tempDate.start = new Date(this.setStartDate(1, e));
      } else if (new Date(e).getTime() > this.maxDate.getTime() && new Date(e).getTime() > this.minDate.getTime()) {
        tempDate.end = new Date(this.maxDate);
        tempDate.start = new Date(this.tempDate?.start);
      } else if (new Date(e).getTime() >= this.minDate.getTime() && new Date(e).getTime() < this.maxDate.getTime()) {
        tempDate.end = e;
        tempDate.start = new Date(this.tempDate?.start);
      } else if (new Date(e).getTime() < this.minDate.getTime() && new Date(e).getTime() < this.maxDate.getTime()) {
        tempDate.end = new Date(this.minDate);
        tempDate.start = new Date(this.minDate);
      }

      return tempDate;
    },
    startTypeProcesses(date, e) {
      let tempDate = {...date};
      let eventGetTime = new Date(e).getTime();
      if (eventGetTime < new Date(this.tempDate?.start).getTime() && eventGetTime > this.minDate.getTime()) {
        tempDate.start = new Date(e);
      } else if (eventGetTime > this.maxDate.getTime() && eventGetTime > this.minDate.getTime()) {
        tempDate.start = new Date(this.tempDate?.start);
        // tempDate.start = this.maxDate;

      } else if (eventGetTime > this.minDate.getTime() && eventGetTime < new Date(tempDate.end.getTime())) {
        if (formatDate(eventGetTime, 'digits') !== formatDate(tempDate.end.getTime(), 'digits')) {
          tempDate.start = new Date(e);
        } else {
          tempDate.start = new Date(this.tempDate?.start);
        }
      } else if (eventGetTime < this.minDate.getTime() && eventGetTime < this.maxDate.getTime()) {
        tempDate.start = new Date(this.minDate);
      } else {
        if (formatDate(eventGetTime, 'digits') !== formatDate(tempDate.end.getTime(), 'digits')) {
          tempDate.start = new Date(e);
        } else {
          tempDate.start = new Date(this.tempDate?.start);
        }
      }

      return tempDate;
    },
    inputDateChanged(e, type) {
      let date = {...this.tempDate};

      if (type === 'end') {
        date = this.endTypeProcesses(this.tempDate, e);
        this.currentCalendar = 'to';
      }

      if (type === 'start') {
        date = this.startTypeProcesses(this.tempDate, e);
        this.currentCalendar = 'from';
      }

      this.tempDate = {...date};
      this.buttonsDisabled = false;
      this.customRangeClicked();
    },
    topDateClickerOpened() {
      this.currentCalendar = 'to';
    },
    bottomDateClickerOpened() {
      this.currentCalendar = 'from';
    },
  },
  mounted() {
    this.getLastDaysRange(this.lastDaysCount, 'initital');
    this.tempDate = {...this.dateFilter};

    const initialDateFilterStart = formatDate(this.dateFilter.start, 'digits');
    const initialDateFilterEnd = formatDate(this.dateFilter.end, 'digits');
    const nowFormatted = formatDate(new Date(), 'digits');

    if (initialDateFilterEnd === nowFormatted) {
      let now = new Date();
      const past7DaysFormatted = formatDate(now.setDate(new Date().getDate() - 7 - 1), 'digits');
      now = new Date();
      const past30DaysFormatted = formatDate(now.setDate(new Date().getDate() - 30 - 1), 'digits');

      this.currentDataRange = 1;

      if (initialDateFilterStart === past7DaysFormatted) {
        this.currentDataRange = 7;
      }

      if (initialDateFilterStart === past30DaysFormatted) {
        this.currentDataRange = 30;
      }
    }
  },
  computed: {
    maxDateBottomCalendar() {
      if (typeof this.tempDate.end === 'number') {
        return new Date(this.tempDate.end - 1);
      } else {
        let endDate = new Date(this.tempDate.end);
        return endDate.setDate(new Date(endDate)?.getDate() - 1);
      }
    },
  },
  watch: {
    dateFilter(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.tempDate = {...newVal};
      }
    },
  }
}
</script>

<style scoped lang="scss" src="./styles.scss"></style>
<style scoped>
.calendar-block-wrap.right {
  right: 0;
}
</style>