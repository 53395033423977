import axios from "axios";
import {getCookie} from "@/utils/utils";
import store from '../store';
import {isDataOnUrlSaved, saveLocalStorageData} from '@/utils/caching';

export const httpRequest = async (method, url, data, outterOpts) => {
  const requestData = data ?? null;
  const outOps = outterOpts ?? {};
  const headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");
  const options = {
    method,
    url,
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    ...outOps,
  };

  if (getCookie('token')) {
    options.headers['Authorization'] = 'Bearer ' + getCookie('token');
  }

  if (data) {
    options.data = requestData;
  }

  let isDataSaved = isDataOnUrlSaved(url);

  if (isDataSaved) {
    return isDataSaved?.data;
  } else {
    return await axios({...options})
        .then(function (response) {
          saveLocalStorageData(url, response.data);
          return response.data;
        })
        .catch(function (error) {
          if (error?.response?.data?.code === -403001) {
            store.dispatch('SET_USER_LIMITS_ERRORS');

            return {
              message: 'limits exceeded',
            }
          }

          if (error.message === 'Network Error') {
            return {
              errors: 'Network Error',
              message: 'Unauthenticated',
            }
          }
          return {
            errors: error?.response?.data?.errors,
            message: error?.response?.data?.message,
            status: error?.response?.status,
          }
        });
  }
}

export const fetchPromiseAllSettled = async (urlArray) => {
  //If browser doesn't support allSettled
  if (!Promise.allSettled) {
    Promise.allSettled = function (promises) {
      return Promise.all(promises.map(p => Promise.resolve(p).then(value => ({
        status: 'fulfilled',
        value: value
      }), error => ({
        status: 'rejected',
        reason: error
      }))));
    };
  }

  const results = await Promise.allSettled(urlArray.map(item => {
    return httpRequest(item.method ? item.method : 'POST', item.url, item.data, item.opts);
  }));

  //Get fulfilled responses
  const fulfilled = results
      .filter(item => item.status === 'fulfilled')
      .map(item => {
        return item.value;
      });

  //Get rejected errors to display
  const errors = results
      .filter(p => p.status === 'rejected')
      .map(p => p.reason);

  if (errors.length === 0) {
    return {success: true, data: fulfilled};
  } else {
    return {success: false, data: null};
  }
}
