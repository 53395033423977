<template>
  <div class="advanced-custom-data-table latest-table"
       :class="tableClasses">
    <div id="table"
         class="advanced-custom-table">
      <div class="thead" v-sticky>

        <div class="tr">
          <div class="th" v-for="(headerItem, index) in headers"
               :key="index"
               :class="[headerItem.align ? [headerItem.align] : 'left', sortingMeta[sortingKeys.sorting] === headerItem.value ? 'active' : '', headerItem.isSticky ? 'is-sticky' : '', headerItem.stickyClass ? headerItem.stickyClass : '', headerItem.draggable ? '' : 'not-draggable', headerItem.headingClass ?? '', headerItem.tooltipText ? 'with-tooltip' : '']"
               :style="[{ width: headerItem.width ? headerItem.width : '', minWidth: headerItem.textWidth ? headerItem.textWidth : ''}]"
               :hidden="!headerItem.isActive"
               :aria-hidden="!headerItem.isActive">

            <div class="inner-content"
                 :style="{width: `calc: ${headerItem.minWidth} - 54px`}">

              <template v-if="headerItem.hasCheckbox">
                <data-table-checkbox :checked="deleteAllCheckboxValue"
                                     :classes="['minus-sign']"
                                     @input-checked="checkAllClicked"/>
              </template>

              <div class="inner-content-wrap">
                <template v-if="headerItem.sortable && headerItem?.sortArrowsAlign !== 'right'">
                  <div class="sort-icons-block">
                    <svg-icon icon="arrow-solid-up"
                              class="icon icon-first"
                              v-on="headerItem.sortable ? {click: () => sortClickHandler(headerItem.sortBy, 'asc')}: {}"
                              :class="{active: ascActiveClass(headerItem)}"/>
                    <svg-icon icon="arrow-solid-down"
                              class="icon"
                              v-on="headerItem.sortable ? {click: () => sortClickHandler(headerItem.sortBy, 'desc')}: {}"
                              :class="{active: descActiveClass(headerItem)}"/>
                  </div>
                </template>

                <span v-html="headerItem.text"></span>

                <template v-if="headerItem.hasExtendedHeader">
                  <slot name="extended-header-text"></slot>
                </template>

                <template v-if="headerItem.hasExtendedRankFromHeader">
                  <slot name="extended-ranking-from"></slot>
                </template>

                <template v-if="headerItem.hasExtendedRankToHeader">
                  <slot name="extended-ranking-to"></slot>
                </template>

                <template v-if="headerItem.sortable && headerItem?.sortArrowsAlign === 'right'">
                  <div class="sort-icons-block right-align"
                       @click="sortClickHandler(headerItem.sortBy, headerItem.sortable)">
                    <svg-icon icon="arrow-solid-up"
                              class="icon icon-first"
                              v-on="headerItem.sortable ? {click: () => sortClickHandler(headerItem.sortBy, 'asc')}: {}"
                              :class="{active: ascActiveClass(headerItem)}"
                    />
                    <svg-icon icon="arrow-solid-down"
                              class="icon"
                              v-on="headerItem.sortable ? {click: () => sortClickHandler(headerItem.sortBy, 'desc')}: {}"
                              :class="{active: descActiveClass(headerItem)}"/>
                  </div>
                </template>

                <template v-if="headerItem.tooltipText">
                  <custom-tooltip :classes="'question-tooltip'"
                                  :width="headerItem?.tooltipWidth"
                                  direction-vertical="bottom"
                                  :direction-horizontal="headerItem?.tooltipDirection">
                    <template v-slot:slotTrigger>
                      <svg-icon icon="question-circle-icon"
                                class="question-tooltip"/>
                    </template>
                    <template v-slot:slotContent>
                      <span v-html="headerItem.tooltipText"></span>
                    </template>
                  </custom-tooltip>

                </template>

              </div>
            </div>

          </div>
        </div>
        <div class="tr table-actions"
             v-if="itemsChecked && itemsChecked >= 1">
          <div class="th">
            <div class="inner-content">
              <template v-if="itemsChecked === items?.length">
                <data-table-checkbox :checked="true"
                                     @input-checked="checkAllClickedTwo"/>
              </template>
              <template v-else>
                <data-table-checkbox :checked="true"
                                     :classes="['minus-sign']"
                                     @input-checked="uncheckAllClicked"/>
              </template>

              <slot name="multi-actions"></slot>
            </div>
          </div>
        </div>

        <slot name="select-all"></slot>
      </div>
      <div class="tbody">
        <template :key="index" v-for="(item, index) in items">
          <div class="tr data-rows"
               :class="{first: index === 0}">
            <slot name="items" :item="item">
              items list
            </slot>
          </div>
          <slot name="under_row" :item="item"></slot>
        </template>
      </div>
      <slot name="upgrade-block"></slot>
    </div>
  </div>
  <div class="custom-pagination">
    <pagination 
      v-if="!noPagination && pagination.last_page > 1" 
      :total-pages="pagination.total" 
      :per-page="pagination.per_page"
      :current-page="pagination.current_page"  
      @page-change="onPageChange"
    />

    <div class="right-part">
      <template v-if="showPerPageSelector">
        <div class="pages-amount">
          Showing {{ `${pagination.from} - ${pagination.to} of ${pagination.total}` }}
        </div>

        <div class="rows-per-page-block">
          <div class="custom-select-wrap">

            <custom-data-table-select :options="rowsPerPageItems"
                                      direction="up"
                                      :initial-data="'' + pagination.per_page"
                                      @selectClicked="selectClicked"/>
          </div>
        </div>
      </template>

      <slot name="save-btn"></slot>
    </div>
  </div>
</template>

<script>
import TableCheckbox from "@/components/UI/TableCheckbox/index";
import CustomDataTableSelect from "@/components/Forms/CustomDataTableSelect/index";
import Pagination from "@/components/Pagination"

export default {
  name: "CustomDataTable",
  components: {
    'data-table-checkbox': TableCheckbox,
    'custom-data-table-select': CustomDataTableSelect,
    'pagination': Pagination
  },
  emits: ['check-all-clicked', 'update-sort', 'upgrade-now-show'],
  props: {
    headers: {
      type: Array
    },
    items: {
      type: Array
    },
    itemsPerPage: {
      type: Number
    },
    rowsPerPageItems: {
      type: Array
    },
    pagination: {
      type: Object
    },
    sortingMeta: {
      type: Object
    },
    sortable: {
      type: Boolean,
      default: true
    },
    checkedTableItemsCount: {
      type: Number,
      required: false,
    },
    sortingKeys: {
      type: Object,
      default: () => {
        return {
          direction: 'order',
          sorting: 'sort'
        }
      }
    },
    tableClasses: {
      type: String
    },
    showPerPageSelector: {
      type: Boolean,
      default: true,
    },
    checkAllChanged: {
      type: Boolean,
    },
    itemsChecked: {
      type: Number,
      required: false,
    },
    itemsAmount: {
      type: Number,
      required: false,
    },
    scrollAfterClick: {
      type: Boolean,
      default: true,
    },
    noPagination: {
      type: Boolean,
      default: false,
    },
    showBluredBlock: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteAllCheckboxValue: false,
      draggingEle: 0,
      draggingColumnIndex: 0,
      placeholder: 1,
      isDraggingStarted: false,
      x: 0,
      y: 0,
      table: null,
      tableScrollLeft: 0,
      currentDragIconPosition: '',
    }
  },
  mounted() {

    this.table = document.getElementById('table');
    const customDataTable = document.querySelector('.advanced-custom-data-table');
    if (customDataTable) {
      customDataTable.addEventListener('scroll', () => {
        this.tableScrollLeft = customDataTable.scrollLeft;
      });
    }
  },
  methods: {
    onPageChange(pageNumber) {
      if (this.showBluredBlock) {
        this.$emit('upgrade-now-show');
        return;
      }

      this.$emit('update-sort', { page: pageNumber })

      window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
    },
    selectClicked(e) {
      this.$emit('update-sort', {
        per_page: e.value,
      });
    },
    sortClickHandler(order_by, sort_by) {
      let sortBy = sort_by;

      //inversion for app-ranking sorting
      if (order_by === 'rank' || order_by === 'last_rank') {
        if (sortBy === 'asc') {
          sortBy = 'desc';
        } else {
          sortBy = 'asc';
        }
      }

      const tempKeysObject = {
        [this.sortingKeys.direction]: sortBy,
        [this.sortingKeys.sorting]: order_by,
      }
      this.$emit('update-sort', tempKeysObject);
    },
    checkAllClicked() {
      this.deleteAllCheckboxValue = !this.deleteAllCheckboxValue;
      this.$emit('check-all-clicked', this.deleteAllCheckboxValue);
    },
    checkAllClickedTwo() {
      this.$emit('check-all-clicked', false);
      this.deleteAllCheckboxValue = false;
    },
    uncheckAllClicked() {
      this.deleteAllCheckboxValue = false;
      this.$emit('check-all-clicked', this.deleteAllCheckboxValue);
    },
    ascActiveClass(headerItem) {
      if (this.sortingMeta[this.sortingKeys.sorting] === headerItem.sortBy && (headerItem.sortBy === 'latest_rank' || headerItem.sortBy === 'rank')) {
        return this.sortingMeta[this.sortingKeys.sorting] === headerItem.sortBy && this.sortingMeta[this.sortingKeys.direction] === 'desc';
      } else {
        return this.sortingMeta[this.sortingKeys.sorting] === headerItem.sortBy && this.sortingMeta[this.sortingKeys.direction] === 'asc';
      }
    },
    descActiveClass(headerItem) {
      if (this.sortingMeta[this.sortingKeys.sorting] === headerItem.sortBy && (headerItem.sortBy === 'latest_rank' || headerItem.sortBy === 'rank')) {
        return this.sortingMeta[this.sortingKeys.sorting] === headerItem.sortBy && (!this.sortingMeta[this.sortingKeys.direction] || this.sortingMeta[this.sortingKeys.direction] === 'asc');
      } else {
        return this.sortingMeta[this.sortingKeys.sorting] === headerItem.sortBy && (!this.sortingMeta[this.sortingKeys.direction] || this.sortingMeta[this.sortingKeys.direction] === 'desc');
      }
    },
  },
  computed: {
    links() {
      return this.pagination.links.filter(link => {
        const label = link.label.includes('Previous') || link.label.includes('Next');
        if (!label) {
          return {
            value: +link.label,
            active: link.active
          }
        }
      });
    },
  },
  watch: {
    checkedTableItemsCount(newVal, oldVal) {
      if (newVal < oldVal) {
        this.deleteAllCheckboxValue = false;
      }
    },
    checkAllChanged(newVal, oldVal) {
      if (newVal === false) {
        this.deleteAllCheckboxValue = false;
      }

      if (newVal === true) {
        this.deleteAllCheckboxValue = true;
      }
    }
  },
  directives: {
    sticky: {
      updated(el, bindings) {
        if (!bindings.instance.isSetupSticky) {
          const header = document.querySelector('header');
          const headerHeight = header.offsetHeight;

          const currentPageKey = 'originalOffsetTop_' + bindings.instance.$route.path;
          let originalOffsetTop = sessionStorage.getItem(currentPageKey);

          if (originalOffsetTop === null) {
            originalOffsetTop = el.getBoundingClientRect().top - headerHeight;
            sessionStorage.setItem(currentPageKey, originalOffsetTop);
          }
 
          const tableElement = document.querySelector('.advanced-custom-data-table')
          const tableOffsetWidth = tableElement.offsetWidth;
          const originalWidth = el.getBoundingClientRect().width
          const wrapper = createWrapper()

          wrapper.scrollLeft = tableElement.scrollLeft

          bindings.instance.handleScroll = () => {
            if (window.scrollY >= +originalOffsetTop) {
              if (el.parentElement !== wrapper) {
                el.parentNode.insertBefore(wrapper, el); 
                wrapper.appendChild(el); 
                el.style.width = `${originalWidth}px`; 
                el.style.top = `${headerHeight}px`;
                el.style.zIndex = 1000000000;
                bindings.instance.syncScroll()
                wrapper.style.height = '100px';
              }
            } else {
              if (el.parentElement === wrapper) {
                wrapper.parentNode.insertBefore(el, wrapper); 
                wrapper.parentNode.removeChild(wrapper); 
              }
          }
        };

        function createWrapper() {
          const wrapper = bindings.instance.stickyWrapper = document.createElement('div');
          wrapper.style.overflow = 'hidden';
          wrapper.style.position = 'fixed';
          wrapper.style.zIndex = 1000000000;
          wrapper.style.width = `${tableOffsetWidth}px`
          wrapper.style.height = el.style.height
          wrapper.style.top = `${headerHeight}px`;

          return wrapper
        }

        bindings.instance.syncScroll = () => {
          wrapper.scrollLeft = tableElement.scrollLeft
        }

        tableElement.addEventListener('scroll', bindings.instance.syncScroll)
        window.addEventListener('scroll', bindings.instance.handleScroll);
        bindings.instance.isSetupSticky = true

        bindings.instance.resizeObserver = new ResizeObserver(entries => {
            for (const entry of entries) {
              const newWidth = entry.contentRect.width;
              bindings.instance.stickyWrapper.style.width = `${newWidth}px`;

                if (originalWidth < newWidth) {
                  el.style.width = `${newWidth}px`;
                }
            }
        });

        bindings.instance.resizeObserver.observe(tableElement);
        }
      },
      beforeUnmount(el, bindings) {
        const tableElement = document.querySelector('.advanced-custom-data-table')
        tableElement.removeEventListener('scroll', bindings.instance.syncScroll)
        window.removeEventListener('scroll', bindings.instance.handleScroll)
        bindings.instance.resizeObserver?.unobserve(tableElement)
        bindings.instance.isSetupSticky = false
      }
    },
  }
}
</script>

<style src="./styles.scss" lang="scss"></style>