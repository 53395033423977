<script>
export default {
  name: "ClipboardCopy",
  methods: {
    copy(text) {
      this.$refs.input.value = text;
      this.$refs.input.select();
      document.execCommand('copy');
      this.$refs.input.value = '';
    }
  }
}
</script>

<template>
<textarea ref="input"></textarea>
</template>

<style scoped lang="scss">
textarea {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translate(-100vw, -100vh);
}
</style>