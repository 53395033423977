export const menu = {
    myApps: {
        route: {name: 'MyApps'},
        icon: 'my-apps-menu-icon',
        title: 'My Apps',
    },
    dashboard: {
        route: {name: 'DashboardOverview'},
        icon: 'dashboard-menu-icon',
        title: 'Dashboard',
    },
    searchTerms: {
        category: 'search-terms',
        categoryTitle: 'Keywords',
        categoryIcon: 'search-terms-menu-icon',
        submenuList: [
            {
                route: {name: 'KeywordsTracking'},
                title: 'Tracking',
            },
            {
                route: {name: 'RecommendedKeywords'},
                title: 'Recommended',
            },
            {
                route: {name: 'MetadataKeywords'},
                title: 'Metadata',
            },
            {
                route: {name: 'RankingKeywords'},
                title: 'Ranking',
            },
            {
                route: {name: 'KeywordExplorer'},
                title: 'Explorer',
            },
            {
                route: {name: 'KeywordsAutosuggest'},
                title: 'Autosuggests',
            },
            {
                route: {name: 'RelatedKeywords'},
                title: 'Related Keywords',
            },
        ]
    },
    collect: {
        category: 'collect',
        categoryTitle: 'Competitors',
        categoryIcon: 'sidebar-collect-icon',
        submenuList: [
            {
                route: {name: 'MyCompetitors'},
                title: 'Selected',
            },
            {
                route: {name: 'CompetitorsFinder'},
                title: 'Find new',
            },
            {
                route: {name: 'Creatives'},
                title: 'Visuals Analysis',
            },
            {
                route: {name: 'Metadata'},
                title: 'Meta Analysis',
            },
            {
                route: {name: 'ReviewsAI'},
                title: 'Reviews AI Summary',
            },
            {
                route: {name: 'AppsTimeline'},
                title: 'Update Timeline',
            },
            {
                route: {name: 'MarketResearch'},
                title: 'Market Research',
            },
        ],
    },
    reports: {
        category: 'reports',
        categoryTitle: 'Reports',
        categoryIcon: 'sidebar-report-icon',
        submenuList: [
            {
                route: {name: 'ImpressionsReport'},
                title: 'Impressions',
            },
            {
                route: {name: 'RankComparativeReport'},
                title: 'Rank Comparative',
            },
            {
                route: {name: 'RankBreakdownReport'},
                title: 'Rank Breakdown',
            },
        ]
    },
    analyze: {
        category: 'analyze',
        categoryTitle: 'Applications',
        categoryIcon: 'sidebar-analyze-icon',
        submenuList: [
            {
                route: {name: 'AnalyzeApp'},
                title: 'Apps',
            },
            {
                route: {name: 'LiveSearch'},
                title: 'Live Search',
            },
            {
                route: {name: 'AppRanking'},
                title: 'Store Charts',
            },
        ]
    },
    optimize: {
        route: {name: 'MetaEditor'},
        icon: 'sidebar-optimize-icon',
        title: 'Meta Editor',
    }
};
