<template>
  <svg-icon icon="star" class="rating-icon"/>
  <span class="rating">{{ Number(rating).toFixed(1) }}</span>
</template>

<script>
import {defineComponent} from 'vue';
import ProgressBar from "@/components/UI/ProgressBar/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";

export default defineComponent({
  name: "RatingTool",
  components: {
    ProgressBar,
    SvgIcon
  },
  props: {
    rating: {
      type: Number,
    },
  },
})
</script>

<style scoped lang="scss">
.rating-icon {
  margin-right: 5px;
}
</style>