<template>
  <label class="checkbox-label"
         :class="[{checked: checked, 'initial-checked': initialChecked}]">
    <span class="custom-table-checkbox"
          :class="classes">
      <input v-model="checked"
             @change="inputChecked"
             type="checkbox">
    </span>
    <span class="text">
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
  name: "TableCheckbox",
  props: {
    checked: {
      type: Boolean
    },
    classes: {
      type: Array,
    },
    initialChecked: {
      type: Boolean
    },
  },
  emits: ['input-checked'],
  setup(props, context) {
    function inputChecked() {
      context.emit('input-checked', props.checked);
    }

    return {inputChecked};
  },
}
</script>

<style scoped lang="scss">
.checkbox-label {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-table-checkbox {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-right: 12px;

  &:before {
    content: '';
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: solid 1px #d7dde7;
    transition: opacity .3s;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/images/Account-Keywords/icon-check.svg');
    background-position: center;
    background-size: 12px 10px;
    background-repeat: no-repeat;
    background-color: var(--primary-default);
    opacity: 0;
    border-radius: 2px;
  }

  input[type=checkbox] {
    opacity: 0;
    position: absolute;
  }
}

.checkbox-label.checked {
  .custom-table-checkbox {
    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }

  .custom-table-checkbox.minus-sign {
    &:before {
      background-color: #fff;
      width: 8px;
      height: 1px;
      position: absolute;
      top: 6px;
      left: 3px;
      opacity: 1;
      z-index: 20;
    }

    &:after {
      background-image: none;
    }
  }
}

.checkbox-label.initial-checked.checked {
  .custom-table-checkbox {
    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
      background-size: 12px 10px;
      background: var(--neutral-400) url('~@/assets/images/Account-Keywords/icon-check-grey.svg') no-repeat center;
    }
  }

}
</style>