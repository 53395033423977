const REQUESTS_URLS_CONST = {
  // '/api/catalog/countries': 'apiCatalogCountries', //TODO нужно переделать логику кеша с учетом параметров.
  '/api/countries': 'apiCountries',
  '/api/catalog/locales': 'apiLocales',
};

function isTimestampExceeded(date) {
  const currentDateInMilliseconds = new Date().getTime();
  const diff = (currentDateInMilliseconds / 60000) - (date / 60000);
  return diff <= 60 * 24;
}

export const isDataOnUrlSaved = (url) => {
  let savedUrlsInLocalStorage = {};

  if (localStorage.getItem('savedUrlsInLocalStorage')) {
    savedUrlsInLocalStorage = JSON.parse(localStorage.getItem('savedUrlsInLocalStorage'));

    let matchedUrlKey = null;
    for (const key in REQUESTS_URLS_CONST) {
      if (url.indexOf(key) !== -1) {
        matchedUrlKey = REQUESTS_URLS_CONST[key];
        break;
      }
    }

    if (savedUrlsInLocalStorage[matchedUrlKey] && JSON.stringify(savedUrlsInLocalStorage[matchedUrlKey]) !== '{}') {
      if (isTimestampExceeded(savedUrlsInLocalStorage[matchedUrlKey]?.timestamp)) {
        return savedUrlsInLocalStorage[matchedUrlKey];
      } else {
        delete savedUrlsInLocalStorage[matchedUrlKey];
        localStorage.setItem('savedUrlsInLocalStorage', JSON.stringify(savedUrlsInLocalStorage));
        return false;
      }
    }
  }
}

export const saveLocalStorageData = (url, responseData) => {
  let matchedUrlKey = null;
  for (const key in REQUESTS_URLS_CONST) {
    if (url.indexOf(key) !== -1) {
      matchedUrlKey = REQUESTS_URLS_CONST[key];
      let savedUrlsInLocalStorage = {};

      if (localStorage.getItem('savedUrlsInLocalStorage')) {
        savedUrlsInLocalStorage = JSON.parse(localStorage.getItem('savedUrlsInLocalStorage'));
      }

      if (!savedUrlsInLocalStorage[matchedUrlKey] || JSON.stringify(savedUrlsInLocalStorage[matchedUrlKey]) === '{}') {
        savedUrlsInLocalStorage[matchedUrlKey] = {
          timestamp: new Date().getTime(),
          data: responseData,
        };
        localStorage.setItem('savedUrlsInLocalStorage', JSON.stringify(savedUrlsInLocalStorage));
      }
      break;
    }
  }
}