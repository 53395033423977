<script>
import {httpRequest} from "@/api";

export default {
    name: 'CurrentAppAsoScore',
    props: {
        appId: {
            type: Number,
            required: true,
        },
        countryCode: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            asoScore: {},
        }
    },
    mounted() {
        this.fetchAsoScore();
    },
    watch: {
        appId: {
            handler: function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.fetchAsoScore();
                }
            },
        },
        countryCode: {
            handler: function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.fetchAsoScore();
                }
            },
        },
    },
    methods: {
        async fetchAsoScore() {
            let url = this.appId + '/' + this.countryCode + '/aso-score';

            let result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.dashboard.ASO_SCORE_DATA + url);

            if (result) {
                result.score = result.score?.toFixed(0);
            } else {
                result = {
                    score: 'n/a'
                };
            }

            this.asoScore = result;
        }
    }
}
</script>

<template>
    <div class="aso-score">
        <div class="css-icons aso-icon"></div>
        {{ asoScore.score }}
    </div>
</template>

<style scoped lang="scss">

</style>