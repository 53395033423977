<template>
  <div class="tabs-block">
    <div class="tabs-head">
      <div class="tab-header-item"
           :class="{active: currentTab === 1}"
           @click="tabClicked(1)">All Countries
      </div>
      <div class="tab-header-item"
           :class="{active: currentTab === 2}"
           @click="tabClicked(2)">Favorite
      </div>
    </div>
    <div class="tabs-content">
      <div class="tab-content-item"
           :class="{active: currentTab === 1}"
      >
        <basic-search-input placeholder="Search..."
                            @search-input-changed="searchInputChanged"
                            @clicked-outside="countriesTabsClosed"
                            :with-result-slots="true"
                            :min-input-length="0"
                            :show-caret="false"
                            :show-search-icon="showSearchIcon"
                            :full-width-content="true"
                            :focus-counter="inputFocusCounter"
                            height="32px"
                            :reset-input-value="resetInputValue"
                            results-max-height="256px"
                            :search-results="allCountries">
          <template v-slot:search-icon>
            <svg-icon class="search-icon country-search-icon"
                      icon="search-solid"/>
          </template>

          <template v-slot:results="results">
            <template v-if="results.result?.length > 0">
              <div v-for="result in results.result"
                   @click="selectClicked(result)"
                   class="result-item">
                <span class="country-flag">{{ flagEmoji(result?.code) }}</span>
                <span class="product-name">{{ result?.name }}</span>
                <span
                    class="keywords-count">{{
                    +result?.keywords_count > 0 ? '(' + result?.keywords_count + ')' : ''
                  }}</span>
              </div>
            </template>
            <div v-else
                 class="no-results">Nothing was found.
            </div>
          </template>
        </basic-search-input>

        <template v-if="showLoader">
          <div class="preloader-wrap country-tabs-preloader">
            <preloader-table-small/>
          </div>
        </template>

      </div>
      <div class="tab-content-item"
           :class="{active: currentTab === 2}">

        <basic-search-input placeholder="Search..."
                            @search-input-changed="searchInputChanged"
                            @clicked-outside="countriesTabsClosed"
                            :with-result-slots="true"
                            :min-input-length="0"
                            :show-caret="false"
                            :show-search-icon="showSearchIcon"
                            :full-width-content="true"
                            :focus-counter="inputFocusCounter"
                            height="32px"
                            :reset-input-value="resetInputValue"
                            results-max-height="256px"
                            :search-results="allCountries">
          <template v-slot:search-icon>
            <svg-icon class="search-icon country-search-icon"
                      icon="search-solid"/>
          </template>

          <template v-slot:results="results">
            <template v-if="countryInput?.length > 0">
              <template v-if="results.result?.length > 0">
                <div v-for="result in results.result"
                     @click="selectClicked(result)"
                     class="result-item">
                  <span class="country-flag">{{ flagEmoji(result?.code) }}</span>
                  <span class="product-name">{{ result?.name }}</span>
                  <span
                      class="keywords-count">{{
                      +result?.keywords_count > 0 ? '(' + result?.keywords_count + ')' : ''
                    }}</span>
                </div>
              </template>
              <div v-else
                   class="no-results">Nothing was found.
              </div>
            </template>
            <template v-else>
              <div class="no-search-block">
                <template v-if="favoriteCountries.result?.length > 0">
                  <div v-for="result in favoriteCountries.result"
                       @click="selectClicked(result)"
                       class="result-item">
                    <span class="country-flag">{{ flagEmoji(result?.code) }}</span>
                    <span class="product-name">{{ result?.name }}</span>
                    <span
                        class="keywords-count">{{
                        +result?.keywords_count > 0 ? '(' + result?.keywords_count + ')' : ''
                      }}</span>
                  </div>
                </template>
                <div v-else>No favorite countries.</div>
              </div>
            </template>
          </template>
        </basic-search-input>

        <!--        <template v-if="showLoader">-->
        <!--          <div class="preloader-wrap">-->
        <!--            <preloader-table-small/>-->
        <!--          </div>-->
        <!--        </template>-->

      </div>
    </div>
  </div>
</template>

<script>
import BasicSearchInput from '@/components/UI/BasicSearchInput/index';
import PreloaderTableSmall from '@/components/UI/PreloaderTableSmall/index';
import {getFlagEmoji} from "@/utils/utils";
import {mapGetters} from "vuex";

export default {
  name: "CustomTabs",
  props: {
    allCountries: {
      type: Object,
    },
    resetTabsToDefault: {
      type: Number,
    }
  },
  emits: ['country-selected', 'country-input-changed', 'fetch-countries'],
  data() {
    return {
      currentTab: 1,
      searchResults: [],
      filteredSearchResults: [],
      countryInput: '',
      showLoader: false,
      inputFocusCounter: 0,
      resetInputValue: 0,
    }
  },
  components: {
    'basic-search-input': BasicSearchInput,
    'preloader-table-small': PreloaderTableSmall,
  },
  methods: {
    tabClicked(ref) {
      this.currentTab = ref;
      if (this.currentTab === 2) {
        this.$emit('fetch-countries');
        this.inputFocusCounter++;
      }

      if (this.currentTab === 1) {
        this.inputFocusCounter++;
      }

      this.resetInputValue++;
    },
    searchInputChanged(value) {
      this.countryInput = value;
      this.$emit('country-input-changed', value);
    },
    selectClicked(value) {
      this.$emit('country-selected', value);
      this.selectedValue = `${this.flagEmoji(value?.code)} ${value.name}`;
      this.resetInputValue++;
    },
    flagEmoji(val) {
      return getFlagEmoji(val);
    },
    countriesTabsClosed() {
      if (this.favoriteCountries?.result?.length > 0) {
        this.currentTab = 2;
      }
    },
    setDefaultTab() {
      if (this.favoriteCountries?.result?.length > 0) {
        this.currentTab = 2;
        this.inputFocusCounter++;
      } else {
        this.currentTab = 1;
      }
    },
  },
  mounted() {
    this.setDefaultTab();
  },
  computed: {
    ...mapGetters([
      'countryLoading'
    ]),
    showSearchIcon() {
      return this.countryInput.length === 0;
    },
    favoriteCountries() {
      if (!this.allCountries?.result) {
        return [];
      }
      this.filteredSearchResults = {...this.allCountries};
      const countriesArray = Object.values(this.filteredSearchResults?.result);
      return {result: countriesArray.filter(country => country.keywords_count > 0)};
    }
  },
  watch: {
    allCountries: {
      handler(newValue, oldValue) {
        // if (this.countryInput.length === 0) {
        //   this.setDefaultTab();
        // }
      },
      deep: true,
    },
    resetTabsToDefault(val) {
      this.setDefaultTab();
    },
    countryLoading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.showLoader = newValue;
      }
    }
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>