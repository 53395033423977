<template>
  <custom-modal :opened="showModal"
                :max-width="520"
                @closeModal="closeModal('showModal')"
                class-name="plan-actions-modal">
<!--    <div class="modal-message"> You have exceeded the limits of keywords and competitors for your account. Please, delete some keywords and  competitors to fit within the current account limits.</div>-->
    <div class="footer-text">
      You have exceeded the limits of keywords and competitors for your account. Please, delete some keywords and  competitors to fit within the current account limits.
    </div>
    <template v-slot:footer-action>
      <div class="btn-block action-btns">
        <slot></slot>
      </div>
    </template>
  </custom-modal>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton/index.vue";

export default {
  name: "OverheadAppsModal",
  components: {BaseButton},
  props: {
    planName: {
      type: String,
      default: 'Plan name'
    },
    planKey: {
      type: String,
    },
    openShowModalOutside: {
      type: Number
    },
  },
  data() {
    return {
      showModal: true,
      env: process.env.process.env.VUE_APP_URL,
    }
  },
  methods: {
    subscriptionRoute() {
      const path = 'subscription';
      return this.env + path + '?plan=' + this.planKey;
    },
  },
  watch: {
    openShowModalOutside(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showModal = true;
      }
    }
  }
}
</script>

<style lang="scss">
.plan-actions-modal {
  header {
    padding: 16px 32px 6px !important;
  }

  .main {
    padding: 0 32px 32px !important;
  }

  .modal-message {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: var(--neutral-900);
    margin-bottom: 12px;
  }

  .footer-text {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: var(--neutral-800);
    margin-bottom: 24px;
  }

  .action-btns {
    max-width: 100%;
    margin: 0;
    justify-content: center;

    .btn {
      width: 144px;
      min-width: 144px;
      max-width: 144px;
      font-weight: 600;
      border-radius: 4px;
      margin: 0 12px 0 0;
      white-space: nowrap;
    }

    .btn:last-child {
      margin-right: 0;
    }
  }
}
</style>