<template>
  <template v-if="currentApp?.id">
    <AppsSelect :global-mode="true" :selected-app="currentApp" @app-selected="appClickHandler"></AppsSelect>
  </template>

  <template v-if="currentApp?.id && $route.meta?.hideHeaderCountrySelector !== true">
    <select-country :current-country="currentCountry"
                    :countries="searchCountryResults"
                    :is-loading="countriesListLoading"
                    @opened="setCountriesFilter"
                    @changed="countryFilterSelected"
    />
  </template>
</template>

<script>
import {httpRequest} from "@/api";
import {setCookie, getCookie} from "@/utils/utils";
import CustomInlineSearch from "@/components/Forms/CustomInlineSearch/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import getters from "@/store/getters";
import AppsSelect from "./AppsSelect.vue";
import SelectCountry from "@/components/Dropdowns/SelectCountry/index.vue";
import {mapGetters} from "vuex";
import myApps from "@/api/modules/my-apps";

export default {
  name: "HeaderMainOptions",
  components: {
    SelectCountry,
    AppsSelect,
    'custom-inline-search': CustomInlineSearch,
    'preloader-table-small': PreloaderTableSmall,
  },
  props: {
    currentApp: {
      type: Object,
    },
    currentCountry: {
      type: Object,
    }
  },
  data() {
    return {
      searchCountryResults: [],
      appListLoading: false,
      countriesListLoading: false,
    }
  },
  methods: {
    async appClickHandler(item) {
      await this.$store.dispatch('SET_CURRENT_APP', item);

      setCookie('currentAppId', item.id, null, 7);
      this.$store.dispatch('SET_CURRENT_APP_RELOADED', true);
      this.$store.dispatch('SET_CURRENT_APP_ID', item.id);

      await this.$store.dispatch('SET_DATA_COUNTS', {appId: item.id});

      if (this.$route.name === 'MyApps') {
        this.$router.push('/search-terms/keywords-tracking');
      }
    },
    async setCountriesFilter() {
      if (getters.countryLoading(this.$store.state)) {
        return;
      }
      this.countriesListLoading = true;

      this.$store.dispatch('SET_COUNTRY_LOADING', true);
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.SEARCH_KEYWORDS_COUNTRIES + '/' + this.currentAppId + '/countries');
      this.$store.dispatch('SET_COUNTRIES_LIST', result?.countries);
      this.$store.dispatch('SET_COUNTRY_LOADING', false);
      this.searchCountryResults = [...result?.countries];

      this.countriesListLoading = false;
    },
    async countryFilterSelected(value) {
      this.showCollapsedSidebarAppContent = false;
      this.$store.dispatch('SET_CURRENT_COUNTRY_MANUAL', value);
      this.$store.dispatch('SET_CURRENT_COUNTRY', value);
      // this.$store.dispatch('SET_DATA_COUNTS_LOADER', false);
      await this.setCurrentLatestCountry(value?.id);
      await this.setCountriesFilter();
      await myApps.checkCurrentApp(value?.code);
      await this.$store.dispatch('SET_DATA_COUNTS', {countryCode: value?.code});

      // this.$store.dispatch('SET_DATA_COUNTS_LOADER', true);
      // this.appSearchInput = '';
    },
    async setCurrentLatestCountry(countryId) {
      const url = process.env.VUE_APP_API_URL + 'api/profiles/applications/' + this.currentAppId + '/' + countryId + '/used';
      await httpRequest('POST', url);
    },
    countriesDropdownOpened() {
      this.setCountriesFilter();
    },
  },
  computed: {
    ...mapGetters([
      'currentApp',
    ]),
    currentAppId() {
      return this.currentApp.id ?? getCookie('currentAppId');
    },
  },
}
</script>