<template>
  <div class="response-message error">
    <div class="response-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorMessageBlock",
  props: {
    message: {
      type: String
    }
  }
}
</script>