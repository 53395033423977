export default {
    POSITIONS: 'api/positions',
    USER: 'auth/user',
    LOGIN: 'auth/login',
    LOGOUT: 'auth/logout',
    REGISTER: 'auth/register',
    PROFILES: 'api/profiles',
    AVATARS: 'api/profiles/avatars',
    CONTACTS: 'api/tickets',
    USER_LIMITS: 'api/user-limits',
    PLANS_LIMITS: 'api/plans-limits',
    PLANS_PRICES: 'api/plans-prices',
    USER_CURRENT_PLAN: 'api/curr-plan',
    FILTER_STORES: 'api/catalog/stores',
    FILTER_CATEGORIES: 'api/catalog/categories',
    FILTER_COUNTRIES: 'api/catalog/countries',
    FILTER_CLUSTERS: 'api/catalog/clusters',
    FILTER_LOCALES: 'api/catalog/locales',
    COUNTRY_LOCALES: 'api/catalog/country-locales', // /{storeKey}/{countryCode}
    FETCH_COMPETITORS_COUNT: 'api/profiles/application/custom-competitors-count', // ?app_id=5482&is_competitor=1
    COUNTRY_LANGUAGES: 'api/catalog/country-languages', // /{storeKey}/{countryCode}
    LANGUAGES: 'api/catalog/languages', // {countryCode}
    SETTINGS: 'api/profiles/settings',

    LOCALES_FIX: [
        {
            code: 'zh_CN',
            name: 'Chinese',
            store: 1,
        },
        {
            code: 'es_ES',
            name: 'Spanish',
            store: 1,
        }
    ],
};