<script>
export default {
  name: "CustomMultiselectNew",
  emits: ['selected'],
  props: {
    items: {
      type: Array,
      required: true,
    },
    preSelected: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.selected = this.preSelected;
  },
  data() {
    return {
      selected: [],
      opened: false,

      uniqueId: Math.random().toString(36).slice(2, 9),
    }
  },
  computed: {
    isAllSelected() {
      return this.items.length === this.selected.length;
    },
  },
  methods: {
    toggleAll(e) {
      if (e.target.checked) {
        this.selected = this.items.map(item => item.value);
      } else {
        this.selected = [];
      }
    },
    outside() {
      if (this.opened) {
        this.opened = false;
      }
    },
  },
  watch: {
    selected() {
      this.$emit('selected', this.selected);
    },
  },
}
</script>

<template>
  <div class="multiselect_trigger" v-click-outside="outside">
    <div class="select_trigger cursor-pointer" @click="opened = !opened">
      <slot name="trigger">
        <span v-if="isAllSelected">
          <slot name="trigger-all">
            All Selected
          </slot>
        </span>
        <span v-else>{{ selected.length }} selected</span>
        <div class="open_close">
          <svg-icon icon="angle-down-solid" :class="{rotate: opened}"/>
        </div>
      </slot>
    </div>
    <div class="options_container mt-10" v-show="opened">
      <div class="options">
        <div class="option">
          <div class="option_checkbox mt-5 mb-10">
            <input class="common-checkbox" :id="'all' + '_' + uniqueId" type="checkbox" @change="toggleAll" :checked="isAllSelected">
            <label :for="'all' + '_' + uniqueId" v-if="isAllSelected">Unselect All</label>
            <label :for="'all' + '_' + uniqueId" v-else>Select All</label>
          </div>
        </div>
        <div class="option" v-for="item in items" :key="item.value">
          <div class="option_checkbox mt-5">
            <input class="common-checkbox" type="checkbox" :id="item.value + '_' + uniqueId" :value="item.value" v-model="selected">
            <label :for="item.value + '_' + uniqueId">
              <slot name="label" :label="item.label">
                {{ item.label }}
              </slot>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.select_trigger {
  user-select: none;
  position: relative;
  width: 100%;
  height: 36px;
  border: 1px solid var(--neutral-500);
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--neutral-800);
  display: flex;
  align-items: center;
  padding-left: 12px;
}
.options_container {
  max-height: 200px;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    border: 1px solid var(--scroll-bar-screen);
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--scroll-bar-screen);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--scroll-bar-screen);
  }
}
.option_checkbox {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--neutral-800);
  user-select: none;
  display: flex;
  align-items: center;

  label {
    cursor: pointer;
    width: 100%;
    display: block;

    &:hover {
      color: var(--neutral-900);
      background-color: var(--neutral-200);
    }
  }
}
.open_close {
  position: absolute;
  right: 12px;

  .rotate {
    transform: rotate(180deg);
  }
}
</style>