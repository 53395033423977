export const labels = {
    emoji: [
        {
            name: 'hot',
            icon: '🔥',
        },
        {
            name: 'soon',
            icon: '⏳',
        },
        {
            name: 'new',
            icon: '🔔',
        },
    ],
    text: [
        {
            name: 'hot'
        },
        {
            name: 'soon'
        },
        {
            name: 'new'
        },
    ]
};