export default {
    TOP_APP_DATA: 'api/app-dashboard/top-app-data-block',
    TRACING_KEYWORDS_DATA: 'api/app-dashboard/tracking-keywords-block',
    COMPETITORS_DATA: 'api/app-dashboard/competitors-block',
    INSIGHTS_DATA: 'api/app-dashboard/insights-block',
    COMPARE_CATEGORY_BLOCK: 'api/app-dashboard/compare-category-block',
    SEARCH_VISIBILITY_BLOCK: 'api/app-dashboard/search-visibility-block',
    CATEGORY_VISIBILITY_BLOCK: 'api/app-dashboard/category-visibility-block',
    CATEGORY_RANKING_CHART: 'api/app-dashboard/category-ranking-chart',
    CATEGORY_COUNTRIES_CHARTS: 'api/app-dashboard/category-countries-ranking',
    APP_INFO_BLOCK: 'api/app-dashboard/app-info-block',
    VERSION_HISTORY_BLOCK: 'api/app-dashboard/version-history-block',
    ASO_SCORE_DATA: 'api/applications/', //api/applications/{applicationId}/{countryCode}/aso-score
};