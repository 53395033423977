<template>
  <header class="site-header main">
    <div class="header-content">
      <div class="left-part">
        <router-link v-if="showUserDetails"
                     :to="{name: 'MyApps'}"
                     class="site-logo">
          <img src="@/assets/images/aso-logo-header-new.svg"
               width="138"
               height="32"
               class="logo-icon full-logo"
               alt="Site logo"/>
        </router-link>
        <div v-else
             class="site-logo">
          <img src="@/assets/images/aso-logo-header-new.svg"
               width="138"
               height="32"
               class="logo-icon full-logo"
               alt="Site logo"/>
        </div>
      </div>

      <div class="header-main-blocks">
        <slot name="main-block">
          <div class="middle-part">
            <header-main-options v-if="!isMobile"
                                 :current-country="currentCountry"
                                 :current-app="currentApp"/>
          </div>
        </slot>

        <div class="right-part"
             v-if="$route.name !== 'PlanSelectionPage' && showUserDetails">

          <order-radaso-button v-if="$route.name === 'MyApps'"
                               class="order-radaso-button-wrap"
                               :pre-selected="['aso-audit', 'launch-support', 'full-aso-support']" />

          <header-user-details :user-data="userData"
                               @userLogoutClicked="userLogoutClicked"/>

          <header-burger :user-data="userData"
                         @userLogoutClicked="userLogoutClicked"/>
        </div>
      </div>
    </div>
  </header>

</template>

<script>
import {mapGetters} from 'vuex';
import {httpRequest} from "@/api";
import {deleteCookie} from "@/utils/utils";
import HeaderUserDetails from '@/components/Dropdowns/HeaderUserDetails/index';
import HeaderSearch from '@/components/Dropdowns/HeaderSearch/index';
import HeaderBurger from '@/components/Dropdowns/HeaderBurger/index';
import HeaderMainOptions from "@/components/UI/HeaderMainOptions/index.vue";
import OrderRadasoButton from "@/components/OrderRadaso/Button/index.vue";

export default {
  name: "TheHeader",
  components: {
    OrderRadasoButton,
    'header-user-details': HeaderUserDetails,
    'header-search': HeaderSearch,
    'header-burger': HeaderBurger,
    'header-main-options': HeaderMainOptions,
  },
  props: {
    showUserDetails: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    /*if (!this.currentApp?.id) {
      this.$store.dispatch('INIT_SELECTED_APP');
    }*/
  },
  methods: {
    async userLogoutClicked() {
      await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.user.LOGOUT);
      deleteCookie('token');
      deleteCookie('currentAppId');
      this.$store.dispatch('SET_TOKEN', null);
      location.reload();
    },
  },
  computed: {
    ...mapGetters([
      'userData',
      'currentApp',
      'currentCountry',
      'isMobile',
    ]),
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>