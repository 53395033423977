<script>
import CustomMultiselectNew from "../../Forms/CustomMultipleSelect/CustomMultiselectNew.vue";
import CustomInputCounter from "../../Forms/CustomInputCounterNew/index.vue";

export default {
  name: "FilterItemComp",
  components: {CustomInputCounter, CustomMultiselectNew},
  props: {
    filter: {
      type: Object,
      required: true
    },
    resetKey: {
      type: Number,
      default: 0
    }
  },
  emits: ['clear-filter'],
  methods: {
    clearFilter(filter) {
      this.$emit('clear-filter', filter);
    }
  },
}
</script>

<template>
  <div class="filter-item" v-if="!filter.hidden">
    <label class="mb-8">{{ filter.name }} <span class="color-primary cursor-pointer" data-action="filter-reset"
                                                v-if="filter.value.isChanged()" @click="clearFilter(filter)">Clear</span></label>
    <div class="filter-input">
      <template v-if="filter.type === 'range'">
        <div class="range-inputs">
          <custom-input-counter
              :input-data="filter.value.currentMinValue"
              :max="filter.value.maxValue"
              :min="filter.value.minValue"
              :custom-max-width="'100%'"
              @input-counter-changed="(v) => filter.value.setMinValue(v.value)"
          ></custom-input-counter>
          <custom-input-counter
              :input-data="filter.value.currentMaxValue"
              :max="filter.value.maxValue"
              :min="filter.value.minValue"
              :custom-max-width="'100%'"
              @input-counter-changed="(v) => filter.value.setMaxValue(v.value)"
          ></custom-input-counter>
        </div>
      </template>
      <template v-else-if="filter.type === 'dropdown-multi'">
        <custom-multiselect-new
            :key="resetKey"
            @selected="(v) => filter.value.setSelectedValues(v)"
            :pre-selected="filter.value.getSelectedValues()"
            :items="filter.value.items"
        ></custom-multiselect-new>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
label {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--neutral-900);
  display: block;
  margin-bottom: 8px;
  user-select: none;
}

.filter-item {
  text-align: left;
  margin-top: 15px;

  &:first-child {
    margin-top: 0;
  }
}

.range-inputs {
  display: flex;

  .custom-input-counter {
    width: 128px;

    &:first-child {
      margin-right: 10px;
    }
  }
}
</style>