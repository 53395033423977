export default {
    EXPORTS_TRACKING: 'export/tracking-keywords',
    EXPORTS_RANKING_KEYWORDS: 'export/ranking-keywords',
    EXPORTS_AUTOSUGGESTS: 'export/auto-suggests',
    EXPORTS_META_KEYWORDS: 'export/meta-keywords',
    RECOMMENDED_KEYWORDS: 'export/recommended-keywords',
    META_KEYWORDS_TABLE: 'export/meta-keywords-table',
    META_CREATOR: 'export/meta-creator',
    EXPORTS_COMPETITORS: 'export/competitors',
    EXPORTS_RELATED: 'export/related-keywords',
    EXPORTS_IMPRESSIONS_REPORT: 'export/impressions-report',
    EXPORTS_RANK_COMPARATIVE_REPORT: 'export/rank-comparative-report',
    REVIEWS_SUMMARIES: 'export/reviews-summaries',
};