<template>
  <div class="custom-select-with-search"
       v-click-outside="outside"
       :class="[{'active' : isOpened}, {'bg-transparent': bgIsTransparent}, {'no-border': noBorder}, {'no-active-border': noActiveBorder} ]">
    <div class="select-styled"
         :class="[{error: errorMessage?.length > 0}, {'placeholder': selectedValue.length === 0}]"
         :style="{minWidth: width, height: height}"
         @click="triggerDropdown">
      <span id="selected-value">
        <template v-if="typeof selectedValue === 'object'">
          <dynamic-image classes="flag"
                         :width="18"
                         :height="14"
                         :size="32"
                         :country-code="selectedValue.code"/>
          &nbsp;<span>{{ selectedValue.name }}</span>
        </template>
        <template v-else>
        <dynamic-image classes="flag"
                       :width="18"
                       :height="14"
                       :size="32"
                       :country-code="selectedValue.code"/>
          &nbsp;<span>{{ selectedValue.name }}</span>
        </template>

        <template v-if="selectedValue.length === 0">
          <slot name="header">Click to select</slot>
        </template>
      </span>
      <div class="icon">
        <svg-icon icon="angle-down-solid"/>
      </div>
    </div>
    <div class="select-options-wrap with-padding"
         v-if="isOpened">
      <basic-search-input placeholder="Search..."
                          @search-input-changed="addAppSearchHandler"
                          :with-result-slots="false"
                          :focused="isOpened"
                          :min-result-length="minResultLength"
                          :min-input-length="minInputLength"
                          :classes="classes"
                          :position="'relative'"
                          :focus-counter="focusCounter"
                          :search-results="searchResults">
        <template v-slot:search-icon>
          <svg-icon class="search-icon"
                    icon="search-solid"/>
        </template>

        <template v-slot:results="results">
          <div v-for="result in results.result"
               @click="selectClicked(result)"
               class="result-item">
            <dynamic-image v-if="result?.code !== 'WORLDWIDE'"
                           classes="country-flag"
                           :width="18"
                           :height="14"
                           :size="32"
                           :country-code="result?.code"/>
            <span class="product-name">
              {{ result?.name }}
            </span>
            <template v-if="result?.sap">
              <div class="sap-separator">|</div>
              <span class="sap">{{ result.sap }}</span>
            </template>
          </div>

          <template v-if="results.result?.length === 0">
            <div class="no-data">
              No data found on your criteria.
            </div>
          </template>
        </template>
      </basic-search-input>
    </div>

    <error-message v-if="errorMessage?.length > 0">{{ errorMessageComp }}</error-message>

  </div>
</template>

<script>
import ErrorMessageBlock from "@/components/Forms/ErrorMessageBlock/index";
import BasicSearchInput from '@/components/UI/BasicSearchInput/index';

export default {
  name: "CustomSelectWithSearch",
  components: {
    'error-message': ErrorMessageBlock,
    'basic-search-input': BasicSearchInput,
  },
  props: {
    options: {
      type: Object
    },
    errorMessage: {},
    initialData: {},
    bgIsTransparent: {
      type: Boolean,
      default: false
    },
    searchResults: {
      type: Object
    },
    width: {
      type: String
    },
    height: {
      type: String,
      default: '40px'
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    noActiveBorder: {
      type: Boolean,
      default: false
    },
    classes: {
      type: Array
    },
    minResultLength: {
      type: Number,
      default: 0
    },
    minInputLength: {
      type: Number,
      default: 2
    },
  },
  emits: ['add-app-search-handler', 'country-selected', 'dropdown-closed'],
  data() {
    return {
      isOpened: false,
      // selectedValue: '',
      selectedValue: {},
      focusCounter: 0,
    }
  },
  methods: {
    selectClicked(value) {
      this.$emit('country-selected', value);
      this.selectedValue = {
        code: value.code,
        name: value.name,
      };
      this.isOpened = false;
    },
    outside() {
      this.isOpened = false;
    },
    addAppSearchHandler(value) {
      this.$emit('add-app-search-handler', value);
    },
    triggerDropdown() {
      this.isOpened = !this.isOpened;
      if (this.isOpened === false) {
        this.$emit('dropdown-closed');
      }
    }
  },
  mounted() {
    if (this.initialData) {
      this.selectedValue = this.initialData;
    }
  },
  computed: {
    errorMessageComp() {
      if (typeof this.errorMessage === 'string') {
        return this.errorMessage;
      } else {
        let msgString = '';
        this.errorMessage.forEach(item => {
          msgString += item;
        });
        return msgString;
      }
    },
  },
  watch: {
    initialData(newVal, oldVal) {
      // this.selectedValue = val;
    },
    isOpened(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.focusCounter++;
      }
    }
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>