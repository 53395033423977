import {SplitArrays} from "@/utils/factories";
import {fetchPromiseAllSettled} from "@/api";

export default {
    TRANSLATE_KEYWORDS: 'api/keywords-translate', //?locale_code=en&store_key=APP_STORE&keywords[0]=привет&keywords[1]=игра&keywords[2]=аркада

    //TODO remove vue context dependency
    translateKeywords: function (vueContext, store_key, keywords) {
        const separatedArrays = SplitArrays(keywords, 100);

        if (separatedArrays?.length === 0) {
            return;
        }

        let queryArray = [];

        separatedArrays.forEach(async array => {
            let urlQuery = '?locale_code=' + 'en' + '&store_key=' + store_key;
            array.forEach((keyword, idx) => {
                urlQuery += `&keywords[${idx}]=${encodeURIComponent(keyword)}`;
            });
            queryArray.push({
                url: process.env.VUE_APP_API_URL + this.TRANSLATE_KEYWORDS + urlQuery,
                method: 'GET',
            });
        });

        return new Promise(async (resolve, reject) => {
            let response = await fetchPromiseAllSettled(queryArray);
            if (response.success === true) {
                let result = {};
                response.data.forEach(response => {
                    for (const [key, value] of Object.entries(response)) {
                        result[key] = value;
                    }
                });
                resolve(result);
            }
        });
    },

    translateKeyword(vueContext, store_key, keyword) {
        return new Promise((resolve, reject) => {
            this.translateKeywords(vueContext, store_key, [keyword]).then(result => {
                resolve(result[keyword]);
            });
        });
    },

    TRANSLATE_PHRASES: 'api/translate',

    translatePhrases: function (store_key, phrases) {
        const separatedArrays = SplitArrays(phrases, 100);

        if (separatedArrays?.length === 0) {
            return;
        }

        let queryArray = [];

        separatedArrays.forEach(async array => {
            let urlQuery = '?locale_code=' + 'en' + '&store_key=' + store_key;
            array.forEach((keyword) => {
                urlQuery += `&phrases[]=${encodeURIComponent(keyword)}`;
            });
            queryArray.push({
                url: process.env.VUE_APP_API_URL + this.TRANSLATE_PHRASES + urlQuery,
                method: 'GET',
            });
        });

        return new Promise(async (resolve, reject) => {
            let response = await fetchPromiseAllSettled(queryArray);
            if (response.success === true) {
                let result = {};
                response.data.forEach(response => {
                    for (const [key, value] of Object.entries(response)) {
                        result[key] = value;
                    }
                });
                resolve(result);
            }
        });
    }
};