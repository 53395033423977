<template>
  <div class="skeleton-box"
       :class="{collapsed: collapsed}">
    <div class="skeleton-details">
      <div class="skeleton-image"></div>
      <div class="skeleton-name-block">
        <div class="skeleton-name"></div>
      </div>
    </div>

    <div class="bottom-block">
      <div class="rating">
        <div class="rating-skeleton"></div>
        <div class="rating-skeleton"></div>
      </div>
      <div class="other-apps-skeleton"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CurrentAppSkeletonBox",
  props: {
    collapsed: {
      type: Boolean
    }
  },
  computed: {
    showCurrentCountryDropdown() {
      return !(this.$route.name === 'Profile' || this.$route.name === 'MyApps');
    }
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>