<template>
  <div class="basic-filter-dropdown">
    <div class="range-input-header">
      <slot></slot>
      <div class="clear-btn"
           v-if="showClearBtn"
           @click="cleanFilterClicked">Clear
      </div>
    </div>

    <sources-dropdown :options="item?.presetsData"
                      :reset-preset-counter="resetCounter"
                      :key="multiSelect ? resetCounter : 0"
                      direction="down"
                      :multi-select="multiSelect"
                      placeholder="Select source"
                      :initial-data="initialValue"
                      :initial-default-value="initialDefaultValue"
                      @multi-select-clicked="multiFilterChanged($event, item?.filter)"
                      @select-clicked="filterChanged($event, item?.filter)"/>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import SourcesDropdown from "@/components/UI/FiltersComponent/components/SourcesDropdown/index.vue";

export default defineComponent({
  name: "index",
  components: {
    'sources-dropdown': SourcesDropdown,
  },
  emits: ['filter-changed', 'filter-cleaned'],
  props: {
    initialData: {
      type: Object,
    },
    initialDefaultValue: {
      type: String,
    },
    initialValue: {
      type: String,
    },
    resetDataCounter: {
      type: Number,
    },
    multiSelect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      item: {},
      resetCounter: 0,
      filterValue: '',
      showClearButton: false,
    }
  },
  mounted() {
    this.item = {...this.initialData};

    if (this.initialData?.isInitiallyActive) {
      this.showClearButton = true;
    }
  },
  methods: {
    cleanFilterClicked() {
      this.filterValue = 'All Sources';
      this.resetCounter++;
      this.showClearButton = false;
      if (this.multiSelect) {
        this.item.presetsData = this.item?.defaultData ?? this.item.presetsData;
        this.$emit('filter-cleaned', {
          e: {}
        });
      } else {
        this.$emit('filter-cleaned', {
          filterValue: "all",
          presetValue: "All",
          type: "sources"
        });
      }
    },
    filterChanged(e, type) {
      this.filterValue = e?.presetValue;
      this.showClearButton = true;
      this.$emit('filter-changed', {
        ...e, type
      });
    },
    multiFilterChanged(e, type) {
      this.showClearButton = true;
      this.$emit('filter-changed', {
        e, type
      });
    },
    cleanFilter() {
      this.cleanFilterClicked();
    }
  },
  computed: {
    showClearBtn() {
      return this.showClearButton || (this.filterValue !== '' && this.filterValue !== 'All' && this.filterValue !== 'All Sources');
    },
  },
  watch: {
    resetDataCounter(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.filterValue = 'All Sources';
        this.resetCounter++;
        this.showClearButton = false;
        this.$emit('filter-changed', {
          filterValue: "all",
          presetValue: "All",
          type: "sources"
        });
      }
    },
    initialData(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal?.isInitiallyActive === undefined) {
          this.showClearButton = false;
        }
      }
    },
  }
})
</script>

<style scoped lang="scss">
.basic-filter-dropdown {
  position: relative;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.range-input-header {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--neutral-900);
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  .clear-btn {
    margin-left: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-default);
    cursor: pointer;
  }
}
</style>