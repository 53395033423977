<template>
  <span class="label"
        :class="[{'text-label': labelType !== 'emoji'}, labelContent]">
   {{ label }}
  </span>
</template>

<script>
import {labels} from "@/sidebar-menu/labels-types";

export default {
  name: "Label",
  props: {
    labelContent: {
      type: String,
    },
    labelType: {
      type: String,
      default: 'text'
    },
  },
  computed: {
    label() {
      if (this.labelType === 'emoji') {
        const tempLabel = labels.emoji.find(item => {
          return item.name === this.labelContent
        });
        return tempLabel.icon;
      }

      return this.labelContent;
    }
  }
}
</script>

<style scoped lang="scss">
.label {
  margin-left: 8px;

  &.text-label {
    padding: 0 4px;
    border-radius: 4px;
    height: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 600;
    line-height: 1.45;
    color: #fff;
    text-transform: uppercase;

    &.new {
      background-color: #6bd790;
    }

    &.soon {
      background-color: #ff9f40;
    }

    &.hot {
      background-color: #f16f54;
    }
  }
}

.sidebar.collapsed {
  .label.text-label {
    @media only screen and (min-width: 768px) {
      margin-left: 6px;
      //display: none;
    }
  }
}
</style>