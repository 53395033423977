<template>
  <div class="presets-select"
       v-click-outside="outside"
       :class="[{'active' : isOpened}, {'bg-transparent': bgIsTransparent}, 'direction-' + direction, classes]">
    <div class="select-styled"
         @click="isOpened = ! isOpened">
      <span id="selected-value">
         <template v-if="selectedValue?.presetName">
         {{ selectedValue?.presetName }}: <span class="value">{{ selectedValue?.presetValue }}</span>
         </template>
        <span class="placeholder" v-else>
          {{ placeholder }}
        </span>
      </span>
      <div class="icon">
        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-right" role="img"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"
             class="svg-inline--fa fa-angle-right fa-w-6 fa-3x">
          <path fill="currentColor"
                d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"
                class=""></path>
        </svg>
      </div>
    </div>

    <div class="select-options-wrap">
      <slot name="results">
        <ul class="select-options">
          <li v-for="option in options"
              @click="selectClicked(option)">
            <div class="name">{{ option?.presetName }}:</div>
            <div class="value">{{ option?.presetValue }}</div>
          </li>
        </ul>
      </slot>
    </div>

  </div>
</template>

<script>

export default {
  name: "PresetsDropdown",
  emits: ['select-clicked'],
  props: {
    options: {
      type: Object
    },
    initialData: {
      type: String
    },
    bgIsTransparent: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'down'
    },
    classes: {
      type: String,
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    closeDropdown: {
      type: Number,
      default: 0,
      required: false,
    },
    resetPresetCounter: {
      type: Number,
      default: 0,
      required: false,
    },
    placeholder: {
      type: String,
      default: 'Select presets',
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      isOpened: false,
      selectedValue: {
        presetName: '',
        presetValue: '',
      },
    }
  },
  methods: {
    selectClicked(option) {
      let tempObj = {
        presetName: option.presetName,
        presetValue: option.presetValue,
      }

      this.selectedValue = {...tempObj};

      this.isOpened = false;
      this.$emit('select-clicked', option);
    },
    outside() {
      this.isOpened = false;
    },
  },
  mounted() {
    if (this.initialData) {
      this.selectedValue = {...this.initialData};
    }
  },
  watch: {
    initialData(val) {
      if (val) {
        // this.selectedValue = val;
        this.selectedValue = {...val};
      }
    },
    closeDropdown(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isOpened = false;
      }
    },
    resetPresetCounter(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedValue = {
          presetName: '',
          presetValue: '',
        };
        this.isOpened = false;
      }
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>