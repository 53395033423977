<template>
  <div class="title-block">
    <div class="logo">
      <img v-if="logo"
           alt="App Logo"
           :width="logoSize"
           :height="logoSize"
           :src="cropImage(logo, logoSize + 'x' + logoSize, store)"
      />
    </div>
    <div class="title">
      <div class="title-text">
        <a :href="storeLink" target="_blank" v-if="mode === 'with-link'">
          <svg-icon v-once
                    style="height: 12px"
                    icon="link-icon"
                    class="link-icon common-app-store-link"/>
        </a>
        <span>{{ title }}</span>
      </div>
      <div>
        <a :href="storeLink" target="_blank">
          <img v-if="store === 'APP_STORE'"
               src="@/assets/images/icons/app_store.png"
               alt="Store icon"
               class="store-icon"
               :width="storeIconSize"
               :height="storeIconSize">
          <img v-else
               src="@/assets/images/icons/google_play.png"
               alt="Store icon"
               class="store-icon"
               :width="storeIconSize"
               :height="storeIconSize">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {cropImage} from "@/utils/utils";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";

export default {
  name: 'AppTitle',
  components: {SvgIcon},
  props: {
    title: String,
    logo: String,
    store: String,
    storeLink: String,
    storeIconSize: {
      type: Number,
      default: 12,
    },
    logoSize: {
      type: Number,
      default: 28,
    },
    mode: {
      type: String,
      default: 'without-link-icon'
    }
  },
  data() {
    return {

    };
  },
  methods: {
    cropImage,
  },
}
</script>

<style lang="scss">
.title-block {
  display: flex;
  gap: 10px;
  width: 100%;

  .title-text {
    display: flex;
    gap: 3px;
  }

  .logo {
    align-items: center;
    display: flex;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 7px;
    width: 100%;

    img {
      margin-top: 8px;
    }
  }
}
</style>
