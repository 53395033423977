<template>
  <div class="presets-select"
       v-click-outside="outside"
       :class="[{'active' : isOpened}, {'bg-transparent': bgIsTransparent}, 'direction-' + direction, classes]">
    <div class="select-styled"
         :class="{'multi':multiSelect}"
         @click="isOpened = ! isOpened">
      <span id="selected-value">
        <slot name="heading" v-if="!multiSelect">
         <span class="value">{{
             selectedValue?.presetValue?.length > 0 ? selectedValue?.presetValue : initialDefaultValue
           }}</span>
        </slot>
        <slot name="heading" v-else-if="options">
          <div class="value" v-if="selectedValues?.length >= options.length">All selected</div>
          <div class="value" v-else>{{ selectedValues?.length }} selected</div>
        </slot>
      </span>
      <div class="icon">
        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-right" role="img"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"
             class="svg-inline--fa fa-angle-right fa-w-6 fa-3x">
          <path fill="currentColor"
                d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"
                class=""></path>
        </svg>
      </div>
    </div>


    <div class="select-options-wrap">
      <ul class="select-options">
        <template v-if="!multiSelect">
          <li v-for="option in options"
              @click="selectClicked(option)">
            <div class="value">{{ option?.presetValue }}</div>
          </li>
        </template>
        <template v-else>
          <li v-for="(option, index) in options">
            <label :for="index">
              <div class="checkbox">
                <input v-model="selectedValues"
                       @change="this.$emit('multi-select-clicked', selectedValues)"
                       :id="index"
                       :value="option?.filterValue"
                       type="checkbox">
              </div>
              <div class="value">{{ option?.presetValue }}</div>
            </label>
          </li>
        </template>
      </ul>
    </div>

  </div>
</template>

<script>

import Label from "@/components/UI/Label/index.vue";

export default {
  name: "PresetsDropdown",
  components: {Label},
  emits: ['select-clicked', 'multi-select-clicked'],
  props: {
    options: {
      type: Object
    },
    initialData: {
      type: String
    },
    bgIsTransparent: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'down'
    },
    classes: {
      type: String,
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    closeDropdown: {
      type: Number,
      default: 0,
      required: false,
    },
    resetPresetCounter: {
      type: Number,
      default: 0,
      required: false,
    },
    placeholder: {
      type: String,
      default: 'Select presets',
    },
    initialDefaultValue: {
      type: String,
      default: 'All Sources'
    },
    multiSelect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpened: false,
      selectedValues: [],
      selectedValue: {
        filterValue: '',
        presetValue: '',
      }
    }
  },
  methods: {
    selectClicked(option) {
      this.$emit('select-clicked', option);
      this.selectedValue = option;
      this.isOpened = false;
    },
    outside() {
      this.isOpened = false;
    },
  },
  async mounted() {
    if (this.multiSelect) {
      await this.$nextTick();
      this.selectedValues = this.options?.filter(option => option.selected ?? false).map(option => option.filterValue)
    }

    if (this.initialData) {
      setTimeout(() => {
        for (const key in this.options) {
          if (this.options[key]?.filterValue === this.initialData) {
            this.selectedValue.filterValue = this.options[key]?.filterValue;
            this.selectedValue.presetValue = this.options[key]?.presetValue;
            break;
          }
        }
      }, 200);
    }
  },
  watch: {
    initialData(newVal, oldVal) {
      if (newVal !== oldVal) {
        // this.selectedValue = val;
        for (const key in this.options) {
          if (this.options[key]?.filterValue === this.initialData) {
            this.selectedValue.filterValue = this.options[key]?.filterValue;
            this.selectedValue.presetValue = this.options[key]?.presetValue;
            break;
          }
        }
      }
    },
    closeDropdown(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isOpened = false;
      }
    },
    resetPresetCounter(newVal, oldVal) {
      if (newVal !== oldVal) {
        // this.selectedValue.filterValue = '';
        // this.selectedValue.presetValue = '';

        let tempValue = {...this.selectedValue};
        tempValue.filterValue = '';
        tempValue.presetValue = '';
        this.selectedValue = {...tempValue};

        this.isOpened = false;
      }
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>