<template>
  <div class="basic-accordion"
       :class="[{'active': isActive}, classes]"
       :style="{'height': fullHeight, 'background': background}"
       @mouseleave="cursorLeftTarget">
    <div class="accordion-inner-content">
      <div class="header-block"
           ref="headerBlock"
           :class="justify">
        <slot name="header"
              @click="accordionTrigger">
          <div class="value">
            default value
          </div>
        </slot>
        <div class="icon-wrap"
             @click="accordionTrigger"
             @mousedown="userAccordionClicked"
             :style="{color: color}"
             v-if="caretVisible || !collapsed">
          <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-right" role="img"
               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"
               class="svg-inline--fa fa-angle-right fa-w-6 fa-3x">
            <path fill="currentColor"
                  d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"
                  class=""></path>
          </svg>
        </div>
      </div>
      <div class="content-block"
           ref="contentBlock">
        <div class="content-items-list">
          <transition name="fade">
            <div class="content-item">
              <slot name="accordion-body">default body</slot>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SidebarMenuAccordion",
  data() {
    return {
      isActive: false,
      fullHeight: 0
    }
  },
  props: {
    initialHeight: {
      type: String
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#102148'
    },
    justify: {
      type: String,
      default: 'jcfs'
    },
    background: {
      type: String,
      default: '#ffffff'
    },
    caretVisible: {
      type: Boolean,
      default: false
    },
    dataUpdated: {
      type: Number,
      required: false
    },
    openOnInit: {
      type: Boolean,
      default: false
    },
    reOpenDropdownCounter: {
      type: Number,
      default: 0,
    },
    classes: {
      type: String,
    }
  },
  mounted() {
    this.fullHeight = this.initialHeight;

    // setTimeout(() => {
    // if (this.openOnInit && window.innerWidth > 990 && this.globalLoadFinished) {
    if (this.openOnInit && this.globalLoadFinished) {
      this.isActive = true;
      // const headerBlock = this.$refs.headerBlock;
      // headerBlock.click();
    }
    // }, 500);
  },
  methods: {
    accordionTrigger(e) {
      e.stopPropagation();
      if (!e.target.classList.contains('sublist-item')) {
        this.isActive = !this.isActive;
        this.calculateHeight();
        this.$emit('accordion-trigger-clicked', this.isActive);
      }

    },
    cursorLeftTarget() {
      if (this.collapsed) {
        this.fullHeight = this.initialHeight;
        setTimeout(() => {
          this.isActive = false;
        }, 250);
      }
    },
    calculateHeight() {
      const initHeight = parseInt(this.initialHeight);
      if (!this.$refs?.contentBlock) {
        return;
      }

      if (this.isActive) {
        this.fullHeight = this.$refs?.contentBlock?.clientHeight + initHeight + 'px';
      } else {
        this.fullHeight = this.initialHeight;
      }
    },
    userAccordionClicked() {
      this.$emit('user-accordion-action', !this.isActive);
    }
  },
  computed: {
    ...mapGetters([
      'globalLoadFinished'
    ]),
  },
  watch: {
    initialHeight() {
      this.calculateHeight();
    },
    collapsed() {
      this.isActive = false;
      this.calculateHeight();
    },
    dataUpdated(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.isActive = false;
        this.calculateHeight();
      }
    },
    reOpenDropdownCounter(newVal, oldVal) {
      this.isActive = true;
      // if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
      //   const headerBlock = this.$refs.headerBlock;
      //
      //   if (this.openOnInit) {
      //     setTimeout(() => {
      //       headerBlock.click();
      //     }, 300);
      //   }
      // }
    },
    openOnInit(newVal, oldVal) {
      if (newVal === false) {
        this.isActive = false;
        this.calculateHeight();
      }
    }
  }
}
</script>

<style lang="scss" src="./styles.scss" scoped></style>