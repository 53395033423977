<template>
  <div class="custom-select-with-search"
       v-click-outside="outside"
       :class="[{'active' : isOpened}, {'bg-transparent': bgIsTransparent}, {'no-border': noBorder}, {'no-active-border': noActiveBorder} ]">

    <div class="selected-value"
         v-show="!isOpened || !showSearchInput">
      <slot name="heading-info"></slot>
      <template v-if="selectedValue.length === 0">
        <slot name="header">{{ placeholder }}</slot>
      </template>
    </div>
    <div class="input-wrap"
         v-show="isOpened && showSearchInput">
      <input type="text"
             class="selected-value"
             :class="{'input-active': isOpened}"
             v-model="searchInput"
             @input="searchInputChanged"
             ref="inputSearch"
             :placeholder="placeholder">
      <span class="icon"
            @click="isOpened = !isOpened">
          <svg-icon icon="angle-down-solid"/>
        </span>
    </div>

    <div class="select-styled"
         @click="selectDropdownClicked"
         :class="{'error': errorMessage?.length > 0, 'placeholder': selectedValue.length === 0}"
         :style="defaultOptions">
      <div class="icon">
        <svg-icon icon="angle-down-solid"/>
      </div>
    </div>

    <div class="select-options-wrap">
      <div class="select-options">
        <slot name="results"></slot>
      </div>
    </div>

    <error-message v-if="errorMessage?.length > 0">{{ errorMessageComp }}</error-message>

  </div>
</template>

<script>
import ErrorMessageBlock from "@/components/Forms/ErrorMessageBlock/index";
import BasicSearchInput from '@/components/UI/BasicSearchInput/index';

export default {
  name: "CustomInlineSearch",
  components: {
    'error-message': ErrorMessageBlock,
    'basic-search-input': BasicSearchInput,
  },
  props: {
    options: {
      type: Object
    },
    errorMessage: {},
    initialData: null,
    bgIsTransparent: {
      type: Boolean,
      default: false
    },
    searchResults: {
      type: Object
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    noActiveBorder: {
      type: Boolean,
      default: false
    },
    classes: {
      type: Array
    },
    minResultLength: {
      type: Number,
      default: 0
    },
    minInputLength: {
      type: Number,
      default: 2
    },
    config: {
      type: Object,
    },
    placeholder: {
      type: String,
      default: 'Click to select'
    },
    closeFromOutsideCounter: {
      type: Number,
    },
    showSearchInput: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['search-input-changed', 'dropdown-opened', 'dropdown-closed'],
  data() {
    return {
      isOpened: false,
      selectedValue: '',
      defaultOptions: {
        minWidth: '180px',
        height: '40px'
      },
      searchInput: '',
    }
  },
  methods: {
    outside() {
      this.isOpened = false;
      this.searchInput = '';
      this.$emit('dropdown-closed');
    },
    searchInputChanged() {
      this.$emit('search-input-changed', this.searchInput);
    },
    selectDropdownClicked() {
      this.isOpened = !this.isOpened;
      this.$emit('dropdown-opened');
      // this.$refs.searchInput.focus();
      // this.searchInput = '';
    },
  },
  mounted() {
    if (this.initialData) {
      this.selectedValue = this.initialData;
    }

    if (this.config) {
      this.defaultOptions = {...this.defaultOptions, ...this.config};
    }
  },
  computed: {
    errorMessageComp() {
      if (typeof this.errorMessage === 'string') {
        return this.errorMessage;
      } else {
        let msgString = '';
        this.errorMessage.forEach(item => {
          msgString += item;
        });
        return msgString;
      }
    },
  },
  watch: {
    initialData(newVal, oldVal) {
      this.searchInput = '';
      this.selectedValue = newVal;
    },
    isOpened(newVal, oldVal) {
      if (newVal) {
        setTimeout(() => {
          this.$refs.inputSearch.focus();
        }, 500);
      }
    },
    closeFromOutsideCounter(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isOpened = false;
        this.searchInput = '';
      }
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>