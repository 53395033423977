import {httpRequest} from "../index";
import {Developer} from "../objects/Developer";
import {CustomCompetitor} from "../objects/CustomCompetitor";
import customCompetitors from "./custom-competitors";

export default {
    COMPETITORS_IN_FINDER: 'api/application/find-competitors',
    COMPETITORS_IN_FINDER_V2: 'api/application/find-competitors-v2',
    TOP_APP_DATA: 'api/app-dashboard/top-app-data-block',
    APP_INFO_BLOCK: 'api/app-dashboard/app-info-block',
    VERSION_HISTORY_BLOCK: 'api/app-dashboard/version-history-block',
    TEXTS_BLOCK: 'api/app-dashboard/texts-block',
    APP_METRICS_BLOCK: 'api/app-dashboard/app-metrics-block',
    APP_METRICS_BLOCK_NEW: 'api/app-dashboard/app-metrics-block-new',
    APP_SCREENSHOTS: 'api/app-dashboard/screenshots',
    RANKING_KEYWORDS_BLOCK: 'api/app-dashboard/ranking-keywords-country-in-block',
    CATEGORY_VISIBILITY_BLOCK: 'api/app-dashboard/category-visibility-block',
    APP_FILLED_LOCALES: 'api/app-dashboard/app-filled-locales', ///api/app-dashboard/app-filled-locales/{applicationId}
    INSTALLS_REVENUE: 'api/app-dashboard/installs-revenue',
    GET_LATEST_SCREENSHOTS: 'api/application/find-competitors/getLatestScreenshots', //?country_code=UA&app_id=1328002

    findCompetitors(countryCode, appId) {
        const url = '?country_code=' + countryCode + '&app_id=' + appId;
        const competitorsUrl = process.env.VUE_APP_API_URL + this.COMPETITORS_IN_FINDER + url;

        return new Promise((resolve, reject) => {
            httpRequest('GET', competitorsUrl).then(response => {
                let competitors = [];
                response.forEach(item => {
                    competitors.push(new CustomCompetitor(
                        appId,
                        null,
                        item.application.id,
                        null,

                        item.application.logo,
                        item.application.title,
                        item.application.category,
                        item.application.rating,
                        item.application.store.store_key,
                        item.application.store.app_url,
                        'new',
                        new Developer(
                            item.application.publisher?.name
                        ),
                        item.application.voted,
                        item.application.sub_title
                    ));
                });

                resolve(new customCompetitors.customCompetitorsViewObject([], competitors));
            }).catch(error => {
                console.error(error);
                resolve(new customCompetitors.customCompetitorsViewObject());
            });
        });
    }
};