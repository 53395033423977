import {httpRequest} from "@/api";
import {cropImage, deleteCookie, getCookie, sanitizeString} from "@/utils/utils";
import {UserAppsArrayCreator, UserCurrentAppObjectCreator} from "@/utils/factories";
import store from '../../store';

export default {
    MY_APPLICATIONS: 'api/profiles/applications',
    // RECENTLY_USED_APPLICATIONS: 'api/profiles/applications?sort_by=recently_used_at&sort_direction=desc', // api/profiles/applications/${appId}?country_id=${countryId}
    LIVE_SEARCH_APPLICATIONS: 'api/search',  // api/search?query=${appName}&page=1&limit=15
    SEARCH_APPLICATIONS: 'api/search/live',
    SEARCH_MY_APPLICATIONS: 'api/profiles/search',
    SEARCH_LOCALES: 'api/countries',
    SEARCH_LOCALES_WITH_ID: 'api/catalog/countries',
    MY_APPLICATIONS_NEW: 'api/applications/user-list',
    GET_CURRENT_APP: 'api/applications/user-app', // ?app_id={{params.app_id}}&country_code={{params.country_code}}
    BATCH_DELETE: 'api/profiles/applications-batch-delete', //?app_ids[]=193&app_ids[]=266
    BATCH_INFO: 'api/profiles/applications-batch-info', //?app_ids[]=193&app_ids[]=266

    getApp(appId, countryCode) {
        let url = '?app_id=' + appId + '&country_code=' + countryCode;
        return new Promise((resolve, reject) => {
            httpRequest('GET', process.env.VUE_APP_API_URL + this.GET_CURRENT_APP + url).then(response => {
                resolve(response);
            }).catch(error => {
                console.error(error);
                reject(error);
            });
        });
    },

    async checkCurrentApp(countryCode) {
        const currentAppId = getCookie('currentAppId');
        if (!currentAppId || currentAppId === 'undefined') {
            return;
        }

        const currentApp = await httpRequest(
          'GET',
          process.env.VUE_APP_API_URL + this.GET_CURRENT_APP  + '?app_id=' + currentAppId + '&country_code=' + countryCode
        );

        if (currentApp) {
            await store.dispatch('SET_CURRENT_APP', UserCurrentAppObjectCreator(currentApp) );
        } else {
            deleteCookie('currentAppId');
        }
    },

    async getAllMyApps() {
        let result = await httpRequest(
            'GET',
            process.env.VUE_APP_API_URL + this.MY_APPLICATIONS_NEW
        );
        let myApps = [];
        UserAppsArrayCreator(result).forEach(resultItem => {
            myApps.push({
                id: resultItem.id,
                info: {
                    logo: resultItem.logo,
                    title: resultItem.title,
                    store_link: resultItem.store_link,
                },
                store: resultItem.store,
            });
        });

        return myApps;
    },

    //TODO вынесено. требует пересмотра и рефакторинга
    async searchApps(query, countryCode) {
        const url = process.env.VUE_APP_API_URL
            + this.LIVE_SEARCH_APPLICATIONS
            + '?country_code=' + countryCode
            + '&query=' + query
            + '&page=1&limit=15';
        const result = await httpRequest('GET', url);

        let searchResults;

        if (result?.result) {
            searchResults = {
                results: result?.result?.map(resultItem => {
                    const rating_num = resultItem?.rating_num ? parseFloat(resultItem?.rating_num).toFixed(1) : null;

                    let logo = null;
                    if (resultItem?.logo) {
                        logo = resultItem?.logo;
                    } else if (resultItem?.icon) {
                        logo = resultItem?.icon;
                    }
                    logo = cropImage(logo, '22x22', resultItem?.store?.key);

                    return {
                        id: resultItem?.id ?? null,
                        store_key: resultItem?.store?.key ?? null,
                        logo,
                        link: resultItem?.link ?? null,
                        title: resultItem?.title ? sanitizeString(resultItem?.title) : null,
                        developer_name: resultItem?.developer?.title ?? null,
                        categories: resultItem?.categories ? [...resultItem?.categories] : [],
                        rating_num,
                        votes_num: resultItem?.votes_num,
                        price: resultItem?.price ?? null,
                        genre: resultItem?.genre ?? null,
                    }
                })
            };
        } else {
            searchResults = {
                results: []
            }
        }

        // this.searchResults = {result: result?.results?.filter(item => item !== null)};
        return {result: searchResults?.results?.filter(item => item !== null)};
    },

    getAppsAso(appsIds, localeCode) {
        let urls = [];
        appsIds.forEach(appId => {
            urls.push({
                url: process.env.VUE_APP_API_URL + 'api/applications/' + appId + '/' + localeCode + '/textASO',
                method: 'GET',
            });
        });

        return new Promise((resolve, reject) => {
            return Promise.all(urls.map(url => httpRequest(url.method, url.url))).then(responses => {
                let result = {};
                appsIds.forEach((appId, idx) => {
                    result[appId] = responses[idx];
                });
                resolve(result);
            });
        });
    }
};