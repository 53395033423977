<template v-if="totalPages > 0">
  <div class="pagination">
    <button
      class="pagination-item"
      @click="prevPage"
      :disabled="currentPage === 1"
    >
      <svg-icon icon="angle-left-solid" />
    </button>

    <button
      v-if="pageCount > 4 && currentPage > 3"
      @click="gotoPage(1)"
      class="pagination-item"
      :class="{ active: 1 === currentPage }"
    >
      1
    </button>

    <div v-if="pageCount > 4 && currentPage > 3" class="dots">...</div>

    <button
      v-for="pageNumber in visiblePages"
      :key="pageNumber"
      @click="gotoPage(pageNumber)"
      class="pagination-item"
      :class="{ active: pageNumber === currentPage }"
    >
      {{ pageNumber }}
    </button>

    <div v-if="pageCount > 4 && currentPage < pageCount - 2" class="dots">
      ...
    </div>

    <button
      v-if="pageCount > 4 && currentPage < pageCount - 2"
      @click="gotoPage(pageCount)"
      class="pagination-item"
      :class="{ active: pageCount === currentPage }"
    >
      {{ pageCount }}
    </button>

    <button
      class="pagination-item"
      @click="nextPage"
      :disabled="currentPage === pageCount"
    >
      <svg-icon icon="angle-right-solid" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      default: 50,
    },
  },
  computed: {
    pageCount() {
      return Math.ceil(this.totalPages / this.perPage);
    },
    visiblePages() {
      let visiblePageNumbers = [];

      if (this.pageCount <= 4) {
        visiblePageNumbers = Array.from({ length: this.pageCount }).map(
          (val, idx) => idx + 1
        );
      }

      if (this.pageCount > 4) {
        const secondPrevPage = this.currentPage - 2;
        const prevPage = this.currentPage - 1;
        const nextPage = this.currentPage + 1;
        const secondNextPage = this.currentPage + 2;

        const showSecondPrevPage = secondPrevPage > 0;
        const showPrevPage = prevPage > 0;
        const showNextPage = nextPage <= this.pageCount;
        const showSecondNextPage = secondNextPage <= this.pageCount;

        visiblePageNumbers = [
          showSecondPrevPage && secondPrevPage,
          showPrevPage && prevPage,
          this.currentPage,
          showNextPage && nextPage,
          showSecondNextPage && secondNextPage,
        ];

        visiblePageNumbers = visiblePageNumbers.filter(Boolean);
      }

      return visiblePageNumbers;
    },
  },
  methods: {
    gotoPage(pageNumber) {
      this.$emit("page-change", pageNumber);
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.$emit("page-change", this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.pageCount) {
        this.$emit("page-change", this.currentPage + 1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pagination-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  outline: none;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(160, 190, 221, 0.4);
  border-radius: 4px;
  border: none;
  margin-right: 4px;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--neutral-800);
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 767px) {
    width: 30px;
    height: 30px;
  }

  .svg-icons {
    font-size: 12px;
    margin-top: 3px;
  }
}

.pagination-item:disabled {
  color: #909bb6;
  cursor: not-allowed;
}

.pagination-item:last-child {
  margin-right: 0;
}

.pagination-item:not(:disabled):hover {
  background: var(--primary-default);
  box-shadow: 0px 8px 48px 0px rgba(184, 186, 229, 0.16);
  color: #ffffff;
}

.pagination-item.active {
  background: var(--primary-default);
  box-shadow: 0px 8px 48px 0px rgba(184, 186, 229, 0.16);
  color: #ffffff;
}

.dots {
  color: var(--neutral-800, #505660);
  text-align: center;
  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0 4px;
}
</style>
