export const MIN_COMPETITORS_FOR_RECOMMENDED = 10;

export function getCounter(dateCounters, title, parentTitle) {
    let count = null;
    switch (title) {
        case 'Tracking':
            count = dateCounters?.trackingKeywords?.total?.value;
            break;
        case 'Recommended':
            count = dateCounters?.competitors?.selectedCount >= MIN_COMPETITORS_FOR_RECOMMENDED ? dateCounters?.recommendedKeywords?.all?.value : 0;
            break;
        case 'Metadata':
            if (parentTitle === 'Keywords') {
                count = dateCounters?.metaKeywords?.total?.value;
            }
            break;
        case 'Ranking':
            count = dateCounters?.rankingKeywords?.all?.value;
            break;
        case 'Selected':
            count = dateCounters?.competitors?.selectedCount;
            break;
    }

    if (count !== null) {
        count = this.setStringFormatting(count);
    }

    return count;
}
