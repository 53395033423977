export default class MultiselectFilterValue {
    // item format [{value: foo, label: bar}, ...]
    currentValue = [];
    initValue = [];
    items = [];
    isAlwaysChanged = false;

    constructor(items = [], initValue = null) {
        this.items = items;
        this.initValue = initValue ?? items;
        this.currentValue = [...this.initValue];
    }

    clone() {
        let newObj = new MultiselectFilterValue();
        newObj.currentValue = [...this.currentValue];
        newObj.initValue = [...this.initValue];
        newObj.items = [...this.items];
        newObj.isAlwaysChanged = this.isAlwaysChanged;
        return newObj;
    }

    collectedData(key) {
        let result = {};
        this.currentValue.forEach((item, index) => {
            result['filter[' + key + '][list][' + index + ']'] = item.value;
        });
        return result;
    }

    reset() {
        this.currentValue = [...this.initValue];
    }

    setAlwaysChanged(value = true) {
        this.isAlwaysChanged = value;
    }

    isChanged() {
        return this.currentValue.map((item) => item.value).sort().join('|') !== this.initValue.map((item) => item.value).sort().join('|');
    }

    setSelectedValues(values) {
        this.currentValue = this.items.filter((item) => values.includes(item.value));
    }

    getSelectedValues() {
        return this.currentValue.map((item) => item.value);
    }

    getValues() {
        return this.items.map((item) => item.value);
    }
}