<template>
  <header class="site-header main">
    <div class="new-container">
      <div class="header-content">
        <div class="left-part">
          <slot name="header-left">
            <router-link :to="{name: 'MyApps'}"
               class="site-logo">
              <img src="@/assets/images/aso-logo-header-new.svg"
                   width="138"
                   height="32"
                   class="logo-icon full-logo"
                   alt="Site logo"/>
            </router-link>
          </slot>
        </div>
        <slot name="header-right">
          <div class="right-part">
            <header-user-details :user-data="userData"
                                 @userLogoutClicked="userLogoutClicked"/>

            <header-burger :user-data="userData"
                           @userLogoutClicked="userLogoutClicked"/>
          </div>
        </slot>
      </div>
    </div>
  </header>

</template>

<script>
import {mapGetters} from 'vuex';
import {httpRequest} from "@/api";
import {deleteCookie} from "@/utils/utils";
import HeaderUserDetails from '@/components/Dropdowns/HeaderUserDetails/index';
import HeaderSearch from '@/components/Dropdowns/HeaderSearch/index';
import HeaderBurger from '@/components/Dropdowns/HeaderBurger/index';

export default {
  name: "TheHeader",
  components: {
    'header-user-details': HeaderUserDetails,
    'header-search': HeaderSearch,
    'header-burger': HeaderBurger,
  },
  methods: {
    searchInputChanged(e) {
    },
    async userLogoutClicked() {
      await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.user.LOGOUT);
      deleteCookie('token');
      deleteCookie('currentAppId');
      this.$store.dispatch('SET_TOKEN', null);
      // this.$router.push('/');
      location.reload();
    }
  },
  computed: {
    ...mapGetters([
      'userData',
    ]),
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>