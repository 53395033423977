export default {
    SET_TOKEN(state, payload) {
        state.token = payload;
    },
    SET_USER_DATA(state, payload) {
        state.userData = {...payload};
    },
    SET_USER_AVATAR(state, payload) {
        state.userData.avatar = payload;
    },
    TRIGGER_SIDEBAR(state, payload) {
        state.sidebarIsCollapsed = payload;
    },
    SET_IS_MOBILE(state, payload) {
        state.isMobile = payload;
    },
    SET_IS_TABLET(state, payload) {
        state.isTablet = payload;
    },
    SET_CURRENT_APP(state, payload) {
        state.currentApp = {...payload};
    },
    SET_USER_LIMITS_ERRORS(state) {
        state.userLimitsError++;
    },
    INVOKE_USER_LIMITS_MANUAL(state) {
        state.userLimitsManualChanged++;
    },
    SET_USER_CANCEL_TOKEN_SOURCE(state, payload) {
        state.cancelTokenSource = payload;
    },
    SET_USER_LIMITS(state, payload) {
        if (payload !== null) {
            state.userLimits = {...payload};
        }
    },
    UPDATE_USER_LIMITS(state) {
        state.updateUserLimits++;
    },
    SET_PLANS_LIMITS(state, payload) {
        if (payload !== null) {
            state.plansLimits = {...payload};
        }
    },
    SET_CURRENT_APP_ID(state, payload) {
        // state.currentAppId = {...payload};
        state.currentAppId = payload;
    },
    SET_CURRENT_APP_RELOADED(state, payload) {
        state.currentAppReloaded = payload;
    },
    SET_MY_APPS(state, payload) {
        state.myApps = {...payload};
    },
    SET_RECENT_APPS(state, payload) {
        state.recentApps = [...payload];
    },
    SET_PAGINATION_META(state, payload) {
        state.pageMeta = {...payload};
    },
    FETCH_PROMISE_ALL_SETTLED(state, payload) {
        state.promiseSettledData = {...payload};
    },
    SET_CURRENT_COUNTRY(state, payload) {
        state.currentCountry = {...payload};
    },
    SET_CURRENT_COUNTRY_MANUAL(state, payload) {
        state.currentCountryManual = {...payload};
    },
    SET_COUNTRY_LIST(state, payload) {
        if (!payload.hasOwnProperty('errors')) {
            state.countryList = [...payload];
        } else {
            state.countryList = [];
        }
    },
    SET_COUNTRY_LOADING(state, payload) {
        state.countryLoading = payload;
    },
    INIT_LAYOUT_LOADED(state, payload) {
        state.initLayoutLoaded = payload;
    },
    SET_DATA_COUNTS(state, payload) {
        state.dataCounts = {...payload};
    },
    SET_DATA_COUNTS_LOADER(state, payload) {
        state.dataCountsLoaded = payload;
    },
    SET_COUNTRIES_LIST(state, payload) {
        state.countriesList = [...payload];
    },
    GLOBAL_LOAD_FINISHED(state, payload) {
        state.globalLoadFinished = payload;
    },
    SET_USER_SUBSCRIPTION(state, payload) {
        state.userSubscription = {...payload};
    },
    MOBILE_HEADER_OPENED(state, payload) {
        state.mobileHeaderOpened = payload;
    },
    SHOW_OVERHEAD_MODAL(state, payload) {
        state.showOverheadModal++;
    },
    SET_ABSOLUTE_DROPDOWN_ID(state, payload) {
        state.absoluteDropdownOpenedId = payload;
    },
    SET_EDITABLE_META(state, payload) {
        state.editableMetaData = {...payload};
    },
    INVOKE_CALL_TO_APP_ADD_ACTION(state, payload) {
        state.callToAddAppCounter++;
    },
};