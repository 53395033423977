import {createStore} from 'vuex';
import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';
import KeywordsTrackingModule from './modules/keywords-tracking/index.js';
import RankingKeywordsModule from './modules/ranking-keywords/index.js';
import RecommendedKeywordsModule from './modules/recommended-keywords/index.js';
import LiveSearchModule from './modules/live-search/index.js';
import AppRankingModule from './modules/app-ranking/index.js';
import MetaDataModule from './modules/meta-data/index.js';
import KeywordsMetaDataModule from './modules/keywords-meta-data/index.js';
import UncoveredKeywordsModule from './modules/uncovered-meta/index.js';
import MetadataKeywordsModule from './modules/metadata-keywords/index.js';
import Competitors from './modules/competitors/index';

export default createStore({
    state: {
        userData: {
            name: '',
            surname: '',
            email: 'example@example.mail',
            position_id: 1,
            plan: 'free',
            avatar: '/avatar.png',
        },
        token: null,
        userLimitsError: 0,
        userLimitsManualChanged: 0,
        initLayoutLoaded: false,
        countryLoading: false,
        sidebarIsCollapsed: false,
        isMobile: false,
        isTablet: false,
        currentApp: {},
        currentCountry: {},
        currentCountryManual: {},
        userLimits: null,
        plansLimits: null,
        currentAppId: null,
        currentAppReloaded: false,
        myApps: [],
        recentApps: [],
        pageMeta: {},
        countryList: [],
        promiseSettledData: [],
        updateUserLimits: 0,
        dataCounts: {},
        dataCountsLoaded: false,
        cancelTokenSource: null,
        countriesList: [],
        globalLoadFinished: null,
        userSubscription: {},
        mobileHeaderOpened: false,
        showOverheadModal: 0,
        absoluteDropdownOpenedId: false,
        callToAddAppCounter: 0,
    },
    modules: {
        keywordsTracking: KeywordsTrackingModule,
        rankingKeywords: RankingKeywordsModule,
        recommendedKeywords: RecommendedKeywordsModule,
        liveSearch: LiveSearchModule,
        appRanking: AppRankingModule,
        metaData: MetaDataModule,
        keywordsMetaData: KeywordsMetaDataModule,
        uncoveredKeywordsMeta: UncoveredKeywordsModule,
        metadataKeywords: MetadataKeywordsModule,
        competitors: Competitors,
    },
    mutations,
    actions,
    getters,
})
