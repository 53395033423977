import {createRouter, createWebHistory} from 'vue-router';
import {agentIsMobile, agentIsTablet, getCookie, setCookie} from "@/utils/utils";
import store from '../store/index';
import {routes} from "@/router/routes";

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

function overheadType() {
    if (store.getters.userSubscription?.has_overhead_limits && (store.getters.userLimits?.max_apps?.used > store.getters.userLimits?.max_apps?.total)) {
        return 'appsOverhead';
    } else if (store.getters.userSubscription?.has_overhead_limits && (store.getters.userLimits?.max_keywords?.used > store.getters.userLimits?.max_keywords?.total)) {
        return 'keywordsOverhead';
    } else if (store.getters.userSubscription?.has_overhead_limits && (store.getters.userLimits?.max_competitors?.used > store.getters.userLimits?.max_competitors?.total)) {
        return 'competitorsOverhead';
    } else {
        return false;
    }
}

const DEFAULT_TITLE = 'ASOlytics';
const TITLE_SUFFIX = ' - Asolytics App Marketing Tools';
router.beforeEach(async (to, from, next) => {
    document.title = (to.meta.pageTitle || DEFAULT_TITLE) + TITLE_SUFFIX;

    const token = getCookie('token');
    const currentAppId = getCookie('currentAppId');
    const sidebarCollapsed = localStorage.getItem('sidebarCollapsed');

    if (process.env?.VUE_APP_DOWN_ALL_503 === 'yes' && to.name !== 'Maintenance') {
        next({name: 'Maintenance'});
    } else if (process.env?.VUE_APP_DOWN_ALL_503 === 'yes' && to.name === 'Maintenance') {
        next();
    } else if (process.env?.VUE_APP_DOWN_ALL_503 === 'no' && to.name === 'Maintenance') {
        await store.dispatch('TRIGGER_SIDEBAR', sidebarCollapsed === 'true');
        await store.dispatch('SET_IS_MOBILE', agentIsMobile());
        await store.dispatch('SET_IS_TABLET', agentIsTablet());
        if (to.name !== 'Authorization' && !token && token !== 'undefined') {
            next({name: 'Authorization'});
        } else {
            next({name: 'MyApps'});
        }
    } else {
        await store.dispatch('TRIGGER_SIDEBAR', sidebarCollapsed === 'true');
        await store.dispatch('SET_IS_MOBILE', agentIsMobile());
        await store.dispatch('SET_IS_TABLET', agentIsTablet());

        if (to.name === 'ResetPassword') {
            next();
        } else if (to.name !== 'Authorization' && !token && token !== 'undefined') {
            next({name: 'Authorization'});
        } else if (store.state.userData.hasOwnProperty('is_confirmed_some_plan')
            && store.state.userData?.is_confirmed_some_plan === false
            && !currentAppId
            && to.name !== 'PlanSelectionPage'
            && to.name !== 'Profile'
            && to.name !== 'Authorization') {

            next({name: 'PlanSelectionPage'});
        } else if (store.state.userData.hasOwnProperty('is_confirmed_some_plan')
            && store.state.userData?.is_confirmed_some_plan === true
            && !currentAppId
            && to.name !== 'AddFirstAppPage'
            && to.name !== 'Profile'
            && to.name !== 'Authorization') {
            store.dispatch('INVOKE_CALL_TO_APP_ADD_ACTION');

            next({name: 'AddFirstAppPage'});
        } else if (overheadType() === 'appsOverhead'
            && to.name !== 'MyApps'
            && to.name !== 'Profile'
            && to.name !== 'Authorization') {

            next({name: 'MyApps'});
        } else if ((overheadType() !== false)
            && to.name !== 'MyApps'
            && to.name !== 'KeywordsTracking'
            && to.name !== 'Competitors'
            && to.name !== 'MyCompetitors'
            && to.name !== 'IndirectCompetitors'
            && to.name !== 'NonCompetitors'
            && to.name !== 'Profile'
            && to.name !== 'Authorization') {

            next({name: 'MyApps'});
        } else if (to.meta.requiresSelectedApp && !currentAppId) {
            store.dispatch('INVOKE_CALL_TO_APP_ADD_ACTION');

            next({name: 'MyApps'});
        } else {
            if (to?.query.hasOwnProperty('beta-testing') && to.query['beta-testing'] === 'cfe42536b42297a656de91055660fac') {
                const betaTesterCookie = getCookie('betaTesterCookie');
                const cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN;
                const days = 365;

                if (!betaTesterCookie) {
                    setCookie('betaTesterCookie', true, cookieDomain, days);
                }

                window.location.href = '/';
            } else {
                next();
            }
        }
    }
});

export default router;