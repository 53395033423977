export default class App {
    _id = null;
    _origin_id = null;
    _store = null;
    _locale_info = null;

    constructor(id, origin_id, store) {
        this._id = id;
        this._origin_id = origin_id;
        this._store = store;
    }

    set locale_info(value) {
        this._locale_info = value;
    }

    get id() {
        return this._id;
    }

    get origin_id() {
        return this._origin_id;
    }

    get store() {
        return this._store;
    }

    get title() {
        return this._locale_info?.name || '';
    }

    get logo() {
        return this._locale_info?.logo || '';
    }

    get storeLink() {
        return this.store.key === 'GOOGLE_PLAY' ? `https://play.google.com/store/apps/details?id=${this._origin_id}` : `https://apps.apple.com/app/id${this._origin_id}`;
    }

    mutateToModalAppFormat() {
        return {
            id: this.id,
            title: this.title,
            store: this.store.key,
            logo: this.logo,
        };
    }
}