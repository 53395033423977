<template>
  <div class="custom-input-counter"
       :style="{maxWidth: customMaxWidth}">
    <input type="number"
           class="input-number"
           @keydown="isNumber($event)"
           v-model="inputValue">

    <div class="sort-icons-block">
      <div class="counter-item">
        <svg-icon icon="angle-right"
                  @click="incrementValue"
                  class="icon icon-first"/>
      </div>
      <div class="counter-item">
        <svg-icon icon="angle-left"
                  @click="decrementValue"
                  class="icon"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomInputCounter",
  props: {
    inputData: {
      type: [Number, String]
    },
    min: {
      type: [Number, String],
      default: 0
    },
    max: {
      type: [Number, String],
      default: 100000000
    },
    customMaxWidth: {
      type: String,
      default: '64px',
    },
  },
  emits: ['input-counter-changed-outside', 'input-counter-changed', 'show-clear-button-changed'],
  data() {
    return {
      inputValue: 0,

      isFloat: false,
    }
  },
  created() {
    this.isFloat = Number(this.inputData) !== this.inputData;
  },
  methods: {
    isNumber(e) {
      e = (e) ? e : window.event;

      let charCode = (e.which) ? e.which : e.keyCode;

      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 188 && charCode !== 190 && charCode !== 37 && charCode !== 39 && charCode !== 191) {
        e.preventDefault();
      } else {

        if (this.inputValue === 0 && e.key === '0') {
          e.preventDefault();
        }

        setTimeout(() => {
          this.inputChanged();
          this.$emit('show-clear-button-changed', true);
          return true;
        }, 150);
      }
    },
    inputChanged() {
      if (+this.inputValue > this.max) {
        this.inputValue = this.max;
      }

      this.$emit('input-counter-changed', {
        value: this.inputValue,
        type: 'manual'
      });

      this.$emit('show-clear-button-changed', true);
    },
    incrementValue() {
      let tmp = Number(this.inputValue);
      if (!this.isFloat) {
        tmp = tmp + 1;
      } else {
        tmp = tmp + 0.1;
      }

      this.inputValue = this.normalVal(tmp);

      this.$emit('input-counter-changed', {
        value: this.inputValue,
        type: 'manual'
      });

      this.$emit('show-clear-button-changed', true);
    },
    decrementValue() {
      let tmp = Number(this.inputValue);
      if (!this.isFloat) {
        tmp = tmp - 1;
      } else {
        tmp = tmp - 0.1;
      }

      this.inputValue = this.normalVal(tmp);

      this.$emit('input-counter-changed', {
        value: this.inputValue,
        type: 'manual'
      });

      this.$emit('show-clear-button-changed', true);
    },
    normalVal(val) {
      val = Number(val);

      if (val > this.max) {
        val = this.max;
      } else if (val < this.min) {
        val = this.min;
      }

      if (val < 0) {
        val = 0;
      }

      if (val % 1 !== 0) {
        val = val.toFixed(2);
      }

      return val;
    }
  },
  mounted() {
    if (this.inputData) {
      this.inputValue = this.inputData;
    }
  },
  computed: {},
  watch: {
    inputData(newVal, oldVal) {
      // if (newVal !== oldVal && oldVal !== 0) {
      if (newVal !== oldVal) {
        this.$emit('input-counter-changed', {
          value: newVal,
          type: 'outside-watcher'
        });

        this.inputValue = newVal;
      }
    },
  }
}
</script>

<style scoped src="./styles.scss" lang="scss"></style>