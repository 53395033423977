<template>
  <div class="modal-forms">
    <div class="country-select sub-select">
      <custom-select-search @add-app-search-handler="addAppSearchCountryHandler"
                            @country-selected="countrySelectHandler"
                            no-active-border
                            :min-result-length="0"
                            :min-input-length="0"
                            :initial-data="countryInitial"
                            :search-results="searchCountryResults">
        <template v-slot:header>Select country</template>
      </custom-select-search>
    </div>
    <div class="app-search-form">
      <basic-search-input placeholder="Search for my apps"
                          @search-input-changed="addAppSearchHandler"
                          :search-results="searchResults"
                          :is-focused-initially="true"
                          class="add-app-country-search"
                          :input-disabled="!addedCountry?.code">
        <template v-slot:search-icon>
          <svg-icon class="search-icon"
                    icon="search-solid"/>
        </template>
        <template v-slot:results="results">

          <div class="result-item add-item-block"
               v-if="searchResults.result.length > 0"
               v-for="result in results.result"
               v-on="!isIncluded(result?.id) ? {click: () => addAppHandler(result)}: {}">

            <div class="left-part">
              <div class="product-type">
                <svg-icon :icon="result?.store_key === 'GOOGLE_PLAY' ? 'google-play' : 'app-store'"/>
              </div>

              <template v-if="result?.logo">
                <div class="product-logo">
                  <app-image
                      :width="22"
                      :height="22"
                      alt="App image"
                      :lazy-src="result?.logo"
                  />
                </div>
              </template>
              <template v-else>
                <div class="empty-logo"></div>
              </template>


              <div class="product-name">{{ result?.title ?? '' }}</div>
              <div class="product-category"
                   :title="result?.developer_name ?? ''">{{ result?.developer_name ?? '' }}
              </div>
            </div>

            <div class="add-app-button">
              <svg-icon v-if="isIncluded(result?.id)"
                        class="included"
                        icon="check-solid"/>
              <svg-icon v-else
                        icon="plus"/>
            </div>
          </div>
          <div v-else
               class="no-results">
            We couldn't find what you are looking for
          </div>
        </template>
      </basic-search-input>
    </div>
  </div>
</template>

<script>
import BasicSearchInput from '@/components/UI/BasicSearchInput/index';
import CustomSelectWithSearch from '@/components/Forms/CustomSelectWithSearch/index';
import Accordion from '@/components/UI/Accordion/index';
import {httpRequest} from "@/api";
import {getFlagEmoji, cropImage, sanitizeString} from "@/utils/utils";
import {mapGetters} from "vuex";
import myApps from "../../../../api/modules/my-apps";

export default {
  name: "AddAppModalForm",
  components: {
    'basic-search-input': BasicSearchInput,
    'custom-accordion': Accordion,
    'custom-select-search': CustomSelectWithSearch,
  },
  emits: ['add-app-click-handler'],
  data() {
    return {
      searchResults: {},
      searchCountryResults: {},
      addedCountry: {},
      countryInitial: null,
      fullCountriesList: [],
    }
  },
  async created() {
    this.addedCountry = localStorage.getItem('addedCountry') ? JSON.parse(localStorage.getItem('addedCountry')) : {
      code: 'US',
      name: 'United States'
    };
    if (this.addedCountry) {
      this.countryInitial = `${this.flagEmoji(this.addedCountry.code)} ${this.addedCountry.name}`;
    }

    await this.addAppSearchCountryHandler();
  },
  methods: {
    async addAppSearchHandler(value) {
      if (value?.length > 2) {
        this.searchResults = await myApps.searchApps(value, this.addedCountry?.code);
      } else {
        this.searchResults = {};
      }
    },
    async addAppSearchCountryHandler(value) {
      const queryString = value?.length >= 0 ? '?query=' + value : null;
      if (queryString) {
        const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.SEARCH_LOCALES + queryString);
        this.searchCountryResults = {result: result?.countries?.filter(item => item?.code !== 'WORLDWIDE')};
      } else {

        const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.SEARCH_LOCALES);
        this.searchCountryResults = {result: result?.countries?.filter(item => item?.code !== 'WORLDWIDE')};
      }

      if (this.fullCountriesList?.length === 0) {
        this.fullCountriesList = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.SEARCH_LOCALES_WITH_ID);
      }
    },
    countrySelectHandler(value) {
      this.addedCountry = value;
    },
    addAppHandler(value) {
      localStorage.setItem('addedCountry', JSON.stringify(this.addedCountry));
      this.$emit('add-app-click-handler', {
        application_id: value?.id,
        country_code: this.addedCountry?.code,
        fullCountriesList: this.fullCountriesList
      });
    },
    flagEmoji(val) {
      return getFlagEmoji(val);
    },
    isIncluded(id) {
      if (!this.myApps) {
        return false;
      }
      for (const key in this.myApps) {
        if (this.myApps[key]?.id === id) {
          return true;
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      'myApps',
    ]),
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
