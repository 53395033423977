<script>
import CustomInlineSearch from "../../Forms/CustomInlineSearch/index.vue";
import {httpRequest} from "../../../api";
import {UserAppsArrayCreator} from "../../../utils/factories";
import {cropImage} from "../../../utils/utils";
import PreloaderTableSmall from "../PreloaderTableSmall/index.vue";
import {mapGetters} from "vuex";

export default {
  name: "AppsSelect",
  components: {PreloaderTableSmall, CustomInlineSearch},
  props: {
    selectedApp: {
      type: Object,
      default: () => null,
    },
    //TODO пока добавил так. это связано с трудностями обновления выбранной прилки в кейсе пеового добавления
    globalMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['app-selected'],
  data() {
    return {
      appSearchInput: '',
      appsList: [],
      currentSelectedApp: null,
      appListLoading: false,
      appsCloseCounter: 0,
    }
  },
  beforeMount() {
    this.currentSelectedApp = this.selectedApp ?? this.currentApp;
  },
  methods: {
    cropImage,
    appsInputChanged(e) {
      this.appSearchInput = e;
    },
    appsDropdownClosed() {
      this.appSearchInput = '';
    },
    appsDropdownOpened() {
      this.fetchAppsData();
    },
    async fetchAppsData() {
      this.appListLoading = true;
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS_NEW);
      this.appsList = UserAppsArrayCreator(result);
      this.appListLoading = false;
    },
    appClickHandler(app) {
      this.appsCloseCounter++;
      this.currentSelectedApp = app;
      this.$emit('app-selected', app);
      this.appSearchInput = '';
    },
  },
  computed: {
    showedApp() {
      return this.globalMode ? this.selectedApp : this.currentSelectedApp;
    },
    ...mapGetters([
        'currentApp'
    ]),
    filterAppInSearch() {
      const inputLowered = this.appSearchInput?.toLowerCase();
      return this.appsList?.filter(item => item?.title?.toLowerCase()?.includes(inputLowered));
    },
  },
}
</script>

<template>
  <div class="app-search">
    <custom-inline-search placeholder=""
                          @search-input-changed="appsInputChanged"
                          @dropdown-opened="appsDropdownOpened"
                          @dropdown-closed="appsDropdownClosed"
                          :with-result-slots="true"
                          :min-input-length="0"
                          :show-caret="true"
                          :full-width-content="true"
                          results-max-height="256px"
                          :initial-data="'initial app name'"
                          :close-from-outside-counter="appsCloseCounter"
                          :search-results="appsList">

      <template v-slot:heading-info>
        <div class="app-details">
          <div class="app-logo">
            <img :src="cropImage(showedApp.logo, '24x24', showedApp?.store?.key)"
                 width="20"
                 height="20"
                 alt="App logo">
          </div>
          <div class="app-name">{{ showedApp?.title ?? '' }}</div>
          <template v-if="showedApp?.store?.key">
            <img v-if="showedApp?.store?.key === 'APP_STORE'"
                 src="@/assets/images/icons/app_store.png"
                 alt="Store icon"
                 class="store-icon"
                 width="20"
                 height="20">
            <img v-else
                 src="@/assets/images/icons/google_play.png"
                 alt="Store icon"
                 class="store-icon"
                 width="20"
                 height="20">
          </template>
        </div>
      </template>

      <template v-slot:results>
        <template v-if="appListLoading">
          <div class="preloader-wrap">
            <preloader-table-small :loader-size="'40px'"></preloader-table-small>
          </div>
        </template>
        <template v-else-if="!appListLoading && filterAppInSearch?.length === 0">
          <div class="results-group">
            <div class="text">No data found</div>
          </div>
        </template>
        <template v-else>
          <div v-for="app in filterAppInSearch"
               @click="appClickHandler(app)"
               class="result-item">
            <div class="item-logo">
              <img :src="cropImage(app?.logo, '22x22', app?.store?.key)"
                   :width="22"
                   :height="22"
                   alt="App image"
              />
            </div>
            <span class="item-name">{{ app?.title ?? '' }}</span>

            <div class="item-store">
              <template v-if="app?.store?.key">
                <img v-if="app?.store?.key === 'APP_STORE'"
                     src="@/assets/images/icons/app_store.png"
                     alt="Store icon"
                     class="store-icon"
                     width="16"
                     height="16">
                <img v-else
                     src="@/assets/images/icons/google_play.png"
                     alt="Store icon"
                     class="store-icon"
                     width="16"
                     height="16">
              </template>
            </div>
          </div>
        </template>
      </template>
    </custom-inline-search>
  </div>
</template>

<style lang="scss">
.app-search {
  max-width: 340px;
  width: 100%;

  .preloader-wrap {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .select-styled {
    height: 36px!important;
    width: 100% !important;
    border-radius: 4px;
    border: solid 1px var(--neutral-500);
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    text-align: left;
    color: var(--neutral-900);
    padding: 0 16px;
  }

  .selected-value {
    border-radius: 4px;
    border: solid 1px var(--neutral-500);
    padding: 0 16px;

    .app-name {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(100% - 70px)
    }
  }

  .icon .svg-icons {
    font-size: 18px;
    margin-bottom: 4px;
  }

  .select-options-wrap {
    padding: 0;
    border-radius: 4px;
    border: solid 1px #fff;
  }

  .result-item {
    height: 38px;
    display: flex;
    align-items: center;
    padding: 0 40px 0 16px;
    transition: background-color .3s;
    cursor: pointer;
    position: relative;
  }

  .result-item:hover {
    background-color: #f5f8fd;
  }

  .item-logo {
    margin-right: 8px;
    height: 22px;
  }

  .item-name {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    color: var(--neutral-800);
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .item-store {
    position: absolute;
    top: 6px;
    right: 16px;
    height: 16px;
  }

  .app-details {
    display: flex;
    align-items: center;
    width: 100%;

    .app-logo {
      display: flex;
      margin-right: 6px;
    }

    .store-icon {
      margin-left: 5px;
    }
  }
}
</style>