<template>
  <img
      :data-src="lazySrc"
      :data-srcset="lazySrcset"
      :style="style"
      :width="width"
      :height="height"
      :decoding="decoding"
      class="app-image"
  >
</template>

<script>
import lozad from 'lozad';

export default {
  name: 'AppImage',
  props: {
    backgroundColor: {
      type: String,
      default: 'transparent',
      // default: '#efefef',
    },
    height: {
      type: Number,
      default: null,
    },
    lazySrc: {
      type: String,
      default: null,
    },
    lazySrcset: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: null,
    },
    decoding: {
      type: String,
      default: "sync"
    },
    noAspectRatio: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    aspectRatio() {
      if (!this.width || !this.height) return null;
      return (this.height / this.width) * 100;
    },
    style() {
      const style = { backgroundColor: this.backgroundColor };
      if (this.width) style.width = `${this.width}px`;
      const applyAspectRatio = this.loading && this.aspectRatio;
      if (!this.noAspectRatio && applyAspectRatio) {
        style.height = 0;
        style.paddingTop = `${this.aspectRatio}%`;
      }

      return style;
    },
  },
  mounted() {
    const setLoadingState = () => {
      this.loading = false;
    };
    this.$el.addEventListener('load', setLoadingState);
    const observer = lozad(this.$el);
    observer.observe();
  },
};
</script>

<style lang="scss">
.app-image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  vertical-align: middle;
}
</style>