<template>
  <div class="voted">
    <template v-if="isShortenDigits">{{ shortenDigits(voted) }}</template>
    <template v-else>{{ voted }}</template>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import ProgressBar from "@/components/UI/ProgressBar/index.vue";
import {shortenDigits} from "@/utils/utils";

export default defineComponent({
  name: "VotedTool",
  methods: {shortenDigits},
  components: {
    ProgressBar,
  },
  props: {
    isShortenDigits: {
      type: Boolean,
      default: false,
    },
    voted: {
      type: Number,
    },
  },
})
</script>

<style scoped lang="scss">

</style>