export default {
    SET_SUGGESTED_KEYWORDS(state, payload) {
        state.suggestedKeywords = payload;
    },
    SET_SORTING_META(state, payload) {
        state.sortingMeta = {...payload};
    },
    SET_URL_QUERY_STRING(state, payload) {
        state.urlQueryString = payload;
    },
    SET_LIVE_SEARCH_RESULTS(state, payload) {
        state.liveSearchResults = {...payload};
    },
    SET_POPULARITY_CHART_RESULTS(state, payload) {
        state.popularityChartResults = {...payload};
    },
    SET_CURRENT_KEYWORD(state, payload) {
        state.inputCurrentKeyword = payload;
    },
};