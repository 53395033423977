<template>
  <template v-if="!linkUrl">
    <div class="custom-tooltip-component"
         :class="classes"
         ref="tooltip"
         @mouseover="tooltipMouseOver"
         @mouseleave="tooltipMouseLeave">

      <div class="slot-trigger" :class="{'allowPointEvents': allowPointEvents}">
        <slot name="slotTrigger"></slot>
      </div>
      <teleport to="body">
          <div class="tooltip-content"
               :class="classes"
               ref="tooltipContent"
               @mouseover="tooltipContentMouseOver"
               @mouseleave="tooltipContentMouseLeave"
               :style="[tooltipVerticalPosition, tooltipHorizontalPosition, {width: width}, {textAlign: textAlign}, {'padding': withPadding ? '4px 8px' : 0}]"
               v-if="showTooltipContent">
            <div class="tooltip-content-inner"
                 :style="style ?? ''">
              <slot name="slotContent"></slot>
            </div>
          </div>
      </teleport>
    </div>
  </template>
  <template v-else>
    <a class="custom-tooltip-component"
       :class="classes"
       ref="tooltip"
       :href="linkUrl"
       target="_blank"
       @mouseover="tooltipMouseOver"
       @mouseleave="tooltipMouseLeave">

      <div class="slot-trigger" :class="{'allowPointEvents': allowPointEvents}">
        <slot name="slotTrigger"></slot>
      </div>
      <teleport to="body">
        <transition name="tooltip-fade">
          <div class="tooltip-content"
               ref="tooltipContent"
               :style="[tooltipVerticalPosition, tooltipHorizontalPosition, {width: width}, {textAlign: textAlign}]"
               v-if="showTooltipContent">
            <div class="tooltip-content-inner"
                 :style="style ?? ''">
              <slot name="slotContent"></slot>
            </div>
          </div>
        </transition>
      </teleport>
    </a>
  </template>
</template>

<script>
export default {
  name: "Tooltip",
  data() {
    return {
      showTooltipContent: false,
      directVertical: null,
      directHorizontal: null,
      boundingRect: {}
    }
  },
  props: {
    allowPointEvents: {
      type: Boolean,
      default: false
    },
    style: {
      type: Object,
    },
    width: {
      type: String,
      default: '92px'
    },
    maxHeight: {
      type: String,
      required: false,
    },
    classes: {
      type: String,
      default: ''
    },
    textAlign: {
      type: String,
      default: 'left'
    },
    directionVertical: {
      type: String,
      default: 'top'
    },
    directionHorizontal: {
      type: String,
      default: 'right'
    },
    straight: {
      type: Boolean,
      default: false
    },
    linkUrl: {
      type: String,
      required: false
    },
    withPadding: {
      type: Boolean,
      default: false
    },
    delayedClosing: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    this.directVertical = this.directionVertical;
    this.directHorizontal = this.directionHorizontal;
  },
  methods: {
    tooltipMouseOver(e) {
      this.showTooltipContent = true;
      e.stopPropagation();
      const rect = e.target.getBoundingClientRect();
      this.boundingRect.top = rect.top + window.scrollY;
      this.boundingRect.bottom = rect.bottom + window.scrollY;
      this.boundingRect.left = rect.left;
      this.boundingRect.right = rect.right;

      this.$nextTick(() => {
        const tooltipContent = this.$refs.tooltipContent;
        if (tooltipContent) {
          this.boundingRect.height = tooltipContent.clientHeight;
          this.boundingRect.width = tooltipContent.clientWidth;
          const clientRect = tooltipContent.getBoundingClientRect();

          if (this.directVertical === 'top' && clientRect.top < 0) {
            this.directVertical = 'bottom';
          }

          if (this.directHorizontal === 'right' && window.innerWidth <= clientRect.right) {
            if ((clientRect.right - (this.boundingRect.width / 2)) > window.innerWidth) {
              this.directHorizontal = 'left';
            } else {
              this.directHorizontal = 'center';
            }
          } else if (this.directHorizontal === 'center' && window.innerWidth <= (clientRect.right + (this.boundingRect.width / 2))) {
            this.directHorizontal = 'center';
          } else if (this.directHorizontal === 'left') {

          } else {
            this.directHorizontal = 'right';
          }
        }
      })
    },
    tooltipMouseLeave() {
      this.showTooltipContent = false;
    },
    tooltipContentMouseOver() {
      if (this.delayedClosing) {
        this.showTooltipContent = true;
      }
    },
    tooltipContentMouseLeave() {
      this.showTooltipContent = false;
    }
  },
  computed: {
    tooltipVerticalPosition() {
      const tooltip = this.$refs.tooltip;

      if (this.directVertical === 'top') {
        return `top: calc(${this.boundingRect.top}px - 4px - ${this.boundingRect.height ?? 0}px)`;
      } else {
        let positionTop = '';
        if ((this.boundingRect.top < parseInt(this.maxHeight)) && (window.innerHeight - this.boundingRect.top) < parseInt(this.maxHeight)) {
          positionTop = `calc(${this.boundingRect.top}px - 4px - ${this.boundingRect.height / 2 ?? 0}px)`;
        } else if ((window.innerHeight - this.boundingRect.top) <= parseInt(this.maxHeight)) {
          let scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;

          if ((this.boundingRect.top - scrollTop + 80) < this.boundingRect.height) {
            positionTop = `calc(${this.boundingRect.top}px - 4px)`;
          } else {
            positionTop = `calc(${this.boundingRect.top}px + 4px - ${this.boundingRect.height ?? 0}px + ${tooltip.clientHeight}px)`;
          }

        } else {
          positionTop = `calc(${this.boundingRect.top}px + ${tooltip.clientHeight}px + 4px)`;
        }
        return `top: ${positionTop}`;
      }
    },
    tooltipHorizontalPosition() {
      const parsedWidth = parseInt(this.width);
      const padding = this.withPadding ? 0 : 4;
      if (this.directHorizontal === 'right' && this.straight) {
        return `left: calc(${this.boundingRect.left}px);`;
      } else if (this.directHorizontal === 'left' && this.straight) {
        return `left: calc(${this.boundingRect.left}px - ${padding}px - ${parsedWidth}px`;
      } else if (this.directHorizontal === 'center') {
        return `left: calc(${this.boundingRect.left}px - ${parsedWidth / 2}px);`;
      } else if (this.directHorizontal === 'right' && !this.straight) {
        return `left: calc(${this.boundingRect.left}px;`;
      } else {
        return `left: calc(${this.boundingRect.left}px - ${parsedWidth}px);`;
      }
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>