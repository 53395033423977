<template>
  <div class="configure-columns-modal">
    <div class="columns">
      <label v-for="column in localColumnsData" :for="column.id">
        <input type="checkbox"
               class="common-checkbox"
               :id="column.id"
               :value="column.selected"
               v-model="column.selected" />
        <span>
          {{ column.label }}
        </span>
      </label>
    </div>
    <div class="button">
      <base-button height="36px"
                   width="255px"
                   class="btn-standard"
                   border-radius="4px"
                   font-size="16px"
                   @click="apply">
        Apply
      </base-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ConfigureColumnsModal',
  emits: ['apply'],
  props: {
    allColumns: {
      type: Object,
      required: true,
    },
    configuredColumns: {
      type: Array,
    },
  },
  data() {
    return {
      localColumnsData: [],
    }
  },
  mounted() {
    this.setLocalColumnsData();
  },
  methods: {
    setLocalColumnsData() {
      const hasConfiguredColumns = this.configuredColumns && this.configuredColumns.length > 0;
      this.localColumnsData = Object.keys(this.allColumns).map((key) => {
        const isLanguageColumn = key === 'language';

        return {
          id: key,
          label: this.allColumns[key].alt ?? this.allColumns[key].header,
          selected: isLanguageColumn
              ? hasConfiguredColumns ? this.configuredColumns.includes(key) : false
              : hasConfiguredColumns ? this.configuredColumns.includes(key) : true,
        };
      });
    },
    apply() {
      const selectedColumns = this.localColumnsData.filter((column) => column.selected).map((column) => column.id);
      this.$emit('apply', selectedColumns);
    }
  },
  watch: {
    configuredColumns() {
      this.setLocalColumnsData();
    },
    allColumns() {
      this.setLocalColumnsData();
    }
  }
}
</script>
<style>
.configure-columns-modal {
  .columns {
    label {
      display: flex;
      cursor: pointer;
      padding: 5px 0;
      margin-bottom: 4px;
      gap: 4px;

      &:hover {
        background-color: var(--neutral-200);
      }
    }
  }
  .button {
    margin-top: 20px;
  }
}
</style>
