export default {
    userData: state => {
        return state.userData;
    },
    token: state => {
        return state.token;
    },
    sidebarIsCollapsed: state => {
        return state.sidebarIsCollapsed;
    },
    isMobile: state => {
        return state.isMobile;
    },
    isTablet: state => {
        return state.isTablet;
    },
    currentApp: state => {
        return state.currentApp;
    },
    userLimits: state => {
        return state.userLimits;
    },
    plansLimits: state => {
        return state.plansLimits;
    },
    userLimitsError: state => {
        return state.userLimitsError;
    },
    userLimitsManualChanged: state => {
        return state.userLimitsManualChanged;
    },
    currentAppId: state => {
        return state.currentAppId;
    },
    myApps: state => {
        return state.myApps;
    },
    recentApps: state => {
        return state.recentApps;
    },
    currentAppReloaded: state => {
        return state.currentAppReloaded;
    },
    pageMeta: state => {
        return state.pageMeta;
    },
    promiseSettledData: state => {
        return state.promiseSettledData;
    },
    currentCountry: state => {
        return state.currentCountry;
    },
    currentCountryManual: state => {
        return state.currentCountryManual;
    },
    countryLoading: state => {
        return state.countryLoading;
    },
    initLayoutLoaded: state => {
        return state.initLayoutLoaded;
    },
    updateUserLimits: state => {
        return state.updateUserLimits;
    },
    countryList: state => {
        return state.countryList;
    },
    dataCounts: state => {
        return state.dataCounts;
    },
    dataCountsLoaded: state => {
        return state.dataCountsLoaded;
    },
    cancelTokenSource: state => {
        return state.cancelTokenSource;
    },
    countriesList: state => {
        return state.countriesList;
    },
    globalLoadFinished: state => {
        return state.globalLoadFinished;
    },
    userSubscription: state => {
        return state.userSubscription;
    },
    mobileHeaderOpened: state => {
        return state.mobileHeaderOpened;
    },
    showOverheadModal: state => {
        return state.showOverheadModal;
    },
    absoluteDropdownOpenedId: state => {
        return state.absoluteDropdownOpenedId;
    },
    editableMetaData: state => {
        return state.editableMetaData;
    },
    callToAddAppCounter: state => {
        return state.callToAddAppCounter;
    },
};