import {createApp, defineAsyncComponent} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import SvgSprite from '@/components/UI/SvgSprite/index.vue';
import SvgIcon from '@/components/UI/SvgIcon/index.vue';
import BaseButton from '@/components/UI/BaseButton/index';
import TheSidebar from '@/components/TheSidebar/index';
import TheHeader from '@/components/TheHeader/index';
import TheNewHeader from '@/components/TheNewHeader/index';
import DynamicImage from '@/components/UI/DynamicImage/index';
import {apiUrls} from '@/api/api';
import VueHighcharts from 'vue3-highcharts';
import {SetupCalendar} from 'v-calendar';
import VueEasyLightbox from 'vue-easy-lightbox';
import { createGtm } from 'vue-gtm';
import * as Sentry from "@sentry/vue";
import {httpRequest} from "@/api";
import {SplitArrays} from "@/utils/factories";
import {mapGetters} from "vuex";
import Sockets from "./api/modules/sockets";
import {TooltipDirective} from "./directives/tooltip";

const tooltip = defineAsyncComponent(() => import("@/components/UI/Tooltip"));
const CurrentApp = defineAsyncComponent(() => import("@/components/UI/CurrentApp/index"));
const AddEntityBlock = defineAsyncComponent(() => import("@/components/UI/AddEntityBlock/index"));
const Modal = defineAsyncComponent(() => import("@/components/UI/Modal/index"));
const AppImage = defineAsyncComponent(() => import("@/components/UI/ImageLazyLoad/index"));
const PreloaderTableSmall = defineAsyncComponent(() => import("@/components/UI/PreloaderTableSmall/index"));
const ProgressIndicator = defineAsyncComponent(() => import("@/components/UI/ProgressIndicator/index"));
const HeaderTabs = defineAsyncComponent(() => import("@/components/UI/HeaderTabs/index"));
const DetailsModal = defineAsyncComponent(() => import("@/components/AppDetailsComponent/DetailsModal/index.vue"));


window.axios = require('axios');
const app = createApp(App).use(store).use(router);

app.use(VueHighcharts);
app.use(SetupCalendar, {});
app.use(VueEasyLightbox);

if (process?.env?.NODE_ENV === 'production' && !window.location.href.includes('test-asolytics')) {
    app.use(
        createGtm({
            id: 'GTM-5WWHSWBT'
        }),
    );
}

app.config.globalProperties.$ApiUrls = apiUrls;

app.directive('click-outside', {
    beforeMount(el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});

app.directive('scroll', {
    beforeMount: function (el, binding) {
        let f = function (evt) {
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f)
            }
        }
        window.addEventListener('scroll', f)
    }
});

app.directive('tooltip', TooltipDirective )

app.mixin({
    methods: {
        openModal(modalName) {
            this[modalName] = true;
        },
        closeModal(modalName) {
            this[modalName] = false;
            document.body.style.overflow = 'auto';
        },
        collectQueryArray(separatedArrays, currentAppId, countryCode) {
            let queryArray = [];

            separatedArrays.forEach(async array => {
                let formData = new FormData();
                formData.append("country_code", countryCode);
                formData.append("application_id", currentAppId);

                let urlQuery = '?country_code=' + countryCode + '&application_id=' + currentAppId;
                array.forEach((keyword, idx) => {
                    urlQuery += `&keywords[${idx}]=${encodeURIComponent(keyword)}`;
                });
                queryArray.push({
                    url: process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.ALL_KEYWORDS + urlQuery,
                    method: 'POST',
                });
            });
            return queryArray;
        },
        async saveAddedKeywordsFunc(keywordsArray, currentAppId, countryCode) {
            if (keywordsArray?.length > 500) {
                const separatedArrays = SplitArrays(keywordsArray, 500);
                let concatedResultResponse = {
                    saved_keywords_ids: {},
                };
                if (separatedArrays?.length === 0) {
                    return;
                }

                const queryArray = this.collectQueryArray(separatedArrays, currentAppId, countryCode);
                const result = await this.fetchRequestsBundle(queryArray);
                for (const key in result) {
                    concatedResultResponse.saved_keywords_ids = {...concatedResultResponse.saved_keywords_ids, ...result[key]?.saved_keywords_ids};
                }

                return concatedResultResponse;
            } else {
                let formData = new FormData();
                formData.append("country_code", countryCode);
                formData.append("application_id", currentAppId);

                keywordsArray.forEach((keyword, idx) => {
                    formData.append(`keywords[${idx}]`, keyword);
                });

                return await httpRequest('POST', process.env.VUE_APP_API_URL + apiUrls.keywordsTracking.ALL_KEYWORDS, formData);
            }
        },
        async keywordsBatchDelete(keywordsIds, currentAppId, countryCode) {
            const data = {
                application_id: currentAppId,
                keywords: keywordsIds,
                country_code: countryCode
            };

            return await httpRequest('DELETE', process.env.VUE_APP_API_URL
                + apiUrls.keywordsTracking.BATCH_DELETE_KEYWORDS, data);
        },
        async fetchRequestsBundle(urlArray) {
            // TODO: use fetchPromiseAllSettled from indes.js
            await this.$store.dispatch('FETCH_PROMISE_ALL_SETTLED', urlArray);
            let resultArray = [];

            if (this.promiseSettledData?.data?.length > 0) {
                for (const key in this.promiseSettledData?.data) {
                    if (this.promiseSettledData?.data[key]?.status === 'fulfilled') {
                        resultArray.push(this.promiseSettledData?.data[key]?.value);
                    }
                }
            }

            return resultArray;
        },
        async declineKeywordsFunc(keywordsArray, currentAppId, countryCode) {
            let formData = new FormData();
            formData.append("country_code", countryCode);
            formData.append("app_id", currentAppId);

            keywordsArray.forEach((keyword, idx) => {
                formData.append(`keywords[${idx}]`, keyword);
            });

            await httpRequest('POST', process.env.VUE_APP_API_URL + apiUrls.recommendedKeywords.DECLINE_KEYWORDS, formData);
        },
        async fetchUserLimits() {
            const userLimits = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.user.USER_LIMITS);

            if (!userLimits.hasOwnProperty('errors')) {
                this.$store.dispatch('SET_USER_LIMITS', userLimits);
            }
        },
        async fetchSubscription() {
            const subscription = await httpRequest('GET', process.env.VUE_APP_API_URL + 'api/subscription');
            this.$store.dispatch('SET_USER_SUBSCRIPTION', subscription);
        },
        async fetchCountryCatalog() {
            let url = process.env.VUE_APP_API_URL + this.$ApiUrls.user.FILTER_LOCALES;
            const result = await httpRequest('GET', url);
            this.$store.dispatch('SET_COUNTRY_LIST', result);
            this.countryList = result;
        },
        async fetchDataCounts() {
            this.$store.dispatch('SET_DATA_COUNTS_LOADER', false);
            await this.$store.dispatch('SET_DATA_COUNTS');
            this.$store.dispatch('SET_DATA_COUNTS_LOADER', true);
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        gtag() {
            window.dataLayer = window.dataLayer || [];
            dataLayer.push(arguments);
        },
        setPageTitle(title) {
            document.title = title + ' - Asolytics App Marketing Tools';
        },
        initSelectedApp() {

        },
    },
    computed: {
        ...mapGetters([
            'promiseSettledData',
        ]),
    },
    data() {
        return {
            windowWidth: 0,
            windowHeight: 0,
            sockets: null
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.sockets = new Sockets();
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
        this.sockets.closeConnection();
    },
})

app.component('custom-tooltip', tooltip);
app.component('svg-sprite', SvgSprite);
app.component('svg-icon', SvgIcon);
app.component('app-image', AppImage);
app.component('base-button', BaseButton);
app.component('custom-modal', Modal);
app.component('the-sidebar', TheSidebar);
app.component('the-header', TheHeader);
app.component('the-new-header', TheNewHeader);
// app.component('the-improvement-block', TheImprovementBlock);
app.component('current-app', CurrentApp);
app.component('add-entity-block', AddEntityBlock);
app.component('vue-highcharts', VueHighcharts);
app.component('preloader-table-small', PreloaderTableSmall);
app.component('progress-indicator', ProgressIndicator);
app.component('header-tabs', HeaderTabs);
app.component('details-modal', DetailsModal);
app.component('dynamic-image', DynamicImage);

router.isReady().then(function () {
    app.mount('#app');
});

if (process?.env?.NODE_ENV === 'production' && !window.location.href.includes('test-asolytics')) {
    Sentry.init({
        app,
        dsn: "https://8bcf81e0704f4457aab1de4bf1d8f164@sentry.tontilaguna.com/8",

        tracesSampleRate: 0.2,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,

        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false,
            })
        ],
    });
}
