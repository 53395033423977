import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      suggestedKeywords: [],
      trackedKeywords: [],
      liveSearchResults: {},
      popularityChartResults: {},
      competitors: {},
      sortingMeta: {
        sort_by: 'sap',
        sort_direction: 'desc',
      },
      CONST: {
        COUNTRY_CODE: 'country_code',
        SORT: 'sort_by',
        ORDER: 'order',
        DATE_FROM: 'date_from',
        DATE_TO: 'date_to',
        FILTER: 'filter[0]',
        RANGE_WORDS_COUNT_FROM: 'filter[range_words_count][from]',
        RANGE_WORDS_COUNT_TO: 'filter[range_words_count][to]',
        RANGE_SAP_FROM: 'filter[range_sap][from]',
        RANGE_SAP_TO: 'filter[range_sap][to]',
        RANGE_IMPRESSIONS_FROM: 'filter[range_impression][from]',
        RANGE_IMPRESSIONS_TO: 'filter[range_impression][to]',
        RANGE_RANK_FROM: 'filter[range_rank][from]',
        RANGE_RANK_TO: 'filter[range_rank][to]',
        RANGE_KEYWORDS_TRACKED: 'filter[keyword_tracked][isTracked]',
        RANGE_KEYWORDS_DECLINED: 'filter[keyword_declined][isDeclined]',
        SOURCES: 'filter[sources][list][0]',
        QUERY: 'filter[like_keyword][query]',
      },
      urlQueryString: '',
      trackingKeywordsCount: 0,
      tempInitObject: {},
    };
  },
  mutations,
  actions,
  getters
};
