import {getCookie} from "@/utils/utils";
import {httpRequest} from "@/api";
import store from '../../store';
import keywordsTracking from "@/api/modules/keywords-tracking";

export default {
    countryId: null,

    getCompetitorsResults() {
        return store.getters["keywordsTracking/competitorsResults"];
    },

    getLiveSearchResults() {
        return store.getters["keywordsTracking/liveSearchResults"];
    },

    getCompetitorBaseValue(type) {
        return type === 'competitor' ? 1 : (type === 'indirect' ? 2 : 0);
    },

    async competitorChanged(newType, id, oldType, countryId) {
        this.countryId = countryId;

        let result;
        if (!oldType) {
            await this.markChangedCompetitors(id, newType, 'new');
            result = await this.addCompetitor(this.getCompetitorBaseValue(newType), id);
            if (result?.status !== 'ok') {
                await this.markChangedCompetitors(id, newType, 'delete');
            }
        } else if (oldType === newType) {
            await this.markChangedCompetitors(id, newType, 'delete');
            result = await this.deleteCompetitor(id);
            if (result?.status !== 'ok') {
                await this.markChangedCompetitors(id, newType, 'new');
            }
        } else {
            await this.markChangedCompetitors(id, oldType, 'delete');
            await this.markChangedCompetitors(id, newType, 'new');

            result = await this.deleteCompetitor(id);
            if (result?.status !== 'ok') {
                await this.markChangedCompetitors(id, oldType, 'new');
            } else {
                let switchResult = await this.addCompetitor(this.getCompetitorBaseValue(newType), id);
                if (switchResult?.status !== 'ok') {
                    await this.markChangedCompetitors(id, newType, 'delete');
                }
            }
        }

        if (result?.status === 'ok') {
            await store.dispatch('UPDATE_USER_LIMITS');

            let competitorsCurrAmount = 0;
            if (oldType === null) {
                if (newType === 'competitor') {
                    competitorsCurrAmount++;
                }
            } else if (newType === oldType) {
                if (newType === 'competitor') {
                    competitorsCurrAmount--;
                }
            } else {
                if (newType === 'competitor') {
                    competitorsCurrAmount++;
                } else if (newType === 'nonCompetitor') {
                    competitorsCurrAmount--;
                }
            }

            await store.dispatch('competitors/ADD_COMPETITORS_COUNT', {
                "value": competitorsCurrAmount
            });

            return competitorsCurrAmount;
        }

        return false;
    },

    async addCompetitor(type, app_id, countryId) {
        const currentAppId = store.getters["currentApp"].id ?? getCookie('currentAppId');
        const formData = new FormData();
        formData.append('app_id', currentAppId);
        formData.append('comp_app_id', app_id);
        formData.append('country_id', countryId ?? this.countryId);
        formData.append('is_competitor', type);
        return await httpRequest(
          'POST',
          process.env.VUE_APP_API_URL + keywordsTracking.CUSTOM_COMPETITORS,
          formData
        );
    },

    async deleteCompetitor(app_id) {
        const currentAppId = store.getters["currentApp"].id ?? getCookie('currentAppId');
        const competitorsUrl = process.env.VUE_APP_API_URL
            + keywordsTracking.CUSTOM_COMPETITORS
            + '?app_id=' + currentAppId
            + '&comp_app_id=' + app_id
            + '&country_id=' + this.countryId;

        return await httpRequest('DELETE', competitorsUrl);
    },

    async setActiveCompetitors() {
        let competitorsResults = this.getCompetitorsResults();
        let liveSearchResults = this.getLiveSearchResults();
        if (liveSearchResults?.results === undefined || Object.keys(competitorsResults).length === 0) {
            return;
        }

        liveSearchResults.results = liveSearchResults.results.map(item => {
            if (item !== null) {
                let competitor = competitorsResults?.resultIsCompetitors.includes(+item?.data?.id);
                let nonCompetitor = competitorsResults?.resultNotCompetitors.includes(+item?.data?.id);
                let indirect = competitorsResults?.resultIndirectCompetitors.includes(+item?.data?.id);
                let tempItem = {...item};

                tempItem.competitorType = competitor
                  ? 'competitor'
                  : (indirect ? 'indirect' : (nonCompetitor ? 'nonCompetitor' : null));

                return tempItem;
            }
        });

        await store.dispatch('keywordsTracking/SET_LIVE_SEARCH_RESULTS', liveSearchResults);
    },

    async setEmptyCompetitors() {
        const competitors = {
            resultIsCompetitors: [],
            resultNotCompetitors: [],
            resultIndirectCompetitors: [],
        };
        await store.dispatch('keywordsTracking/SET_COMPETITORS_RESULTS', competitors);
    },

    async fetchCompetitors() {
        let competitorsUrl = '?app_id=' + getCookie('currentAppId');
        competitorsUrl = process.env.VUE_APP_API_URL + keywordsTracking.CUSTOM_COMPETITORS + competitorsUrl
          + '&is_competitor=';
        let promiseUrls = [
            {
                url: competitorsUrl + '1',
                method: 'GET',
            },
            {
                url: competitorsUrl + '0',
                method: 'GET',
            },
            {
                url: competitorsUrl + '2',
                method: 'GET',
            },
        ];

        await store.dispatch('FETCH_PROMISE_ALL_SETTLED', promiseUrls);
        let promiseSettledData = store.getters.promiseSettledData;

        let competitorArray = promiseSettledData?.data[0]?.value?.list;
        let nonCompetitorArray = promiseSettledData?.data[1]?.value?.list;
        let indirectCompetitorArray = promiseSettledData?.data[2]?.value?.list;

        if (promiseSettledData.success) {
            let competitors = {
                resultIsCompetitors: [],
                resultNotCompetitors: [],
                resultIndirectCompetitors: [],
            };

            if (competitorArray) {
                competitorArray.forEach(competitor => competitors.resultIsCompetitors.push(competitor.id));
            }

            if (nonCompetitorArray) {
                nonCompetitorArray.forEach(competitor => competitors.resultNotCompetitors.push(competitor.id));
            }

            if (indirectCompetitorArray) {
                indirectCompetitorArray.forEach(competitor => competitors.resultIndirectCompetitors.push(competitor.id));
            }

            await store.dispatch('keywordsTracking/SET_COMPETITORS_RESULTS', competitors);
            await this.setActiveCompetitors();
        }
    },

    async markChangedCompetitors(id, type, mode = 'new') {
        let competitorsResults = this.getCompetitorsResults();
        if (competitorsResults.resultIsCompetitors === undefined) {
            return;
        }

        let competitors = {
            resultIsCompetitors: [...competitorsResults.resultIsCompetitors],
            resultNotCompetitors: [...competitorsResults.resultNotCompetitors],
            resultIndirectCompetitors: [...competitorsResults.resultIndirectCompetitors],
        };

        if (mode === 'new') {
            if (type === 'competitor') {
                competitors.resultIsCompetitors.push(id);
            } else if (type === 'indirect') {
                competitors.resultIndirectCompetitors.push(id);
            } else {
                competitors.resultNotCompetitors.push(id);
            }
        } else {
            if (type === 'competitor') {
                competitors.resultIsCompetitors = competitors.resultIsCompetitors.filter(itemId => itemId !== id);
            } else if (type === 'indirect') {
                competitors.resultIndirectCompetitors = competitors.resultIndirectCompetitors.filter(itemId => itemId !== id);
            } else {
                competitors.resultNotCompetitors = competitors.resultNotCompetitors.filter(itemId => itemId !== id);
            }
        }

        await store.dispatch('keywordsTracking/SET_COMPETITORS_RESULTS', competitors);
        await this.setActiveCompetitors();
    }
}