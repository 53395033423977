<template>
  <div class="multiple-select" v-click-outside="() => isOpen = false">
    <div class="label" @click="isOpen = !isOpen">
      <slot name="label" :selected="selected">
        <template v-if="selected.length">Selected ({{selected.length}}/{{options.length}})</template>
        <template v-else>Select from ({{options.length}})</template>
      </slot>
      <svg-icon icon="angle-down-solid" :class="{rotate: isOpen}"/>
    </div>
    <div class="options" v-if="isOpen">
      <div v-for="option in options">
        <template v-if="option.options && option.label">
          <div class="group-label">{{ option.label }}</div>
          <div v-for="sub_option in option.options" class="options-line">
            <input type="checkbox"
                   class="common-checkbox"
                   :id="'ms_' + sub_option[valueField]"
                   :value="sub_option[valueField]"
                   v-model="selected"
            />
            <label :for="'ms_' + sub_option[valueField]">{{ sub_option[labelField] }}</label>
          </div>
        </template>
        <template v-else>
          <div class="options-line">
            <input type="checkbox"
                   class="common-checkbox"
                   :id="'ms_' + option[valueField]"
                   :value="option[valueField]"
                   v-model="selected"
            />
            <label :for="'ms_' + option[valueField]">{{ option[labelField] }}</label>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Label from "@/components/UI/Label/index.vue";

export default {
  name: "CustomMultipleSelect",
  components: {Label},
  emits: ['changed'],
  props: {
    options: {
      type: Array
    },
    preSelected: {
      type: Array,
      default: []
    },
    valueField: {
      type: String,
      default: 'value'
    },
    labelField: {
      type: String,
      default: 'label'
    },
    toggleAllWithValueAll: {
      type: Boolean,
      default: false
    },
    selectedAllByDefault: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      selected: [],
      isOpen: false,
      skipNextUpdate: false
    }
  },

  mounted() {
    if (this.preSelected.length) {
      this.skipNextUpdate = true;
      this.selected = this.preSelected;
    } else if (this.selectedAllByDefault) {
      this.selected = this.allValues();
    }
  },

  methods: {
    allValues() {
      return this.options.map(option => option.options ? option.options.map(sub_option => sub_option[this.valueField]) : option[this.valueField]).flat();
    }
  },

  watch: {
    selected(newVal, oldVal) {
      if (newVal === oldVal) return;
      if (this.skipNextUpdate) {
        this.skipNextUpdate = false;
        return;
      }

      let nextVal = newVal;

      if (this.toggleAllWithValueAll) {
        let isAllSelected = nextVal.includes('all');
        let isAllSelectedBefore = oldVal.includes('all');
        let nextWithoutAll = nextVal.filter((item) => item !== 'all');
        let allValues = this.allValues();
        let totalOptionsWithoutAll = allValues.length - 1;

        if (isAllSelected && isAllSelectedBefore && nextWithoutAll.length < totalOptionsWithoutAll) {
          nextVal = nextWithoutAll;
          isAllSelected = false;
          isAllSelectedBefore = false;
          this.skipNextUpdate = true;
        } else if (!isAllSelected && !isAllSelectedBefore && nextWithoutAll.length === totalOptionsWithoutAll) {
          nextVal.push('all');
          isAllSelected = true;
          this.skipNextUpdate = true;
        }

        if (isAllSelected && !isAllSelectedBefore) {
          nextVal = allValues;
        } else if (!isAllSelected && isAllSelectedBefore) {
          nextVal = [];
        }

        this.selected = nextVal;
      }

      this.$emit('changed',  this.selected);
    },
  },
}
</script>

<style scoped lang="scss">
.multiple-select {
  width: max-content;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--neutral-800);
  user-select: none;
  position: relative;

  svg {
    margin: 0 0 0 10px;
  }

  svg.rotate {
    transform: rotate(180deg);
  }

  .group-label {
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 3px;
  }

  label {
    cursor: pointer;
    width: 100%;
  }

  .options {
    position: absolute;
    background-color: #fff;
    left: 0;
    right: 0;
    top: calc(100% + 5px);
    border-radius: 4px;
    box-shadow: 0px 4px 8px 0px #A0BEDD4D;
    padding: 12px;
    z-index: 10;

    .options-line {
      display: flex;
      align-items: center;
      padding: 1px 0;
    }
  }

  .label {
    padding: 6px 16px;
    background-color: #fff;
    cursor: pointer;
    box-shadow: 0px 4px 8px 0px #A0BEDD4D;
    border-radius: 4px;
  }
}
</style>