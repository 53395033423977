<template>
  <div class="search-input-block"
       :class="[...classes, {'opened': isFocused}]"
       v-click-outside="outside">
    <div class="input-wrap">
      <form>
        <label>
          <input type="text"
                 class="search-input"
                 @input="searchInputChanged"
                 :placeholder="placeholder ?? ''"
                 @focus="isFocused = true"
                 v-model="searchInput">
        </label>
      </form>

      <svg-icon v-if="showCaret"
                class="search-icon"
                icon="search-solid"/>
    </div>

    <transition name="fade">
      <div class="results-block-wrap">

        <template v-if="searchInput.length > 2 && loading">
          <custom-preloder height="20px"/>
        </template>

        <template v-else>
          <template v-if="searchResults?.result?.length !== 0">
            <div class="results-block"
                 v-for="result in searchResults">
              <slot name="results"
                    :result="result"></slot>
            </div>
          </template>
          <template v-else>
            <div class="no-results"
                 style="padding: 0 16px;">{{ searchResults?.message ?? "We couldn't find what you are looking for" }}
            </div>
          </template>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import Preloader from '@/components/UI/Preloader/index';
import {debounce} from "@/utils/utils";

export default {
  name: "SearchInput",
  props: {
    placeholder: {
      type: String
    },
    classes: {
      type: Array,
      required: false,
      default: () => []
    },
    searchResults: {
      type: Object
    },
    showCaret: {
      type: Boolean,
      default: true,
    },
    withResultSlots: {
      type: Boolean,
      default: false
    },
    dataUpdated: {
      type: Number,
      required: false
    }
  },
  emits: ['search-input-changed', 'app-clicked'],
  components: {
    'custom-preloder': Preloader
  },
  data() {
    return {
      searchInput: '',
      debouncedInput: '',
      searchIsActive: false,
      isFocused: false,
      loading: true
    }
  },
  methods: {
    outside() {
      this.isFocused = false;
    },
    searchInputChanged() {
      this.loading = true;
      this.$emit('search-input-changed', this.searchInput);
    },
  },
  watch: {
    searchResults(value) {
      if (value?.result || value?.message) {
        this.loading = false;
      }
    },
    searchInput: debounce(function (newVal) {
      this.debouncedInput = newVal;
      this.$emit('search-input-changed', this.debouncedInput);
    }, 500),
    dataUpdated(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.searchInput = '';
      }
    }
  }
}
</script>

<style lang="scss" src="./styles.scss" scoped></style>