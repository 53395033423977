<template>
  <div class="custom-range-input">
    <div class="select-options-wrap"
         :style="{minWidth: minBlockWidth}">

      <div class="range-input-header">
        <slot></slot>
        <div class="clear-btn"
             v-if="showClearButton"
             @click="cleanFilterClicked">Clear
        </div>
      </div>

      <div class="range-inputs">
        <div class="input range-min">
          <custom-input-counter :input-data="+selectedPreset.min"
                                :custom-max-width="'100%'"
                                @input-counter-changed="inputCounterChanged($event, 'min')"
                                @input-counter-changed-outside="inputCounterChanged($event, 'outside')"
                                @show-clear-button-changed="showClearButtonChanged"
                                :min="minLimit"
                                :max="maxLimit"
          />
        </div>
        <div class="input range-max"
             :style="{minWidth: customMaxWidth}">
          <custom-input-counter :input-data="+selectedPreset.max"
                                :custom-max-width="'100%'"
                                @input-counter-changed="inputCounterChanged($event, 'max')"
                                @input-counter-changed-outside="inputCounterChanged($event, 'outside')"
                                @show-clear-button-changed="showClearButtonChanged"
                                :min="minLimit"
                                :max="maxLimit"/>
        </div>
      </div>
      <div class="presets-block"
           v-if="presetsData">
        <presets-dropdown :options="presetsData"
                          :reset-preset-counter="presetCounter"
                          direction="down"
                          @select-clicked="presetClicked"/>
      </div>

    </div>
  </div>
</template>

<script>
import CustomInputCounterNew from "@/components/Forms/CustomInputCounterNew";
import PresetsDropdown from "@/components/UI/FiltersComponent/components/PresetsDropdown/index.vue";

export default {
  name: "CustomRangeInput",
  components: {
    'custom-input-counter': CustomInputCounterNew,
    'presets-dropdown': PresetsDropdown,
  },
  props: {
    options: {
      type: Object
    },
    initialData: {
      type: Object,
      required: false,
    },
    bgIsTransparent: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'down'
    },
    minWidth: {
      type: String
    },
    presetsData: {
      type: Array,
    },
    noPresets: {
      type: Boolean,
      default: false
    },
    minValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 1000000000,
    },
    minLimit: {
      type: Number,
      default: 0,
    },
    maxLimit: {
      type: Number,
    },
    customMaxWidth: {
      type: String,
      default: '64px',
    },
    openedFromOutside: {
      type: Boolean,
    },
    closeDropdown: {
      type: Number,
      required: false
    },
    closeDropdownWithResetValues: {
      type: Number,
      required: false
    },
    resetDataCounter: {
      type: Number,
    },
    clearToLimit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['filter-applied', 'filter-changed', 'filter-cleaned', 'show-clear-button-changed'],
  data() {
    return {
      isOpened: false,
      appliedPreset: {},
      checkedRadio: null,
      presetsInnerData: {},
      filterApplied: false,
      presetCounter: 0,
      selectedPreset: {
        min: this.minValue,
        max: this.maxValue
      },
      defaultPreset: {
        min: this.minValue,
        max: this.maxValue
      },
      showClearButton: undefined,
    }
  },
  methods: {
    inputCounterChanged(data, type) {
      const presetsObject = {...this.selectedPreset};
      if (type !== 'outside') {
        presetsObject[type] = data?.value;
      }

      if (type === 'min' && presetsObject.min > presetsObject.max) {
        presetsObject.max = presetsObject.min;
      }

      if (type === 'max' && presetsObject.max < presetsObject.min) {
        presetsObject.min = presetsObject.max;
      }

      this.selectedPreset = {...presetsObject};

      if (data?.type !== 'outside-watcher') {
        this.$emit('filter-changed', {
          ...this.selectedPreset,
          btnState: this.showClearButton,
        });
      }

      if (data?.type === 'manual') {
        this.presetCounter++;
      }

    },
    outside() {
      this.isOpened = false;
    },
    cleanFilterClicked() {
      this.cleanFilter();
      this.$emit('filter-cleaned', this.selectedPreset);
    },
    cleanFilter() {
      this.presetCounter++;

      let tempSelectedPreset = {...this.defaultPreset};
      if (this.clearToLimit) {
        tempSelectedPreset.min = +this.minLimit ?? +this.defaultPreset?.min;
        tempSelectedPreset.max = +this.maxLimit ?? +this.defaultPreset?.max;
      } else {
        tempSelectedPreset.min = +this.defaultPreset?.min;
        tempSelectedPreset.max = +this.defaultPreset?.max;
      }

      this.selectedPreset = {...tempSelectedPreset};
      this.showClearButton = false;
      this.$emit('show-clear-button-changed', false);
    },
    presetClicked(e) {
      let tempSelectedPreset = {};
      tempSelectedPreset.min = +e?.minValue;
      tempSelectedPreset.max = +e?.maxValue;

      this.showClearButton = true;

      this.selectedPreset = {...tempSelectedPreset};
      this.$emit('filter-changed', {
        ...this.selectedPreset,
        btnState: this.showClearButton,
      });
    },
    showClearButtonChanged(e) {
      this.showClearButton = e;
      this.$emit('show-clear-button-changed', e);
    },
  },
  mounted() {
    let tempPresetsInnerData = {...this.presetsData};
    this.showClearButton = undefined;

    if (this.initialData?.min && this.initialData?.max) {
      let tempSelectedPreset = {...this.selectedPreset};
      tempSelectedPreset.min = +this.initialData?.min;
      tempSelectedPreset.max = +this.initialData?.max;

      for (const key in tempPresetsInnerData) {
        if (+tempPresetsInnerData[key]?.min === +this.initialData?.min && +tempPresetsInnerData[key]?.max === +this.initialData?.max) {
          tempPresetsInnerData[key].checked = true;
        }
      }

      this.filterApplied = true;
      this.appliedPreset = {...this.initialData};
      this.presetsInnerData = {...tempPresetsInnerData};
      this.selectedPreset = {...tempSelectedPreset};
    } else {
      if (this.openedFromOutside) {
        this.isOpened = true;
      }
    }

    if (this.presetsData) {
      this.presetsInnerData = {...this.presetsData};
    }

    this.showClearButton = !!this.initialData?.isInitiallyActive;
  },
  computed: {
    minBlockWidth() {
      const customMaxWidth = parseInt(this.customMaxWidth);
      const minWidth = parseInt(this.minWidth);
      if (customMaxWidth > 64) {
        const diff = customMaxWidth - 64;
        return minWidth + diff + 'px';
      }
      return this.minWidth;
    },
  },
  watch: {
    initialData(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        let tempPresetsInnerData = {...this.presetsData};
        let tempSelectedPreset = {...this.selectedPreset};
        tempSelectedPreset.min = +this.initialData?.min;
        tempSelectedPreset.max = +this.initialData?.max;

        for (const key in tempPresetsInnerData) {
          if (+tempPresetsInnerData[key]?.min === +this.initialData?.min && +tempPresetsInnerData[key]?.max === +this.initialData?.max) {
            tempPresetsInnerData[key].checked = true;
          }
        }

        this.showClearButton = !!this.initialData?.isInitiallyActive;

        this.filterApplied = true;
        this.appliedPreset = {...this.initialData};
        this.presetsInnerData = {...tempPresetsInnerData};
        this.selectedPreset = {...tempSelectedPreset};
      }
    },
    resetDataCounter(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.cleanFilter();
        this.$emit('filter-changed', {
          ...this.selectedPreset,
          btnState: this.showClearButton,
          state: 'reset',
        });
        this.showClearButton = false;
        this.$emit('show-clear-button-changed', false);
      }
    },
  }
}
</script>

<style scoped src="./styles.scss" lang="scss"></style>